import { Center, Circle, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface CircularGridItemProps {}

export const CircularGridItem: React.FC<CircularGridItemProps> = () => {
	return (
		<Center w='100%' height='100px' p='4'>
			<Circle bg='primary.100' size='80px'>
				<Icon as={FiImage} />
			</Circle>
		</Center>
	);
};
