import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
	layout: 'dashboard' | 'editor';
	isOpenSidenav: boolean;
	fullPageLoader: boolean;
}

const initialState: InitialState = {
	layout: 'dashboard',
	isOpenSidenav: true,
	fullPageLoader: false,
};

const layoutSlice = createSlice({
	name: 'layout',
	initialState,
	reducers: {
		setLayout: (
			state,
			action: PayloadAction<{
				layout: 'dashboard' | 'editor';
				isOpenSidenav: boolean;
			}>
		) => {
			const { isOpenSidenav, layout } = action.payload;
			state.layout = layout;
			state.isOpenSidenav = isOpenSidenav;
		},
		startLoading: (state) => {
			state.fullPageLoader = true;
		},
		stopLoading: (state) => {
			state.fullPageLoader = false;
		},
	},
});

export const { setLayout, startLoading, stopLoading } = layoutSlice.actions;

export const layoutReducer = layoutSlice.reducer;
