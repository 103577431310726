import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { SubTemplateType } from '../../../types/types';

interface CloneSubTemplateModalProps {
	subTemplate?: SubTemplateType;
	isOpen: boolean;
	onClose: () => void;
	refetch: () => void;
}

export const CloneSubTemplateModal: React.FC<CloneSubTemplateModalProps> = ({
	subTemplate,
	isOpen,
	onClose,
	refetch,
}) => {
	const toast = useToast();
	const formik = useFormik({
		initialValues: {
			name: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setStatus }) => {
			if (!subTemplate || !subTemplate._id) {
				return;
			}
			try {
				await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/clone`,
					{
						_id: subTemplate?._id,
						name: values.name,
					}
				);
				toast({
					position: 'top',
					status: 'success',
					description: 'Sub-template cloned successfully',
				});
				refetch();
				closeFunction();
			} catch (err: any) {
				return setStatus(err?.response?.data?.message || 'An error occurred');
			}
		},
	});
	const closeFunction = () => {
		formik.resetForm();
		onClose();
	};
	return (
		<>
			<Modal isOpen={isOpen} onClose={closeFunction}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Clone Sub-Template</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={formik.handleSubmit}>
						<ModalBody>
							{formik.status && (
								<Alert status='error' mb='2'>
									<AlertIcon />
									<AlertDescription>{formik.status}</AlertDescription>
								</Alert>
							)}
							<Text mb='4' textAlign='center' fontSize='lg'>
								Do you want to create a duplicate of "{subTemplate?.name}"?
							</Text>
							<FormControl
								id='name'
								isInvalid={formik.errors.name ? true : false}
								mb='4'
							>
								<FormLabel>Name of the new sub-template</FormLabel>
								<Input type='text' {...formik.getFieldProps('name')} />
								<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
							</FormControl>
						</ModalBody>

						<ModalFooter justifyContent='center'>
							<Button
								colorScheme='green'
								type='submit'
								mr={3}
								isLoading={formik.isSubmitting}
							>
								Submit
							</Button>
							<Button colorScheme='gray' onClick={closeFunction}>
								Cancel
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</>
	);
};
