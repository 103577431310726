export const toastCatchError = (err: any, toast: any) => {
	if (err.status !== 401) {
		return toast({
			description: err?.response?.data?.message || 'An error occurred',
			status: 'error',
		});
	}
	return;
};

export const setStatusCatchError = (
	err: any,
	setStatus: (s: string) => void
) => {
	if (err.status !== 401) {
		return setStatus(err?.response?.data?.message || 'An error occurred');
	}
	return;
};
