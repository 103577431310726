import { Box, Text, Center } from '@chakra-ui/react';
import _ from 'lodash';
import React from 'react';
import { useAppSelector } from '../redux/hooks';
import { SectionReorderElement } from './SectionReorderElement';

interface SectionReorderElementContainerProps {}

export const SectionReorderElementContainer: React.FC<
	SectionReorderElementContainerProps
> = () => {
	const { content, isSaved } = useAppSelector((state) => state.subTemplate);

	return (
		<Box>
			{isSaved.length !== 0 && (
				<Text mb='4' color='red' textAlign='center'>
					All elements should be saved to reorder
				</Text>
			)}
			{content.length > 0 ? (
				_.orderBy(content, ['position'], ['asc']).map((subTemplate) => {
					return (
						<SectionReorderElement
							subTemplate={subTemplate}
							key={subTemplate._id || subTemplate.identifier}
						/>
					);
				})
			) : (
				<Center height='300px' px='4'>
					<Text textAlign='center' fontWeight='medium'>
						There are no sections in this page
					</Text>
				</Center>
			)}
		</Box>
	);
};
