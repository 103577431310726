import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import {
	Heading,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import React from 'react';
import { FiGrid, FiLogOut, FiUser } from 'react-icons/fi';
import { HiChevronDown } from 'react-icons/hi';
import { logout } from '../../utils/logoutFunction';
import { resetModule } from '../../utils/moduleFunctions';

interface HeaderProps {}

export const Header: React.FC<HeaderProps> = () => {
	const onLogout = () => {
		logout();
	};

	const goToModulePicker = () => {
		resetModule();
		window.location.reload();
	};

	return (
		<>
			<Box px='8' py='6'>
				<Flex justifyContent='space-between' alignItems='center'>
					<Heading size='lg'>Zone Management Console</Heading>
					<Menu autoSelect={false}>
						<MenuButton
							as={Button}
							leftIcon={<FiUser />}
							rightIcon={<HiChevronDown />}
							color='gray.500'
							variant='ghost'
						>
							Profile
						</MenuButton>
						<MenuList p='0'>
							<MenuItem
								onClick={goToModulePicker}
								_hover={{ bg: 'white', color: 'primary.400' }}
								icon={<FiGrid />}
							>
								Module Picker
							</MenuItem>
							<MenuItem
								onClick={onLogout}
								_hover={{ bg: 'white', color: 'primary.400' }}
								icon={<FiLogOut />}
							>
								Logout
							</MenuItem>
						</MenuList>
					</Menu>
				</Flex>
			</Box>
		</>
	);
};
