import {
	Box,
	Button,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import { InfoType } from '../types/types';
import { FiX } from 'react-icons/fi';

interface ColorPickerProps {
	formik: any;
	field: InfoType;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ formik, field }) => {
	const [changeColor, setChangeColor] = useState(formik.values[field.name]);
	return (
		<>
			<FormControl
				id={field.name}
				isInvalid={formik.errors[field.name] ? true : false}
				isRequired={field.required ? true : false}
				mb='4'
			>
				<FormLabel textTransform='capitalize'>{field.name}</FormLabel>
				<Flex alignItems='center'>
					<Popover placement='bottom-start' strategy='fixed'>
						<PopoverTrigger>
							<Button
								isFullWidth
								bg='gray.100'
								border='1px solid'
								borderColor={formik.errors[field.name] ? 'red.600' : 'gray.100'}
								_focus={{ outline: 'none' }}
								p='2'
							>
								<Box as='span' bg={changeColor || ''} w='full' h='full'>
									{!changeColor && 'Choose color'}
								</Box>
							</Button>
						</PopoverTrigger>
						<PopoverContent p='0' w='220px'>
							<PopoverBody p='0'>
								<SketchPicker
									color={changeColor}
									onChange={(e) => setChangeColor(e.hex)}
									onChangeComplete={(e) =>
										formik.setFieldValue(field.name, e.hex)
									}
									disableAlpha
								/>
							</PopoverBody>
						</PopoverContent>
					</Popover>
					<Tooltip label='Clear color'>
						<IconButton
							aria-label='clear color'
							icon={<FiX />}
							onClick={() => {
								setChangeColor('');
								formik.setFieldValue(field.name, '');
							}}
							ml='2'
							type='button'
						/>
					</Tooltip>
				</Flex>
				<FormErrorMessage>{formik.errors[field.name]}</FormErrorMessage>
			</FormControl>
		</>
	);
};
