import {
	InfoType,
	SubTemplateType,
	SubTemplateWidgetType,
	TemplateListType,
} from '../types/types';

interface valuesType {
	[key: string]: string | number | boolean;
}
// Make empty initial values
export const makeInitialValuesFromInfo = (fields: InfoType[]) => {
	let temp: valuesType = {};
	fields.forEach((field) => {
		if ('default' in field) {
			temp[field.name] = field.default!;
		} else if (field.type === 'BOOLEAN') {
			temp[field.name] = false;
		} else {
			temp[field.name] = '';
		}
	});
	return temp;
};

// Make initial values for editing
export const makeInitialValuesFromInfoAndDetails = (
	fields: InfoType[],
	component: SubTemplateType | SubTemplateWidgetType | TemplateListType
) => {
	let temp: valuesType = {};
	if (fields.length > 0) {
		fields.forEach((field) => {
			if (field.type === 'BOOLEAN') {
				temp[field.name] = component.details
					? component.details[field.name] === null ||
					  component.details[field.name] === undefined
						? false
						: true
					: false;
			} else {
				temp[field.name] = component.details
					? component.details[field.name] || ''
					: '';
			}
		});
	}
	return temp;
};
