import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	OrderedList,
	ListItem,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { TemplateListType } from '../types/types';

interface SaveSubTemplateWarningModalProps {
	templates: TemplateListType[];
	isOpen: boolean;
	onClose: () => void;
	endFunction: () => void;
}

export const SaveSubTemplateWarningModal: React.FC<
	SaveSubTemplateWarningModalProps
> = ({ templates, isOpen, onClose, endFunction }) => {
	const [loading, setLoading] = useState(false);
	const cancelRef = useRef(null);

	const closeFunction = async () => {
		setLoading(true);
		await endFunction();
		setLoading(false);
		onClose();
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Save Sub-template
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? Updating this sub-template will affect the following
							templates:
							<OrderedList>
								{templates.map((temp) => {
									return <ListItem key={temp._id}>{temp.name}</ListItem>;
								})}
							</OrderedList>
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								colorScheme='red'
								onClick={closeFunction}
								mr={3}
								isLoading={loading}
							>
								Confirm
							</Button>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
