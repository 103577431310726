import { Button } from '@chakra-ui/button';
import { Heading, VStack } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Switch,
} from '@chakra-ui/react';
import Select from 'react-select';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { customScrollCss } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setInputDrawerComponent } from '../../../redux/reducers/editorDrawerSlice';
import { removeSelectedItem } from '../../../redux/reducers/selectedItemSlice';
import { addEmptySectionToContent } from '../../../redux/reducers/subTemplateSlice';
import { SectionType } from '../../../types/types';
import { MakeFormFieldsFromInfo } from '../../../utils/MakeFormFieldsFromInfo';
import { makeInitialValuesFromInfo } from '../../../utils/makeInitialValuesFromInfo';

interface PushSubTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
}

interface StoreOptions {
	label: string;
	value: string;
}

export const PushSubTemplateModal: React.FC<PushSubTemplateModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { selectedItem } = useAppSelector((state) => state.selectedItem);
	const { content } = useAppSelector((state) => state.subTemplate);
	const { stores } = useAppSelector((state) => state.store);
	const [storeList, setStoreList] = useState<StoreOptions[]>([]);
	const dispatch = useAppDispatch();
	const [initialValues, setInitialValues] = useState<any>({});
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: (values, { setFieldError, setSubmitting }) => {
			const { storeId, isAllStore, name, ...details } = values;
			if (!isAllStore && storeId.length === 0) {
				setSubmitting(false);
				return setFieldError(
					'storeId',
					'Please check isAllStore or select a store'
				);
			}
			let hasColorErrors = false;
			(selectedItem as SectionType).info
				.filter((f) => f.type === 'COLOR')
				.forEach((f) => {
					if (f.required && !details[f.name]) {
						formik.setFieldError(f.name, 'This field is required');
						hasColorErrors = true;
					}
				});
			if (hasColorErrors) {
				setSubmitting(false);
				return;
			}
			let subTemplate = {
				name: name,
				identifier: v4(),
				sectionId: (selectedItem as SectionType)._id!,
				sectionCode: (selectedItem as SectionType).code!,
				details,
				widget: [],
				position: content.length + 1,
				storeId: storeId,
				isAllStore,
			};
			dispatch(
				addEmptySectionToContent({
					subTemplate,
				})
			);
			dispatch(
				setInputDrawerComponent({
					component: subTemplate,
					componentType: 'SECTION',
				})
			);
			formik.resetForm();
			onClose();
			document.getElementById(subTemplate.identifier)?.scrollIntoView();
			dispatch(removeSelectedItem());
		},
	});

	useEffect(() => {
		if (selectedItem) {
			let init = makeInitialValuesFromInfo((selectedItem as SectionType).info);
			setInitialValues({ name: '', ...init, isAllStore: false, storeId: [] });
		}
	}, [selectedItem]);

	useEffect(() => {
		if (stores) {
			let tempStores = stores.map((s) => {
				return { label: s.websiteName, value: s.sapWebsiteId };
			});

			setStoreList(tempStores);
		}
	}, [stores]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<form onSubmit={formik.handleSubmit}>
				<ModalContent>
					<ModalHeader>Add section details</ModalHeader>
					<ModalCloseButton />
					<ModalBody css={customScrollCss}>
						<Heading size='md' mb='6'>
							Selected Section: {(selectedItem as SectionType)?.name}
						</Heading>
						<VStack spacing={4}>
							<FormControl
								id={'name'}
								isInvalid={formik.errors['name'] ? true : false}
								isRequired
								mb='4'
							>
								<FormLabel textTransform='capitalize'>{'name'}</FormLabel>
								<Input type='text' {...formik.getFieldProps('name')} />
								<FormErrorMessage>{formik.errors['name']}</FormErrorMessage>
							</FormControl>
							{selectedItem &&
								(selectedItem as SectionType).info.map((info) => {
									return (
										<MakeFormFieldsFromInfo
											field={info}
											formik={formik}
											key={info.name}
										/>
									);
								})}
							<FormControl
								id={'isAllStore'}
								isInvalid={formik.errors['isAllStore'] ? true : false}
								isRequired
								mb='4'
							>
								<FormLabel textTransform='capitalize'>For all stores</FormLabel>
								<Switch
									isChecked={formik.values.isAllStore}
									onChange={(e) => {
										if (e.target.checked) {
											return formik.setFieldValue('isAllStore', true);
										}
										formik.setFieldValue('isAllStore', false);
									}}
									colorScheme='green'
								/>
								<FormErrorMessage>
									{formik.errors['isAllStore']}
								</FormErrorMessage>
							</FormControl>
							{formik.values.isAllStore === false && (
								<FormControl
									id={'storeId'}
									isInvalid={formik.errors['storeId'] ? true : false}
									isRequired
									mb='4'
								>
									<FormLabel textTransform='capitalize'>
										Select applicable stores
									</FormLabel>
									<Select
										isMulti
										options={storeList}
										value={storeList.filter((v) =>
											formik.values.storeId.includes(v.value)
										)}
										onChange={(v: any) => {
											let formikValue = v.map((a: StoreOptions) => {
												return a.value;
											});
											formik.setFieldValue('storeId', formikValue);
										}}
										menuPosition='fixed'
									/>
									<FormErrorMessage>
										{formik.errors['storeId']}
									</FormErrorMessage>
								</FormControl>
							)}
						</VStack>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<Button
							colorScheme='green'
							type='submit'
							mr={3}
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
						<Button colorScheme='gray' onClick={onClose}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};
