import { useDisclosure } from '@chakra-ui/hooks';
import { Box, Center, VStack } from '@chakra-ui/layout';
import _ from 'lodash';
import React from 'react';
import { useDrop } from 'react-dnd';
import { SubTemplateCard } from '../../../components/SubTemplateCard';
import { SectionDragType } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setSelectedItemDetails } from '../../../redux/reducers/selectedItemSlice';
import { SectionType } from '../../../types/types';
import { PushSubTemplateModal } from './PushSubTemplateModal';

interface SubTemplateListProps {}

export const SubTemplateList: React.FC<SubTemplateListProps> = () => {
	const subTemplate = useAppSelector((state) => state.subTemplate);
	const dispatch = useAppDispatch();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [{ canDrop }, drop] = useDrop(() => ({
		accept: SectionDragType,
		drop: (item: { section: SectionType }) => {
			dispatch(
				setSelectedItemDetails({
					selectedItemType: 'SECTION',
					selectedItem: item.section,
				})
			);
			onOpen();
		},
		collect: (monitor) => ({
			canDrop: monitor.canDrop(),
		}),
	}));

	return (
		<>
			<PushSubTemplateModal isOpen={isOpen} onClose={onClose} />
			<Box
				ref={drop}
				opacity={canDrop ? 0.5 : 1}
				position='relative'
				minHeight='calc(100vh - 182px)'
			>
				<VStack spacing={10}>
					{subTemplate.content.length > 0 ? (
						_.orderBy(subTemplate.content, ['position'], ['asc']).map((sub) => {
							return (
								<SubTemplateCard
									subTemplate={sub}
									key={sub.identifier || sub._id}
								/>
							);
						})
					) : (
						<Center
							bg='white'
							height='300px'
							width='100%'
							border='2px dashed'
							borderColor='gray.500'
						>
							Add a section to get started
						</Center>
					)}
				</VStack>
			</Box>
		</>
	);
};
