import { Button } from '@chakra-ui/button';
import { Heading, VStack } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { customScrollCss } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeSelectedItem } from '../redux/reducers/selectedItemSlice';
import { addWidgetToContent } from '../redux/reducers/subTemplateSlice';
import { WidgetType } from '../types/types';
import { MakeFormFieldsFromInfo } from '../utils/MakeFormFieldsFromInfo';
import { makeInitialValuesFromInfo } from '../utils/makeInitialValuesFromInfo';

interface PushWidgetModalProps {
	isOpen: boolean;
	onClose: () => void;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

export const PushWidgetModal: React.FC<PushWidgetModalProps> = ({
	isOpen,
	onClose,
	subTemplateIdentifier,
}) => {
	const { selectedItem } = useAppSelector((state) => state.selectedItem);
	const { content } = useAppSelector((state) => state.subTemplate);
	const dispatch = useAppDispatch();
	const [initialValues, setInitialValues] = useState<any>({});
	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: (values) => {
			let widgetPosition;
			content.forEach((sub) => {
				if (
					sub[subTemplateIdentifier.id ? '_id' : 'identifier'] ===
					subTemplateIdentifier[subTemplateIdentifier.id ? 'id' : 'identifier']
				) {
					widgetPosition = sub.widget.length + 1;
				}
			});
			dispatch(
				addWidgetToContent({
					subTemplateIdentifier,
					widget: {
						widgetId: (selectedItem as WidgetType)._id!,
						widgetCode: (selectedItem as WidgetType).code,
						details: { ...values, identifier: v4(), position: widgetPosition },
					},
				})
			);
			formik.resetForm();
			onClose();
			dispatch(removeSelectedItem());
		},
	});

	useEffect(() => {
		if (selectedItem) {
			let init = makeInitialValuesFromInfo((selectedItem as WidgetType).info);
			setInitialValues({ ...init });
		}
	}, [selectedItem]);
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			scrollBehavior='inside'
		>
			<ModalOverlay />
			<form onSubmit={formik.handleSubmit}>
				<ModalContent>
					<ModalHeader>Enter widget details</ModalHeader>
					<ModalCloseButton />
					<ModalBody css={customScrollCss}>
						<Heading size='md' mb='6'>
							Selected Section: {(selectedItem as WidgetType)?.name}
						</Heading>
						<VStack spacing={4}>
							{selectedItem &&
								(selectedItem as WidgetType).info.map((info) => {
									return (
										<MakeFormFieldsFromInfo
											field={info}
											formik={formik}
											key={info.name}
										/>
									);
								})}
						</VStack>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<Button colorScheme='green' type='submit' mr={3}>
							Submit
						</Button>
						<Button colorScheme='gray' onClick={onClose}>
							Cancel
						</Button>
					</ModalFooter>
				</ModalContent>
			</form>
		</Modal>
	);
};
