import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { FiEdit } from 'react-icons/fi';
import { DataTable } from '../../../components/DataTable';
import { ActionType } from '../../../types';

interface RolesDataTableProps {}

export const RolesDataTable: React.FC<RolesDataTableProps> = () => {
	const url = `${process.env.REACT_APP_UMS_BASE_URL}/role`;

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
				width: '150px',
			},
			{
				Header: 'Created By',
				accessor: 'createdBy',
				width: '200px',
			},
			{
				Header: 'Created At',
				accessor: 'createdAt',
				width: '120px',
				isDateField: true,
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Updated At',
				accessor: 'updatedAt',
				width: '120px',
				isDateField: true,
				isPrintable: false,
			},
		],
		[]
	);

	const actions: ActionType[] = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				isLink: true,
				linkTo: '/roles/edit',
			},
		],
		[]
	);

	return (
		<>
			<DataTable
				url={url}
				columns={columns}
				pageHeader='List of Admin Users'
				addNewLink={{
					linkTo: '/roles/add',
					isExternal: false,
				}}
				actions={actions}
			/>
		</>
	);
};
