import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DashboardLayout } from './layouts/dashboard/DashboardLayout';
import { TemplateEditor } from './pages/editor/TemplateEditor';
import { ListTemplates } from './pages/jsonTemplates/ListTemplates';
import { ListStores } from './pages/stores/ListStores';
import { CreateSubTemplate } from './pages/subTemplates/CreateSubTemplate';
import { EditSubTemplate } from './pages/subTemplates/EditSubTemplate';
import { ListSubTemplates } from './pages/subTemplates/ListSubTemplates';

interface PageRoutesProps {}

export const PageRoutes: React.FC<PageRoutesProps> = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<DashboardLayout />}>
					<Route index element={<ListTemplates />} />
					<Route
						path='editor/:templateId/:pageId'
						element={<TemplateEditor />}
					/>
					<Route path='sub-templates' element={<ListSubTemplates />} />
					<Route path='sub-templates/create' element={<CreateSubTemplate />} />
					<Route
						path='sub-templates/edit/:subTemplateId'
						element={<EditSubTemplate />}
					/>
					<Route path='stores' element={<ListStores />} />
				</Route>
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
		</BrowserRouter>
	);
};
