import { Box, Flex } from '@chakra-ui/layout';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { FullPageLoader } from '../../../components/FullPageLoader';
import { useAppSelector } from '../../redux/hooks';
import { Header } from './partials/Header';
import { SideBar } from './partials/SideBar';

interface DashboardLayoutProps {}

export const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
	const { layout, fullPageLoader } = useAppSelector((state) => state.layout);
	return (
		<Flex minHeight='100vh'>
			<SideBar />
			<Box
				width={
					layout === 'dashboard' ? 'calc(100% - 235px)' : 'calc(100% - 75px)'
				}
				ml={layout === 'dashboard' ? '235px' : '75px'}
				bg='white'
				position='relative'
			>
				{layout === 'dashboard' && <Header />}
				{fullPageLoader && <FullPageLoader />}
				<Box p={layout === 'dashboard' ? '8' : '0'}>
					<Outlet />
				</Box>
			</Box>
		</Flex>
	);
};
