import {
	Box,
	Button,
	Collapse,
	Divider,
	Flex,
	Icon,
	Link,
	LinkBox,
	LinkOverlay,
	StackDivider,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import React from 'react';
import {
	FiChevronDown,
	FiChevronUp,
	FiSettings,
	FiUsers,
} from 'react-icons/fi';
import { NestoCart } from '../../components/svgs/NestoCart';
import { NestoLogo } from '../../components/svgs/NestoLogo';
import { Modules } from '../../constants';
import { ModuleTypes } from '../../types';
import { getRefreshToken, getUser } from '../../utils/authFunctions';
import { getUmsPerms } from '../../utils/getUmsPerms';
import { setCurrentModule } from '../../utils/moduleFunctions';
import { SideNavLink } from '../components/SideNavLink';
import { useAppSelector } from '../redux/hooks';

interface SideBarProps {}

export const SideBar: React.FC<SideBarProps> = () => {
	const user = getUser();
	const { isSidenavOpen } = useAppSelector((state) => state.layout);
	const { isOpen, onToggle } = useDisclosure();
	const onModulePick = (s: ModuleTypes) => {
		setCurrentModule(s);
		window.location.reload();
	};
	const { hasRolePerm, hasUserPerm } = getUmsPerms();
	const loadExternalModule = (link: String) => {
		const token = getRefreshToken();
		window.location.replace(`${link}?token=${token}`);
	};
	const hasPermission = (name: string) => {
		let perm = user.filter((p: any) => p.code === name)[0];
		if (!perm.is_read) {
			return false;
		}
		return true;
	};
	return (
		<>
			<Flex
				height='100vh'
				width={isSidenavOpen ? '235px' : '50px'}
				bg='white'
				boxShadow='2px 2px 16px rgba(0, 0, 0, 0.09)'
				zIndex={1}
				position='fixed'
				top='0'
				left='0'
				overflowX='hidden'
				transition='width 150ms ease-in-out'
				_hover={{ width: '235px' }}
				direction='column'
				justifyContent='space-between'
			>
				<Box>
					<LinkBox py='6' px={isSidenavOpen ? '6' : '1'} whiteSpace='nowrap'>
						<LinkOverlay as={Link} to='/' mr='2'>
							<NestoCart color='primary.500' />
						</LinkOverlay>
						<NestoLogo color='primary.500' />
					</LinkBox>
					<Divider />
					<Box mt='4'>
						<VStack alignItems='start' spacing={4}>
							{hasUserPerm && (
								<SideNavLink linkTo='/users'>
									<Flex alignItems='center'>
										<Icon as={FiUsers} h='5' w='5' />
										<Text ml='4'>Users</Text>
									</Flex>
								</SideNavLink>
							)}
							{hasRolePerm && (
								<SideNavLink linkTo='/roles'>
									<Flex alignItems='center'>
										<Icon as={FiSettings} h='5' w='5' />
										<Text ml='4'>Roles</Text>
									</Flex>
								</SideNavLink>
							)}
						</VStack>
					</Box>
				</Box>
				<Divider />
				<Box>
					{Modules.filter((m) => m.key === 'ums').map((module) => {
						return (
							<Button
								onClick={onToggle}
								isFullWidth
								colorScheme='primary'
								variant='ghost'
								leftIcon={<module.icon />}
								rightIcon={isOpen ? <FiChevronDown /> : <FiChevronUp />}
								key={module.key}
								rounded='none'
								py='6'
								justifyContent='start'
							>
								{module.name}
							</Button>
						);
					})}
					<Divider />

					<Collapse in={isOpen} animateOpacity>
						<VStack divider={<StackDivider css={{ margin: '0 !important' }} />}>
							{Modules.filter((m) => m.key !== 'ums').map((module) => {
								if (!hasPermission(module.accessName)) {
									return null;
								}
								return (
									<Button
										key={module.key + '_button'}
										isFullWidth
										rounded='none'
										bg='white'
										color={`${module.color}.600`}
										leftIcon={<module.icon />}
										_hover={{ bg: 'primary.100' }}
										onClick={() =>
											module.isLinked
												? loadExternalModule(module.loginUrl!)
												: onModulePick(module.key)
										}
										py='6'
										justifyContent='start'
										css={{ marginTop: '0 !important' }}
									>
										{module.name}
									</Button>
								);
							})}
						</VStack>
					</Collapse>
				</Box>
			</Flex>
		</>
	);
};
