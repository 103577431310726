import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { DashboardLayout } from './layouts/DashboardLayout';
import { OrderList } from './pages/order/order/OrderList';
import { ReportList } from './pages/order/report/ReportList';
import { SiteList } from './pages/site/SiteList';
import { SlotList } from './pages/timeslot/slot/SlotList';
import { TimeslotList } from './pages/timeslot/timeslot/TimeslotList';
import { UserList } from './pages/user/UserList';
import { AreaList } from './pages/zms/area/AreaList';
import { CityList } from './pages/zms/city/CityList';
import { ZoneList } from './pages/zms/zone/ZoneList';

interface PageRoutesProps {}

export const PageRoutes: React.FC<PageRoutesProps> = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<DashboardLayout />}>
					<Route path='/users' element={<UserList />} />
					<Route path='/zms/cities' element={<CityList />} />
					<Route path='/zms/zones' element={<ZoneList />} />
					<Route path='/zms/areas' element={<AreaList />} />
					<Route path='/oms/orders' element={<OrderList />} />
					<Route path='/oms/reports' element={<ReportList />} />
					<Route path='/timeslots' element={<TimeslotList />} />
					<Route path='/timeslots/slot/:t_id' element={<SlotList />} />
					<Route path='/sites' element={<SiteList />} />
					<Route index element={<Navigate to='/users' />} />
				</Route>
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
		</BrowserRouter>
	);
};
