import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
	isAuth: boolean;
}

const initialState: InitialState = {
	isAuth: false,
};

export const authSlice = createSlice({
	name: 'authSlice',
	initialState,
	reducers: {
		authLogin: (state) => {
			state.isAuth = true;
		},
		authLogout: (state) => {
			state.isAuth = false;
		},
	},
});

export const { authLogin, authLogout } = authSlice.actions;
export const authReducer = authSlice.reducer;
