import { Box, Flex, HStack, IconButton, Text } from '@chakra-ui/react';
import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiTarget, FiTrash2 } from 'react-icons/fi';
import { WidgetReorderElementType } from '../constants/constants';
import { useAppDispatch } from '../redux/hooks';
import { moveWidgetInContent } from '../redux/reducers/subTemplateSlice';
import { SubTemplateWidgetType } from '../types/types';
import { DragIcon } from './svgs/DragIcon';

interface WidgetReorderElementProps {
	widget: SubTemplateWidgetType;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
	deleteFunction: (w: SubTemplateWidgetType) => void;
}

export const WidgetReorderElement: React.FC<WidgetReorderElementProps> = ({
	widget,
	subTemplateIdentifier,
	deleteFunction,
}) => {
	const currentId = widget._id || widget.details?.identifier || '';
	const dispatch = useAppDispatch();
	const [{ isDragging }, drag] = useDrag(
		() => ({
			type: WidgetReorderElementType,
			item: widget,
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[widget]
	);

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: WidgetReorderElementType,
			drop: (item: SubTemplateWidgetType) => {
				if (
					item.details?.identifier &&
					item.details?.identifier !== widget.details?.identifier
				) {
					return dispatch(
						moveWidgetInContent({
							identifier: item.details?.identifier,
							oldPosition: item.details?.position!,
							newPosition: widget.details?.position!,
							subTemplateIdentifier,
						})
					);
				}
				return;
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[widget]
	);
	return (
		<Box
			borderTop='2px solid'
			borderTopColor={isOver ? 'purple.600' : 'transparent'}
		>
			<Flex
				justifyContent='space-between'
				alignItems='center'
				maxWidth='100%'
				overflow='hidden'
				ref={(node) => drag(drop(node))}
				bg={isDragging ? 'gray.100' : 'white'}
			>
				<IconButton
					aria-label=''
					icon={<DragIcon />}
					variant='ghost'
					size='sm'
					_hover={{ bg: 'white', color: 'primary.400' }}
					_focus={{ outline: 'none' }}
					_active={{ bg: 'transparent' }}
					ref={drag}
					cursor='grab'
				/>
				<Text
					isTruncated
					fontSize='sm'
					textAlign='left'
					flexGrow={1}
					cursor='grab'
				>
					{widget.details?.title || widget.details?.identifier}
				</Text>
				<HStack spacing={0}>
					<IconButton
						aria-label=''
						icon={<FiTarget />}
						variant='ghost'
						size='sm'
						color='gray.500'
						_hover={{ bg: 'white', color: 'primary.400' }}
						_focus={{ outline: 'none' }}
						onClick={() => document.getElementById(currentId)?.scrollIntoView()}
						draggable={true}
						onDragStart={(e) => e.preventDefault()}
					/>
					<IconButton
						aria-label=''
						icon={<FiTrash2 />}
						variant='ghost'
						size='sm'
						color='gray.500'
						_hover={{ bg: 'white', color: 'primary.400' }}
						_focus={{ outline: 'none' }}
						draggable={true}
						onDragStart={(e) => e.preventDefault()}
						onClick={() => deleteFunction(widget)}
					/>
				</HStack>
			</Flex>
		</Box>
	);
};
