import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { FullPageLoader } from '../components/FullPageLoader';
import { setupAxios } from '../setupAxios';
import { ModuleTypes } from '../types';
import {
	getRefreshToken,
	getUser,
	removeAccessToken,
	removeRefreshToken,
	removeUser,
	setAccessToken,
	setUser,
} from '../utils/authFunctions';
import { logout } from '../utils/logoutFunction';
import { setCurrentModule } from '../utils/moduleFunctions';
import { PageRoutes } from './PageRoutes';

function App() {
	const [loading, setLoading] = useState(true);
	const toast = useToast();

	setupAxios(axios, toast);

	const onModulePick = (s: ModuleTypes) => {
		setCurrentModule(s);
		window.location.reload();
	};

	useEffect(() => {
		const refreshToken = async () => {
			try {
				let token = getRefreshToken();
				let user = getUser();
				if (user) {
					let zone_access = user.filter((p: any) => p.code === 'ZMS_ACCESS')[0];
					if (!zone_access.is_read) {
						onModulePick('container');
					}
				}
				if (token) {
					const result = await axios.post(
						`${process.env.REACT_APP_UMS_BASE_URL}/admin/token`,
						{ refreshToken: getRefreshToken() }
					);
					setAccessToken(result.data.data.token);
					const userResult = await axios.get(
						`${process.env.REACT_APP_UMS_BASE_URL}/admin/user-access-permission`
					);
					setUser(userResult.data.data);
				} else {
					removeAccessToken();
					removeRefreshToken();
					removeUser();
					logout();
				}
			} catch (err: any) {
				toast({
					description: err.response?.data?.message || 'An error occurred',
					status: 'error',
				});
				removeAccessToken();
				removeRefreshToken();
				removeUser();
				logout();
			}
			setLoading(false);
		};
		refreshToken();
	}, [toast]);

	if (loading) {
		return <FullPageLoader />;
	}

	return <PageRoutes />;
}

export default App;
