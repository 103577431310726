import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { DataTable } from '../../components/DataTable';

interface UserListProps {}

export const UserList: React.FC<UserListProps> = () => {
	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
				width: '150px',
			},
			{
				Header: 'Email',
				accessor: 'email',
				width: '200px',
			},
			{
				Header: 'Mobile',
				accessor: 'mobile',
				width: '120px',
			},
			{
				Header: 'Created At',
				accessor: 'createdAt',
				width: '120px',
				isDateField: true,
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Updated At',
				accessor: 'updatedAt',
				width: '120px',
				isDateField: true,
				isPrintable: false,
			},
		],
		[]
	);

	return (
		<DataTable
			url={`${process.env.REACT_APP_UMS_BASE_URL}/admin`}
			columns={columns}
			pageHeader='List of Admin Users'
		/>
	);
};
