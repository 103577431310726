import { getUser } from './authFunctions';

export const getUmsPerms = () => {
	const permissions = getUser();
	const hasUserPerm = permissions
		?.filter((p: any) => p.code === 'UMS_ACCESS')[0]
		?.subPermission.filter((p: any) => p.code === 'USER_ACCESS')[0]
		?.subPermission.filter((p: any) => p.code === 'USER_MANAGER')[0].is_read;
	const hasPasswordPerm = permissions
		?.filter((p: any) => p.code === 'UMS_ACCESS')[0]
		?.subPermission.filter((p: any) => p.code === 'USER_ACCESS')[0]
		?.subPermission.filter((p: any) => p.code === 'PASSWORD_RESET')[0].is_read;
	const hasRolePerm = permissions
		?.filter((p: any) => p.code === 'UMS_ACCESS')[0]
		.subPermission.filter((p: any) => p.code === 'ROLE_ACCESS')[0].is_read;
	return { hasUserPerm, hasRolePerm, hasPasswordPerm };
};
