import { Center, Flex, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface FullWidthCarouselProps {}

export const FullWidthCarousel: React.FC<FullWidthCarouselProps> = () => {
	return (
		<Flex bg='primary.100' alignItems='center' height='100px' p='2'>
			<Text>{`<`}</Text>
			<Center w='100%'>
				<Icon as={FiImage} />
			</Center>
			<Text>{`>`}</Text>
		</Flex>
	);
};
