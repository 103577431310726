import { Center } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';
import React from 'react';

interface SmallLoaderProps {}

export const SmallLoader: React.FC<SmallLoaderProps> = () => {
	return (
		<Center>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='primary.500'
				size='xl'
			/>
		</Center>
	);
};
