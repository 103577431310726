import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface TemplatesIconProps extends IconProps {}

export const TemplatesIcon: React.FC<TemplatesIconProps> = ({ ...props }) => {
	return (
		<Icon
			width='22px'
			height='22px'
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M2.24705 15.0896C1.74427 14.8611 1.15142 15.0834 0.922885 15.5862C0.694348 16.089 0.916668 16.6818 1.41945 16.9104L2.24705 15.0896ZM20.5804 16.9104C21.0832 16.6818 21.3055 16.089 21.077 15.5862C20.8484 15.0834 20.2556 14.8611 19.7528 15.0896L20.5804 16.9104ZM10.9999 20.1667L10.5861 21.077C10.849 21.1965 11.1508 21.1965 11.4137 21.077L10.9999 20.1667ZM1.41945 16.9104L10.5861 21.077L11.4137 19.2563L2.24705 15.0896L1.41945 16.9104ZM11.4137 21.077L20.5804 16.9104L19.7528 15.0896L10.5861 19.2563L11.4137 21.077Z'
				fill='currentColor'
			/>
			<path
				d='M2.24706 9.67298C1.74427 9.44444 1.15142 9.66676 0.922886 10.1695C0.694348 10.6723 0.916667 11.2652 1.41945 11.4937L2.24706 9.67298ZM20.5804 11.4937C21.0832 11.2652 21.3055 10.6723 21.0769 10.1695C20.8484 9.66676 20.2556 9.44444 19.7528 9.67298L20.5804 11.4937ZM10.9999 14.75L10.5861 15.6604C10.849 15.7799 11.1508 15.7799 11.4137 15.6604L10.9999 14.75ZM1.41945 11.4937L10.5861 15.6604L11.4137 13.8396L2.24706 9.67298L1.41945 11.4937ZM11.4137 15.6604L20.5804 11.4937L19.7528 9.67298L10.5861 13.8396L11.4137 15.6604Z'
				fill='currentColor'
			/>
			<path
				d='M10.9999 1.83334L20.1666 5.16668L10.9999 9.33334L1.83325 5.16668L10.9999 1.83334Z'
				stroke='currentColor'
				strokeWidth='2'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</Icon>
	);
};
