import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DataTable } from '../../../components/DataTable';
import { ColumnType } from '../../../types';

interface ReportListProps {}

export const ReportList: React.FC<ReportListProps> = () => {
	const [zoneFilters, setZoneFilters] = useState([]);

	const toast = useToast();

	const url = useMemo(
		() => `${process.env.REACT_APP_ZMS_BASE_URL}/slot/report`,
		[]
	);

	const loadZoneOptions = useCallback(
		(keyword, filters) => {
			const { date } = filters;
			return axios
				.get(`${process.env.REACT_APP_ZMS_BASE_URL}/zone`, {
					params: {
						pagination: { page: 1, limit: 30 },
						filter: { keyword: keyword, date },
					},
				})
				.then((res) => res.data.data)
				.catch((err) => {
					if (err.response?.status !== 401 && err.response?.status !== 420) {
						toast({
							description: 'An error occurred while fetching zones',
							status: 'error',
						});
					}
					return [];
				});
		},
		[toast]
	);

	const columns: ColumnType[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Timeslot',
				accessor: 'timeslot.name',
			},
			{
				Header: 'Delivery From',
				accessor: 'delivery_from_time',
			},
			{
				Header: 'Delivery To',
				accessor: 'delivery_to_time',
			},
			{
				Header: 'Capacity',
				accessor: 'max_orders',
			},
			{
				Header: '% orders',
				accessor: 'orderCount',
				custom: (val: number, rep: any) => {
					return `${
						Math.round(((val * 100) / rep.max_orders + Number.EPSILON) * 100) /
						100
					}%`;
				},
			},
			{
				Header: 'Date',
				accessor: 'date',
				filter: {
					type: 'date',
				},
				display: false,
				isPrintable: false,
				isRequired: true,
			},
			{
				Header: 'Zone',
				accessor: 'zone_id',
				filter: {
					type: 'async-select',
					loadOptions: loadZoneOptions,
					labelAccessor: 'name',
					valueAccessor: 'id',
				},
				display: false,
				isPrintable: false,
				isRequired: true,
			},
		],
		[loadZoneOptions]
	);

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/zone`)
			.then((res) => {
				let temp: any = [];
				res.data.data.forEach((val: any) => {
					temp.push({
						label: val.name,
						value: val.id,
					});
				});
				setZoneFilters(temp);
			})
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
					});
				}
			});
	}, [toast]);

	return (
		<>
			{zoneFilters && (
				<DataTable
					columns={columns}
					url={url}
					exportCSV={true}
					pageHeader='Reports'
					filterIsOpen={true}
				/>
			)}
		</>
	);
};
