import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Select,
	Switch,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ColorPicker } from '../components/ColorPicker';
import { FileUpload } from '../components/FileUpload';
import { useAppSelector } from '../redux/hooks';
import { InfoType } from '../types/types';

interface MakeFormFieldsFromInfoProps {
	field: InfoType;
	formik: any;
}

// Dynamically generate form for sections, widgets, templates and pages

export const MakeFormFieldsFromInfo: React.FC<MakeFormFieldsFromInfoProps> = ({
	field,
	formik,
}) => {
	const { pageList } = useAppSelector((state) => state.currentPage);
	const { pageId } = useParams();
	const getFieldType = (type: string) => {
		if (type === 'NUMBER') {
			return 'number';
		}
		if (type === 'dateTime' || type === 'DATETIME') {
			return 'datetime-local';
		}
		if (type === 'date' || type === 'DATE') {
			return 'date';
		}
		return 'text';
	};
	if (field.name === 'imageUrl') {
		return <FileUpload name={field.name} formik={formik} />;
	}
	if (field.name === 'imageUrlWeb') {
		return <FileUpload name={field.name} formik={formik} />;
	}
	if (field.name === 'redirectType') {
		return (
			<FormControl
				id={field.name}
				isInvalid={formik.errors[field.name] ? true : false}
				isRequired={field.required ? true : false}
				mb='4'
			>
				<FormLabel textTransform='capitalize'>{field.name}</FormLabel>
				<Select
					value={formik.values[field.name]}
					onChange={(e) => formik.setFieldValue(field.name, e.target.value)}
					placeholder='Select an option'
				>
					{pageList.length > 1 && (
						<option value='TEMPLATE_PAGE'>Template page</option>
					)}
					<option value='CATEGORY'>Category</option>
					<option value='PRODUCT'>Product</option>
					<option value='EXTERNAL_LINK'>External Link</option>
				</Select>
				<FormErrorMessage>{formik.errors[field.name]}</FormErrorMessage>
			</FormControl>
		);
	}
	if (field.name === 'itemCode') {
		return (
			<FormControl
				id={field.name}
				isInvalid={formik.errors[field.name] ? true : false}
				isRequired={field.required ? true : false}
				mb='4'
				placeholder='Select an option'
			>
				<FormLabel textTransform='capitalize'>{field.name}</FormLabel>
				{formik.values.redirectType === 'TEMPLATE_PAGE' ? (
					<Select
						value={formik.values[field.name]}
						onChange={(e) => formik.setFieldValue(field.name, e.target.value)}
						placeholder='Select an option'
					>
						{pageList.map((pg) => {
							if (pg._id === pageId) {
								return null;
							}
							return (
								<option value={pg._id} key={pg._id}>
									{pg.name}
								</option>
							);
						})}
					</Select>
				) : (
					<Input
						type={getFieldType(field.type)}
						{...formik.getFieldProps(field.name)}
					/>
				)}
				<FormErrorMessage>{formik.errors[field.name]}</FormErrorMessage>
			</FormControl>
		);
	}
	if (field.type === 'COLOR') {
		return <ColorPicker formik={formik} field={field} />;
	}
	if (
		field.type === 'STRING' ||
		field.type === 'NUMBER' ||
		field.type === 'dateTime' ||
		field.type === 'DATETIME' ||
		field.type === 'date' ||
		field.type === 'DATE'
	) {
		return (
			<FormControl
				id={field.name}
				isInvalid={formik.errors[field.name] ? true : false}
				isRequired={field.required ? true : false}
				mb='4'
			>
				<FormLabel textTransform='capitalize'>{field.name}</FormLabel>
				<Input
					type={getFieldType(field.type)}
					{...formik.getFieldProps(field.name)}
				/>
				<FormErrorMessage>{formik.errors[field.name]}</FormErrorMessage>
			</FormControl>
		);
	}
	if (field.type === 'BOOLEAN') {
		return (
			<FormControl
				id={field.name}
				isInvalid={formik.errors[field.name] ? true : false}
				isRequired={false}
				mb='4'
			>
				<Flex alignItems='center' justifyContent='space-between' px='1'>
					<FormLabel textTransform='capitalize'>{field.name}</FormLabel>
					<Switch
						isChecked={formik.values[field.name]}
						onChange={(e) => {
							if (e.target.checked) {
								return formik.setFieldValue(field.name, true);
							}
							formik.setFieldValue(field.name, false);
						}}
						colorScheme='green'
					/>
				</Flex>
				<FormErrorMessage>{formik.errors[field.name]}</FormErrorMessage>
			</FormControl>
		);
	}
	return null;
};
