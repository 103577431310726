import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PageList, PageType } from '../../types/types';

interface InitialState {
	currentPage: PageType | undefined;
	pageList: PageList[];
}

interface MoveSubTemplateInContentPayload {
	id: string;
	newPosition: number;
	oldPosition: number;
}

interface AddContentToPageType {
	id: string;
	subTemplateId: string;
	position: number;
}

const initialState: InitialState = {
	currentPage: undefined,
	pageList: [],
};

const currentPageSlice = createSlice({
	name: 'currentPage',
	initialState,
	reducers: {
		addPage: (state, action: PayloadAction<{ page: PageType }>) => {
			const { page } = action.payload;
			let temp = page;
			delete temp.__v;
			delete temp.createdAt;
			delete temp.updatedAt;
			temp.content = temp.content.map(({ _id, subTemplate, ...rest }) => {
				return { ...rest };
			});
			state.currentPage = temp;
		},
		addContentToPage: (state, action: PayloadAction<AddContentToPageType>) => {
			const { subTemplateId, position } = action.payload;
			if (state.currentPage?.content) {
				state.currentPage.content = state.currentPage.content.map((con) => {
					if (con.position >= position) {
						return { ...con, position: con.position + 1 };
					}
					return con;
				});
				state.currentPage.content.push({ subTemplateId, position });
			}
		},
		removeContentFromPage: (
			state,
			action: PayloadAction<{ subTemplateId: string; position: number }>
		) => {
			const { subTemplateId, position } = action.payload;

			if (state.currentPage) {
				state.currentPage.content = state.currentPage?.content
					.filter((con) => con.subTemplateId !== subTemplateId)
					.map((con) => {
						if (con.position > position) {
							return { ...con, position: con.position - 1 };
						}
						return con;
					});
			}
		},
		moveSubTemplateInPage: (
			state,
			action: PayloadAction<MoveSubTemplateInContentPayload>
		) => {
			const { id, oldPosition, newPosition } = action.payload;
			if (state.currentPage?.content) {
				state.currentPage.content = state.currentPage?.content.map((sub) => {
					if (sub.subTemplateId === id) {
						return { ...sub, position: newPosition };
					}
					if (sub.position !== undefined) {
						if (oldPosition > newPosition) {
							if (sub.position < oldPosition && sub.position >= newPosition) {
								return { ...sub, position: sub.position + 1 };
							}
						}
						if (oldPosition < newPosition) {
							if (sub.position > oldPosition && sub.position <= newPosition) {
								return { ...sub, position: sub.position - 1 };
							}
						}
					}
					return sub;
				});
			}
		},
		addPageList: (state, action: PayloadAction<PageList[]>) => {
			state.pageList = action.payload;
		},
		changePageDetails: (
			state,
			action: PayloadAction<{ newPage: PageType }>
		) => {
			const { name, title, pageType } = action.payload.newPage;
			if (state.currentPage) {
				state.currentPage.name = name;
				state.currentPage.title = title;
				state.currentPage.pageType = pageType;
			}
		},
	},
});

export const {
	addContentToPage,
	addPage,
	removeContentFromPage,
	moveSubTemplateInPage,
	addPageList,
	changePageDetails,
} = currentPageSlice.actions;

export const currentPageReducer = currentPageSlice.reducer;
