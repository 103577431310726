import { Button } from '@chakra-ui/button';
import { Box, Text, VStack, StackDivider } from '@chakra-ui/layout';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { SectionDragItem } from '../../../components/SectionDragItem';
import { SmallLoader } from '../../../components/SmallLoader';
import { SectionType } from '../../../types/types';

interface SectionListingProps {}

export const SectionListing: React.FC<SectionListingProps> = () => {
	const [loading, setLoading] = useState(true);
	const [sections, setSections] = useState<SectionType[]>([]);

	const keywordInput = '';
	const [totalItems, setTotalItems] = useState(0);
	const [filters, setFilters] = useState({
		pagination: {
			limit: 10,
			page: 1,
		},
		filter: { keyword: '' },
	});

	const incrementFilterPage = () => {
		setFilters((f) => ({
			...f,
			pagination: {
				...f.pagination,
				page: f.pagination.page + 1,
			},
		}));
	};

	useEffect(() => {
		const getData = async () => {
			const sec = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/section/details`,
				{
					params: {
						...filters,
					},
				}
			);
			setSections((sc) => [...sc, ...sec.data.data]);
			setTotalItems(sec.data.total);
		};
		setLoading(false);
		getData();
	}, [filters]);

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const updatePageAndKeyword = () => {
			setFilters((f) => ({
				...f,
				pagination: { ...f.pagination, page: 1 },
				filter: { keyword: keywordInput },
			}));
		};
		const delayDebounceUpdateSearch = setTimeout(() => {
			updatePageAndKeyword();
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keywordInput]);

	return (
		<>
			{loading ? (
				<Box mt='10'>
					<SmallLoader />
				</Box>
			) : sections ? (
				<>
					<VStack divider={<StackDivider borderColor='gray.100' />} spacing={2}>
						{sections.map((section) => {
							return <SectionDragItem section={section} key={section._id} />;
						})}
						<Button
							onClick={incrementFilterPage}
							disabled={
								filters.pagination.page >=
								Math.ceil(totalItems / filters.pagination.limit)
							}
						>
							Load More
						</Button>
					</VStack>
				</>
			) : (
				<Text textAlign='center'>No sections found</Text>
			)}
		</>
	);
};
