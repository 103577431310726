import { Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface AdBannerProps {}

export const AdBanner: React.FC<AdBannerProps> = () => {
	return (
		<Center w='100%' height='100px' rounded='md' bg='primary.100'>
			<Icon as={FiImage} />
		</Center>
	);
};
