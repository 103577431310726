import React, { useState } from 'react';
import {
	Alert,
	AlertDescription,
	AlertIcon,
	Box,
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Text,
	Tooltip,
	useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toastCatchError } from '../../utils/catchError';
import {
	setAccessToken,
	setRefreshToken,
	setUser,
} from '../../utils/authFunctions';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useAppDispatch } from '../redux/hooks';
import { authLogin } from '../redux/reducers/authSlice';

interface LoginProps {}

export const Login: React.FC<LoginProps> = () => {
	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useAppDispatch();

	const toast = useToast();

	const initialValues = {
		email: '',
		password: '',
	};

	const validationSchema = Yup.object().shape({
		email: Yup.string().email().required('Email is required'),
		password: Yup.string().required('password is required'),
	});

	const formik = useFormik({
		initialValues,
		validationSchema,
		validateOnChange: false,
		validateOnBlur: false,
		onSubmit: async (values, { setStatus }) => {
			try {
				const result = await axios.post(
					`${process.env.REACT_APP_UMS_BASE_URL}/admin/login`,
					values
				);
				setAccessToken(result.data.data.token);
				setRefreshToken(result.data.data.refreshToken);
				setUser(result.data.data.user.userAccessCode);
				dispatch(authLogin());
			} catch (err) {
				toastCatchError(err, toast);
			}

			console.log(values);
		},
	});

	return (
		<Center height='100%' width='100%'>
			<Box
				width='100%'
				maxWidth='446px'
				p='8'
				rounded='lg'
				boxShadow='2px 6px 24px rgba(33, 80, 132, 0.16)'
			>
				<Box mb='10' textAlign='center'>
					<Heading size='md' mb='2'>
						Login to your Account
					</Heading>
					<Text>To Login, enter your Email and Password</Text>
				</Box>
				<form onSubmit={formik.handleSubmit}>
					<Box>
						{formik.status && (
							<Alert status='error' mb='3'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.email} mb='3'>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<Input
								id='email'
								type='email'
								{...formik.getFieldProps('email')}
							/>
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.password} mb='3'>
							<FormLabel htmlFor='password'>Password</FormLabel>
							<InputGroup>
								<Input
									id='password'
									type={showPassword ? 'text' : 'password'}
									{...formik.getFieldProps('password')}
								/>
								<InputRightElement>
									<Tooltip
										label={showPassword ? 'Hide Password' : 'Show Password'}
									>
										<IconButton
											onClick={() => setShowPassword(!showPassword)}
											aria-label={
												showPassword ? 'Hide Password' : 'Show Password'
											}
											icon={showPassword ? <FiEyeOff /> : <FiEye />}
											size='sm'
											variant='ghost'
										/>
									</Tooltip>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
						</FormControl>
						<Button
							colorScheme='green'
							type='submit'
							isFullWidth
							mt='10'
							isLoading={formik.isSubmitting}
						>
							Log In
						</Button>
					</Box>
				</form>
			</Box>
		</Center>
	);
};
