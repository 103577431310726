import { SubTemplateType, SubTemplateWidgetType } from '../types/types';

const requiredSectionFields: (keyof SubTemplateType)[] = [
	'_id',
	'name',
	'sectionId',
];
const requiredWidgetFields: (keyof SubTemplateWidgetType)[] = ['widgetId'];

const replaceEmptyStringWithNull = (ob: any) => {
	let temp: any = {};
	Object.keys(ob).forEach((o) => {
		if (ob[o] === '') {
			temp[o] = null;
		} else {
			temp[o] = ob[o];
		}
	});
	return temp;
};

export const makeSubTemplateForRequest = (
	sub: SubTemplateType
): SubTemplateType => {
	let section: any = {};
	section.widget = [];

	requiredSectionFields.forEach((fieldName) => {
		if (fieldName in sub) {
			section[fieldName] = sub[fieldName];
		}
	});

	if (!sub.details) {
		section.details = {};
	} else {
		section.details = replaceEmptyStringWithNull(sub.details);
	}

	section.isAllStore = sub.isAllStore;
	if (sub.isAllStore) {
		section.storeId = [];
	} else {
		section.storeId = sub.storeId;
	}

	for (let i = 0; i < sub.widget.length; i++) {
		let { cdnUrl, name, ...details } = sub.widget[i].details!;
		section.widget.push({
			details: replaceEmptyStringWithNull(details),
		});

		requiredWidgetFields.forEach((fieldName) => {
			if (fieldName in sub.widget[i]) {
				section.widget[i][fieldName] = sub.widget[i][fieldName];
			}
		});
	}

	return section;
};
