import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { FullPageLoader } from '../../components/FullPageLoader';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { closeSidenav, openSidenav } from '../redux/layoutSlice';
import { Header } from './Header';
import { SideBar } from './SideBar';

interface DashboardLayoutProps {}

export const DashboardLayout: React.FC<DashboardLayoutProps> = () => {
	const { isSidenavOpen, isPageLoading } = useAppSelector(
		(state) => state.layout
	);
	const dispatch = useAppDispatch();
	const [isSmallerThan1024] = useMediaQuery('(max-width:1024px)');

	useEffect(() => {
		if (isSmallerThan1024) {
			dispatch(closeSidenav());
		}
		if (!isSmallerThan1024) {
			dispatch(openSidenav());
		}
	}, [isSmallerThan1024, dispatch]);
	return (
		<Flex minHeight='100vh' maxW='100vw'>
			<SideBar />
			<Box pl={isSidenavOpen ? '235px' : '54px'} position='relative' w='full'>
				{isPageLoading && <FullPageLoader />}
				<Header />
				<Box py='8' px={{ base: '8', xl: '20' }}>
					<Outlet />
				</Box>
			</Box>
		</Flex>
	);
};
