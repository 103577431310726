import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AuthPageLayout } from './layouts/AuthPageLayout';
import { Login } from './pages/Login';
import { ModulePicker } from './pages/ModulePicker';
import { useAppSelector } from './redux/hooks';

interface PageRoutesProps {}

export const PageRoutes: React.FC<PageRoutesProps> = () => {
	const { isAuth } = useAppSelector((state) => state.auth);
	if (isAuth) {
		return (
			<BrowserRouter>
				<Routes>
					<Route path='/' element={<AuthPageLayout />}>
						<Route path='/module-picker' element={<ModulePicker />} />
						<Route path='/' element={<Navigate to='/module-picker' />} />
					</Route>
					<Route path='*' element={<Navigate to='/module-picker' />} />
				</Routes>
			</BrowserRouter>
		);
	}

	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<AuthPageLayout />}>
					<Route index element={<Login />} />
				</Route>
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
		</BrowserRouter>
	);
};
