import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

interface FullPageLoaderProps {}

export const FullPageLoader: React.FC<FullPageLoaderProps> = () => {
	return (
		<Center
			position='fixed'
			height='100%'
			width='100%'
			top='0'
			left='0'
			bg='white'
			opacity='0.6'
			zIndex={100}
		>
			<Spinner
				thickness='4px'
				speed='0.65s'
				emptyColor='gray.200'
				color='primary.500'
				size='xl'
			/>
		</Center>
	);
};
