import { Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface BannerImageProps {}

export const BannerImage: React.FC<BannerImageProps> = () => {
	return (
		<Center bg='primary.100' w='100%' height='100px'>
			<Icon as={FiImage} />
		</Center>
	);
};
