import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StoreType } from '../../types/types';

interface InitialState {
	stores: StoreType[];
}

const initialState: InitialState = {
	stores: [],
};

const storeSlice = createSlice({
	name: 'storeSlice',
	initialState,
	reducers: {
		setStores: (state, action: PayloadAction<InitialState>) => {
			state.stores = action.payload.stores;
		},
	},
});

export const { setStores } = storeSlice.actions;
export const storeReducer = storeSlice.reducer;
