import {
	Box,
	Center,
	Divider,
	Flex,
	Heading,
	HStack,
	StackDivider,
	Text,
	VStack,
} from '@chakra-ui/layout';
import {
	Button,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Progress,
	Select,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
	FiCopy,
	FiEdit,
	FiEye,
	FiMoreVertical,
	FiPlus,
	FiSearch,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { CardBox } from '../../components/CardBox';
import { Pagination } from '../../components/Pagination';
import { useAppDispatch } from '../../redux/hooks';
import { startLoading, stopLoading } from '../../redux/reducers/layoutSlice';
import { FitlersType, TemplateListType } from '../../types/types';
import { AddTemplateModal } from './partials/AddTemplateModal';
import { CloneTemplateModal } from './partials/CloneTemplateModal';
import { EditTemplateModal } from './partials/EditTemplateModal';

interface ListTemplatesProps {}

export const ListTemplates: React.FC<ListTemplatesProps> = () => {
	const [templateList, setTemplateList] = useState<TemplateListType[]>([]);
	const [totalItems, setTotalItems] = useState(0);
	const [refetchFlag, setRefetchFlag] = useState(false);
	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [addModalDetails, setAddModalDetails] = useState({
		step: 1,
		t_id: '',
	});

	const [currentModal, setCurrentModal] = useState<
		'add' | 'clone' | 'edit' | null
	>(null);
	const [selectedTemplate, setSelectedTemplate] = useState<
		TemplateListType | undefined
	>();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const toast = useToast();

	const [keywordInput, setKeywordInput] = useState('');

	const [filters, setFilters] = useState<FitlersType>({
		pagination: {
			page: 1,
			limit: 5,
		},
		sort: {
			name: 'ASC',
		},
		filter: {
			keyword: '',
		},
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template`,
				{
					params: {
						...filters,
					},
				}
			);

			setTemplateList(result.data.data);
			setTotalItems(result.data.total);
			setLoading(false);
			setInitialLoading(false);
		};
		getData();
	}, [filters, refetchFlag]);

	const onClose = () => {
		setCurrentModal(null);
	};

	const onOpen = (
		s: 'add' | 'clone' | 'edit',
		template: TemplateListType | undefined = undefined
	) => {
		setSelectedTemplate(template);
		setCurrentModal(s);
	};

	const setPage = (pg: number) => {
		setFilters({
			...filters,
			pagination: { ...filters.pagination, page: pg },
		});
	};

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilters((f) => ({
				...f,
				filter: { ...f.filter, keyword: keywordInput },
				pagination: { ...f.pagination, page: 1 },
			}));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keywordInput]);

	const onPreview = async (templateId: string) => {
		try {
			dispatch(startLoading());
			const result = await axios.post(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template-demo`,
				{ templateId }
			);

			let url = new URL(`${process.env.REACT_APP_ECOMM_BASE_URL}`);
			url.searchParams.append('isDemo', 'true');
			url.searchParams.append(
				process.env.REACT_APP_JWT_NAME!,
				result.data.data[process.env.REACT_APP_JWT_NAME!]
			);
			url.searchParams.append(
				process.env.REACT_APP_TEMPLATEID_NAME!,
				result.data.data[process.env.REACT_APP_TEMPLATEID_NAME!]
			);
			window.open(url, '_blank')?.focus();
		} catch (err: any) {
			toast({
				description: err?.response?.data?.message || 'An error occurred',
				status: 'error',
			});
		}
		dispatch(stopLoading());
	};

	const refetchFunction = () => {
		setRefetchFlag(!refetchFlag);
	};

	const resetModalDetails = () => {
		setAddModalDetails({
			step: 1,
			t_id: '',
		});
	};

	return (
		<Box>
			{!initialLoading && (
				<>
					<AddTemplateModal
						isOpen={currentModal === 'add'}
						onClose={onClose}
						step={addModalDetails.step}
						t_id={addModalDetails.t_id}
						resetFunction={resetModalDetails}
					/>
					<CloneTemplateModal
						template={selectedTemplate}
						isOpen={currentModal === 'clone'}
						onClose={onClose}
						refetch={refetchFunction}
					/>
					<EditTemplateModal
						template={selectedTemplate}
						isOpen={currentModal === 'edit'}
						onClose={onClose}
						refetch={refetchFunction}
					/>
				</>
			)}
			<Flex alignItems='center' justifyContent='space-between' py='2'>
				<Heading color='gray.800' fontSize='2xl'>
					Templates
				</Heading>
				<Button
					colorScheme='green'
					leftIcon={<FiPlus />}
					onClick={() => onOpen('add')}
				>
					Create New Template
				</Button>
			</Flex>
			<Divider />
			<Flex py='2' justifyContent='flex-end' alignItems='center' mb='1'>
				<Flex>
					<Flex alignItems='center' mr='2'>
						<Text color='gray.500' whiteSpace='nowrap' mr='2'>
							Sort by:
						</Text>
						<Select
							width='150px'
							value={Object.keys(filters.sort)[0]}
							onChange={(e) =>
								setFilters({ ...filters, sort: { [e.target.value]: 'ASC' } })
							}
						>
							<option value='name'>Title</option>
							<option value='createdAt'>Created At</option>
						</Select>
					</Flex>
					<InputGroup maxWidth='250px'>
						<InputLeftElement
							pointerEvents='none'
							children={<Icon as={FiSearch} color='gray.500' />}
						/>
						<Input
							placeholder='Search for Templates'
							value={keywordInput}
							onChange={(e) => setKeywordInput(e.target.value)}
						/>
					</InputGroup>
				</Flex>
			</Flex>
			<Box mb='4' position='relative'>
				{loading && (
					<Progress
						position='absolute'
						top='0'
						left='0'
						width='100%'
						size='xs'
						isIndeterminate
					/>
				)}
				{templateList.length > 0 ? (
					<>
						<VStack
							spacing={2}
							divider={<StackDivider borderColor='gray.100' />}
							align='stretch'
							mb='4'
						>
							{templateList.map((temp) => {
								return (
									<CardBox
										key={temp._id}
										cursor='pointer'
										onClick={() => {
											if (temp.pages.length === 0) {
												setAddModalDetails({
													step: 2,
													t_id: temp._id,
												});
												return onOpen('add');
											}
											navigate(`/editor/${temp._id}/${temp.pages[0]._id}`);
										}}
									>
										<Flex
											alignItems='center'
											justifyContent='space-between'
											py='4'
											px='6'
										>
											<HStack
												spacing={4}
												divider={<StackDivider borderColor='gray.100' />}
											>
												<Box width='250px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Title
													</Text>
													<Text
														color='gray.800'
														fontWeight='bold'
														fontSize='md'
													>
														{temp.name}
													</Text>
												</Box>
												<Box width='80px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Pages
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{temp.pages.length}
													</Text>
												</Box>
												<Box width='150px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Last Updated On
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{format(parseISO(temp.updatedAt!), 'dd/MM/yyyy')}
													</Text>
												</Box>
												<Box width='150px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Created On
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{format(parseISO(temp.createdAt!), 'dd/MM/yyyy')}
													</Text>
												</Box>
											</HStack>
											<Menu autoSelect={false}>
												<MenuButton
													as={IconButton}
													icon={<FiMoreVertical />}
													variant='ghost'
													onClick={(e) => e.stopPropagation()}
												/>
												<MenuList>
													<MenuItem
														icon={<FiCopy />}
														onClick={(e) => {
															onOpen('clone', temp);
															e.stopPropagation();
														}}
														color='gray.600'
														_hover={{ bg: 'white', color: 'primary.400' }}
													>
														Clone
													</MenuItem>
													<MenuItem
														icon={<FiEdit />}
														onClick={(e) => {
															onOpen('edit', temp);
															e.stopPropagation();
														}}
														color='gray.600'
														_hover={{ bg: 'white', color: 'primary.400' }}
													>
														Edit
													</MenuItem>
													<MenuItem
														icon={<FiEye />}
														onClick={(e) => {
															onPreview(temp._id);
															e.stopPropagation();
														}}
														color='gray.600'
														_hover={{ bg: 'white', color: 'primary.400' }}
													>
														Preview
													</MenuItem>
												</MenuList>
											</Menu>
										</Flex>
									</CardBox>
								);
							})}
						</VStack>
						<Pagination
							limit={filters.pagination.limit}
							page={filters.pagination.page}
							setPage={setPage}
							totalItems={totalItems}
							setLimit={(limit: number) =>
								setFilters({
									...filters,
									pagination: {
										...filters.pagination,
										limit: limit,
									},
								})
							}
						/>
					</>
				) : (
					<CardBox>
						<Center height='85px' fontSize='lg' fontWeight='semibold'>
							No data
						</Center>
					</CardBox>
				)}
			</Box>
		</Box>
	);
};
