import { Button } from '@chakra-ui/button';
import {
	FormControl,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Divider, Flex } from '@chakra-ui/layout';
import {
	Center,
	Icon,
	Switch,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	Tooltip,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import Select from 'react-select';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { QuestionMarkCircleIcon } from '../../../components/svgs/QuestionMarkCircleIcon';
import { DeleteWidgetWarningModal } from '../../components/DeleteWidgetWarningModal';
import { SmallLoader } from '../../components/SmallLoader';
import { WidgetReorderElementContainer } from '../../components/WidgetReorderElementContainer';
import { customScrollCss } from '../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { removeInputDrawerComponent } from '../../redux/reducers/editorDrawerSlice';
import {
	removeWidgetFromContent,
	updateSectionDetails,
	updateWidgetDetailsToContent,
} from '../../redux/reducers/subTemplateSlice';
import {
	InfoType,
	SectionType,
	SubTemplateType,
	SubTemplateWidgetType,
	WidgetType,
} from '../../types/types';
import { MakeFormFieldsFromInfo } from '../../utils/MakeFormFieldsFromInfo';
import { makeInitialValuesFromInfoAndDetails } from '../../utils/makeInitialValuesFromInfo';

interface SubTemplateInputDrawerProps {}

interface StoreOptions {
	label: string;
	value: string;
}
export const SubTemplateInputDrawer: React.FC<
	SubTemplateInputDrawerProps
> = () => {
	const { inputDrawerComponent, inputDrawerComponentType, sectionIdentifier } =
		useAppSelector((state) => state.editorDrawer);
	const { isOrderChanged } = useAppSelector((state) => state.subTemplate);
	const { stores } = useAppSelector((state) => state.store);

	const [selectedInfo, setSelectedInfo] = useState<InfoType[] | undefined>();
	const [initialValues, setInitialValues] = useState<any>({});
	const [storeList, setStoreList] = useState<StoreOptions[]>([]);

	const dispatch = useAppDispatch();

	const { isOpen, onClose, onOpen } = useDisclosure();

	const toast = useToast();

	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues,
		enableReinitialize: true,
		onSubmit: (values, { setFieldError }) => {
			if (inputDrawerComponentType === 'SECTION') {
				let { name, isAllStore, storeId, ...details } = values;
				if (!isAllStore && storeId.length === 0) {
					return setFieldError(
						'storeId',
						'Please check isAllStore or select a store'
					);
				}
				let isChanged = false;
				let sameDetails = _.isEqual(
					_.omit(inputDrawerComponent?.details, ['imageUrl']),
					_.omit(values, ['imageUrl'])
				);
				if (
					(inputDrawerComponent as SubTemplateType).name !== name ||
					!sameDetails
				) {
					isChanged = true;
				}

				if (isChanged) {
					dispatch(
						updateSectionDetails({
							subTemplate: {
								...(inputDrawerComponent as SubTemplateType),
								name: name,
								isAllStore,
								storeId,
								details,
							},
						})
					);
				}
				dispatch(removeInputDrawerComponent());
			}
			if (inputDrawerComponentType === 'WIDGET') {
				let isChanged = false;
				let sameDetails = _.isEqual(
					_.omit(inputDrawerComponent?.details, ['imageUrl']),
					_.omit(values, ['imageUrl'])
				);
				if (!sameDetails) {
					isChanged = true;
				}
				if (isChanged) {
					dispatch(
						updateWidgetDetailsToContent({
							widget: {
								...(inputDrawerComponent as SubTemplateWidgetType),
								details: { ...inputDrawerComponent?.details, ...values },
							},
							subTemplateIdentifier: sectionIdentifier!,
						})
					);
				}
				dispatch(removeInputDrawerComponent());
			}
		},
	});

	const findSectionOrWidgetById = async (id: string, type: string) => {
		if (type === 'SECTION') {
			const section = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/section/${id}`
			);
			return section.data.data;
		}
		if (type === 'WIDGET') {
			const widget = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/widget/${id}`
			);
			return widget.data.data;
		}
	};

	useEffect(() => {
		const getData = async () => {
			try {
				if (inputDrawerComponent && inputDrawerComponentType) {
					setLoading(true);
					setSelectedInfo(undefined);
					const component: SectionType | WidgetType =
						await findSectionOrWidgetById(
							inputDrawerComponentType === 'SECTION'
								? (inputDrawerComponent as SubTemplateType).sectionId
								: (inputDrawerComponent as SubTemplateWidgetType).widgetId,
							inputDrawerComponentType
						);
					let temp = makeInitialValuesFromInfoAndDetails(
						component.info,
						inputDrawerComponent
					);
					setSelectedInfo(component.info);
					if (inputDrawerComponentType === 'SECTION') {
						setInitialValues({
							...temp,
							name: (inputDrawerComponent as SubTemplateType).name,
							isAllStore: (inputDrawerComponent as SubTemplateType).isAllStore,
							storeId: (inputDrawerComponent as SubTemplateType).storeId,
						});
					}
					if (inputDrawerComponentType === 'WIDGET') {
						setInitialValues({
							...temp,
						});
					}
				}
			} catch (err: any) {
				toast({
					description: err.response.data.message || 'An error occurred',
				});
			}
			setLoading(false);
		};
		getData();
	}, [inputDrawerComponent, inputDrawerComponentType, toast]);

	useEffect(() => {
		if (stores) {
			let tempStores = stores.map((s) => {
				return { label: s.websiteName, value: s.sapWebsiteId };
			});

			setStoreList(tempStores);
		}
	}, [stores]);

	return (
		<>
			<DeleteWidgetWarningModal
				endFunction={() => {
					dispatch(
						removeWidgetFromContent({
							identifier: (inputDrawerComponent as SubTemplateWidgetType)
								?.details?.identifier,
							position: (inputDrawerComponent as SubTemplateWidgetType)?.details
								?.position,
							subTemplateIdentifier: sectionIdentifier!,
						})
					);
					dispatch(removeInputDrawerComponent());
				}}
				isOpen={isOpen}
				onClose={onClose}
			/>
			<Box
				minWidth='300px'
				width='300px'
				overflowX='hidden'
				overflowY='hidden'
				position='sticky'
				height='calc(100vh - 74px)'
				shadow='lg'
				transition='width 100ms ease-in-out'
				bg='white'
				top='64px'
				css={customScrollCss}
				_hover={{ overflowY: 'auto' }}
			>
				<Box width='294px' height='100%'>
					<Flex direction='column' py='5' px='2' width='100%' height='100%'>
						<Tabs isFitted>
							<TabList>
								<Tab
									color='gray.500'
									_selected={{
										color: 'primary.400',
										borderBottomColor: 'primary.400',
									}}
								>
									Edit
								</Tab>
								<Tab
									color='gray.500'
									_selected={{
										color: 'primary.400',
										borderBottomColor: 'primary.400',
									}}
								>
									Manage
									{isOrderChanged && (
										<Tooltip label='Sub-template order has not been saved'>
											<Box>
												<Icon
													ml='1'
													as={QuestionMarkCircleIcon}
													color='red.600'
												/>
											</Box>
										</Tooltip>
									)}
								</Tab>
							</TabList>

							{loading ? (
								<Center height='300px'>
									<SmallLoader />
								</Center>
							) : (
								<TabPanels>
									<TabPanel px='0'>
										<Box>
											{inputDrawerComponent ? (
												<form onSubmit={formik.handleSubmit}>
													<Box mb='4'>
														{inputDrawerComponentType === 'SECTION' && (
															<FormControl
																id={'name'}
																isInvalid={formik.errors['name'] ? true : false}
																isRequired
																mb='4'
															>
																<FormLabel textTransform='capitalize'>
																	{'name'}
																</FormLabel>
																<Input
																	type='text'
																	{...formik.getFieldProps('name')}
																/>
																<FormErrorMessage>
																	{formik.errors['name']}
																</FormErrorMessage>
															</FormControl>
														)}
														{selectedInfo &&
															selectedInfo.map((info) => {
																return (
																	<MakeFormFieldsFromInfo
																		field={info}
																		formik={formik}
																		key={info.name}
																	/>
																);
															})}
														{inputDrawerComponentType === 'SECTION' && (
															<FormControl
																id={'isAllStore'}
																isInvalid={
																	formik.errors['isAllStore'] ? true : false
																}
																isRequired
																mb='4'
															>
																<Flex
																	alignItems='center'
																	justifyContent='space-between'
																	px='1'
																>
																	<FormLabel textTransform='capitalize'>
																		For all stores
																	</FormLabel>
																	<Switch
																		isChecked={formik.values.isAllStore}
																		onChange={(e) => {
																			if (e.target.checked) {
																				return formik.setFieldValue(
																					'isAllStore',
																					true
																				);
																			}
																			formik.setFieldValue('isAllStore', false);
																		}}
																		colorScheme='green'
																	/>
																</Flex>
																<FormErrorMessage>
																	{formik.errors['isAllStore']}
																</FormErrorMessage>
															</FormControl>
														)}
														{inputDrawerComponentType === 'SECTION' &&
															formik.values.isAllStore === false && (
																<FormControl
																	id={'storeId'}
																	isInvalid={
																		formik.errors['storeId'] ? true : false
																	}
																	isRequired
																	mb='4'
																>
																	<FormLabel textTransform='capitalize'>
																		Select applicable stores
																	</FormLabel>
																	<Select
																		closeMenuOnSelect={false}
																		isMulti
																		options={storeList}
																		value={storeList.filter((v) =>
																			formik.values.storeId.includes(v.value)
																		)}
																		onChange={(v: any) => {
																			let formikValue = v.map(
																				(a: StoreOptions) => {
																					return a.value;
																				}
																			);
																			formik.setFieldValue(
																				'storeId',
																				formikValue
																			);
																		}}
																	/>
																	<FormErrorMessage>
																		{formik.errors['storeId']}
																	</FormErrorMessage>
																</FormControl>
															)}
													</Box>
													<Divider />
													<Button
														mt='4'
														colorScheme='green'
														type='submit'
														isFullWidth
													>
														Submit
													</Button>
													{inputDrawerComponentType === 'WIDGET' && (
														<Button
															mt='4'
															colorScheme='red'
															isFullWidth
															onClick={onOpen}
														>
															Delete Widget
														</Button>
													)}
												</form>
											) : (
												<Center height='300px' px='4'>
													<Text textAlign='center' fontWeight='medium'>
														Select a Sub-Template or a Widget to get started
													</Text>
												</Center>
											)}
										</Box>
									</TabPanel>
									<TabPanel px='0'>
										<Box mb='4'>
											<WidgetReorderElementContainer />
										</Box>
									</TabPanel>
								</TabPanels>
							)}
						</Tabs>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
