import {
	Modal,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AddTemplateStepOne } from './AddTemplateStepOne';
import { AddTemplateStepTwo } from './AddTemplateStepTwo';

interface AddTemplateModalProps {
	isOpen: boolean;
	onClose: () => void;
	step?: number;
	t_id?: string;
	resetFunction: () => void;
}

export const AddTemplateModal: React.FC<AddTemplateModalProps> = ({
	isOpen,
	onClose,
	step,
	t_id,
	resetFunction,
}) => {
	const [currentStep, setCurrentStep] = useState<number>(1);
	const [templateId, setTemplateId] = useState(t_id || '');

	const closeFunction = () => {
		onClose();
		resetFunction();
	};

	const stepOneSubmitFunction = (s: string) => {
		setTemplateId(s);
		setCurrentStep(2);
	};

	useEffect(() => {
		if (step) {
			setCurrentStep(step);
		}
	}, [step]);

	useEffect(() => {
		if (t_id) {
			setTemplateId(t_id);
		}
	}, [t_id]);

	return (
		<>
			<Modal isOpen={isOpen} onClose={closeFunction}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Create new template</ModalHeader>
					<ModalCloseButton />
					{currentStep === 1 ? (
						<AddTemplateStepOne
							closeFunction={closeFunction}
							submitFunction={stepOneSubmitFunction}
						/>
					) : (
						<AddTemplateStepTwo
							closeFunction={closeFunction}
							templateId={templateId}
						/>
					)}
				</ModalContent>
			</Modal>
		</>
	);
};
