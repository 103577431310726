import { AdBannerItem } from './AdBannerItem';
import { BannerImage } from './BannerImage';
import { CircularGridItem } from './CircularGridItem';
import { CombinationDealItem } from './CombinationDealItem';
import { DealsSliderItem } from './DealsSliderItem';
import { FullWidthBanner } from './FullWidthBanner';
import { GridOneItem } from './GridOneItem';
import { HalfWidthBanner } from './HalfWidthBanner';
import { OfferBannerItem } from './OfferBannerItem';
import { SquareGridItem } from './SquareGridItem';
import { TwoBannerItem } from './TwoBannerItem';
import { TwoBannerItemV2 } from './TwoBannerItemV2';

interface WidgetComponentsList {
	[key: string]: JSX.Element;
}

export const WidgetComponents: WidgetComponentsList = {
	BANNER_IMAGE: <BannerImage />,
	FULL_CAROUSEL_IMAGE: <BannerImage />,
	CIRCULAR_GRID_ITEM: <CircularGridItem />,
	CIRCULAR_SLIDER_ITEM: <CircularGridItem />,
	SQUARE_GRID_ITEMS: <SquareGridItem />,
	AD_BANNER_IMAGE: <AdBannerItem />,
	FULL_WIDTH_BANNER: <FullWidthBanner />,
	HALF_WIDTH_BANNER: <HalfWidthBanner />,
	OFFER_BANNER_ITEM: <OfferBannerItem />,
	NO_MARGIN_ITEM: <FullWidthBanner />,
	NO_MARGIN_ONLY_IMAGE: <BannerImage />,
	GRID_ONE_ITEM: <GridOneItem />,
	GRID_TWO_ITEM: <GridOneItem />,
	TWO_BANNER_IMAGE_V1: <TwoBannerItem />,
	TWO_BANNER_IMAGE_V2: <TwoBannerItemV2 />,
	DEALS_SLIDER_ITEM: <DealsSliderItem />,
	COMBINATION_DEAL_ITEM: <CombinationDealItem />,
};
