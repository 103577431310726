import { Center, Flex, Icon, VStack, Text } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface DealsViewV2Props {}

export const DealsViewV2: React.FC<DealsViewV2Props> = () => {
	return (
		<VStack bg='primary.100' height='100px' p='2'>
			<Center w='100%' rounded='md' bg='white' height='70%'>
				<Icon as={FiImage} />
			</Center>
			<Flex alignItems='center' height='30%' w='100%'>
				<Text>{`<`}</Text>
				<Center bg='white' w='100%' height='100%' mr='1'>
					<Icon as={FiImage} />
				</Center>
				<Center bg='white' w='100%' height='100%' mr='1'>
					<Icon as={FiImage} />
				</Center>
				<Center bg='white' w='100%' height='100%'>
					<Icon as={FiImage} />
				</Center>
				<Text>{`>`}</Text>
			</Flex>
		</VStack>
	);
};
