import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface QuestionMarkCircleIconProps extends IconProps {}

export const QuestionMarkCircleIcon: React.FC<QuestionMarkCircleIconProps> = ({
	...props
}) => {
	return (
		<Icon
			xmlns='http://www.w3.org/2000/svg'
			height='18px'
			width='18px'
			fill='none'
			viewBox='0 0 24 24'
			stroke='currentColor'
			{...props}
		>
			<path
				strokeLinecap='round'
				strokeLinejoin='round'
				strokeWidth={2}
				d='M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
			/>
		</Icon>
	);
};
