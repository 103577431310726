import {
	Alert,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	useToast,
	Select,
	InputLeftAddon,
	InputRightElement,
} from '@chakra-ui/react';
import axios from 'axios';
import { AsyncPaginate } from 'react-select-async-paginate';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { chakraSelect } from '../../../../utils/styleConstants';

interface AddUserModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	refetchData: () => void;
}

export const AddUserModalForm: React.FC<AddUserModalFormProps> = ({
	url,
	isOpen,
	onClose,
	refetchData,
}) => {
	const [roleKeyword, setRoleKeyword] = useState('');
	const [selectedRole, setSelectedRole] = useState(null);
	const [rolePage, setRolePage] = useState(1);
	const [searchRolePage, setSearchRolePage] = useState(1);
	const [roleLoading, setRoleLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const initialValues = {
		name: '',
		email: '',
		password: '',
		confirmPassword: '',
		role: [],
		mobilePrefix: '971',
		mobile: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		email: Yup.string().required('Email is required'),
		password: Yup.string().required('Password is required'),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref('password'), null],
			'Passwords must match'
		),
		mobilePrefix: Yup.string().oneOf(
			['971', '91'],
			'Country Code is for mobile required'
		),
		mobile: Yup.number()
			.when('mobilePrefix', {
				is: '971',
				then: Yup.number().test(
					'len',
					'Must be exactly 9 characters',
					(val) => val?.toString().length === 9
				),
			})
			.when('mobilePrefix', {
				is: '91',
				then: Yup.number().test(
					'len',
					'Must be exactly 10 characters',
					(val) => val?.toString().length === 10
				),
			})
			.required('Mobile is required'),
		role: Yup.array().min(1, 'At least 1 user role is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			let { confirmPassword, mobile, mobilePrefix, ...submitData } = values;
			return axios
				.post(`${url}`, {
					...submitData,
					mobile: mobilePrefix + mobile,
				})
				.then((res) => {
					toast({
						description: 'User Created',
						status: 'success',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const loadRoleOptions = () => {
		setRoleLoading(true);
		let limit = 10;
		let params: any = {
			pagination: { page: roleKeyword ? searchRolePage : rolePage, limit },
		};
		let currentPage = roleKeyword ? searchRolePage : rolePage;
		if (roleKeyword) {
			params.filter = { keyword: roleKeyword };
		}
		return axios
			.get(`${process.env.REACT_APP_UMS_BASE_URL}/role/admin`, {
				params,
			})
			.then((res) => {
				let hasMore = res.data.total - currentPage * limit > 0;
				if (hasMore) {
					roleKeyword
						? setSearchRolePage((p) => p + 1)
						: setRolePage((p) => p + 1);
				}
				setRoleLoading(false);
				return {
					options: res.data.data,
					hasMore,
				};
			})
			.catch((err) => {
				setRoleLoading(false);
				return { options: [] };
			});
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		setSelectedRole(null);
		setRolePage(1);
		setSearchRolePage(1);
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Create a new User</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.name} mb='4'>
							<FormLabel htmlFor='name'>Name</FormLabel>
							<Input id='name' {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.email} mb='4'>
							<FormLabel htmlFor='email'>Email</FormLabel>
							<Input id='email' {...formik.getFieldProps('email')} />
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.mobile} mb='4'>
							<FormLabel htmlFor='mobile'>Mobile</FormLabel>
							<InputGroup>
								<InputLeftAddon p='0' pr='1px' border='0' margin='0'>
									<Select
										{...formik.getFieldProps('mobilePrefix')}
										borderRightRadius='0'
										id='mobilePrefix'
									>
										<option value='971'>+971</option>
										<option value='91'>+91</option>
									</Select>
								</InputLeftAddon>
								<Input id='mobile' {...formik.getFieldProps('mobile')} />
							</InputGroup>
							<FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.password} mb='4'>
							<FormLabel htmlFor='password'>Password</FormLabel>
							<InputGroup>
								<Input
									id='password'
									{...formik.getFieldProps('password')}
									type={showPassword ? 'text' : 'password'}
									pr='75px'
								/>
								<InputRightElement w='75px'>
									<Button
										onClick={() => setShowPassword((p) => !p)}
										h='30px'
										size='sm'
									>
										{showPassword ? 'Hide' : 'Show'}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>{formik.errors.password}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.confirmPassword} mb='4'>
							<FormLabel>Confirm Password</FormLabel>
							<InputGroup>
								<Input
									{...formik.getFieldProps('confirmPassword')}
									type={showConfirmPassword ? 'text' : 'password'}
									pr='75px'
								/>
								<InputRightElement w='75px'>
									<Button
										onClick={() => setShowConfirmPassword((p) => !p)}
										h='30px'
										size='sm'
									>
										{showConfirmPassword ? 'Hide' : 'Show'}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>
								{formik.errors.confirmPassword}
							</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.role} mb='4'>
							<FormLabel htmlFor='role'>Role</FormLabel>
							<AsyncPaginate
								debounceTimeout={300}
								isLoading={roleLoading}
								defaultOptions
								getOptionLabel={(option: any) => option.name}
								getOptionValue={(option: any) => option.id}
								loadOptions={loadRoleOptions}
								inputValue={roleKeyword}
								onInputChange={(v: any) => {
									setRoleKeyword(v);
									setSearchRolePage(1);
								}}
								value={selectedRole}
								onChange={(v: any) => {
									setSelectedRole(v);
									formik.setFieldValue(
										'role',
										v.map((val: any) => val.id)
									);
								}}
								isMulti
								menuPosition='fixed'
								closeMenuOnSelect={false}
								name='role'
								id='role'
								styles={{
									control: (existingStyles) => {
										return {
											...existingStyles,
											chakraSelect,
										};
									},
								}}
							/>
							<FormErrorMessage>{formik.errors.role}</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
