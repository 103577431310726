import { Badge, useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { DataTable } from '../../../components/DataTable';
import { ColumnType } from '../../../types';
import { AddSlotModalForm } from './partials/AddSlotModalForm';
import { DeleteSlotConfirmModal } from './partials/DeleteSlotConfirmModal';
import { EditSlotModalForm } from './partials/EditSlotModalForm';

interface SlotListProps {}

export const SlotList: React.FC<SlotListProps> = () => {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/slot`, []);
	const { t_id } = useParams();

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns: ColumnType[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Slot Type',
				accessor: 'slot_type',
				custom: (v: any) => {
					if (v === 'scheduled') {
						return <Badge colorScheme='gray'>Scheduled</Badge>;
					}
					return <Badge colorScheme='red'>Express</Badge>;
				},
				filter: {
					type: 'select',
					options: [
						{
							label: 'Scheduled',
							value: 'scheduled',
						},
						{
							label: 'Express',
							value: 'express',
						},
					],
				},
			},
			{
				Header: 'Start Time',
				accessor: 'start_time',
			},
			{
				Header: 'Cut off time',
				accessor: 'cutoff_time',
			},
			{
				Header: 'Delivery start',
				accessor: 'delivery_from_time',
			},
			{
				Header: 'Delivery end',
				accessor: 'delivery_to_time',
			},
			{
				Header: 'Pick pack allocation interval',
				accessor: 'pick_pack_allocation',
			},
			{
				Header: 'Hand off time',
				accessor: 'hand_off_time',
			},
			{
				Header: 'Dispatch time',
				accessor: 'dispatch_time',
			},
			{
				Header: 'Max orders',
				accessor: 'max_orders',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: <FiTrash2 />,
				color: 'red',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditSlotModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
				t_id={t_id!}
			/>
			<DeleteSlotConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddSlotModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
				t_id={t_id!}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={`${url}/${t_id}`}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='Slot List'
				refetchFlag={refetchFlag}
			/>
		</>
	);
};
