export const WidgetDragType = 'WIDGET';
export const SectionDragType = 'SECTION';
export const WidgetSwapType = 'WIDGET_SWAP';
export const SectionReorderType = 'SECTION_SWAP';
export const WidgetReorderElementType = 'WIDGET_REORDER';
export const SectionReorderElementType = 'SECTION_REORDER';

export const customScrollCss = {
	'&::-webkit-scrollbar': {
		width: '8px',
		height: '6px',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '10px',
		background: 'rgba(0,0,0,0.2)',
	},
	'&::-webkit-scrollbar-thumb:hover': {
		background: 'rgba(0,0,0,0.3)',
	},
	'&::-webkit-scrollbar-thumb:active': {
		background: 'rgba(0,0,0,.4)',
	},
};
