import { Badge, useDisclosure } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { FiEdit, FiFileText, FiTrash2 } from 'react-icons/fi';
import { DataTable } from '../../../components/DataTable';
import { ColumnType } from '../../../types';
import { AddTimeslotModalForm } from './partials/AddTimeslotModalForm';
import { DeleteTimeslotConfirmModal } from './partials/DeleteTimeslotConfirmModal';
import { EditTimeslotModalForm } from './partials/EditTimeslotModalForm';

interface TimeslotListProps {}

export const TimeslotList: React.FC<TimeslotListProps> = () => {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(
		() => `${process.env.REACT_APP_ZMS_BASE_URL}/timeslot`,
		[]
	);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns: ColumnType[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
			},
			{
				Header: 'Zone',
				accessor: 'zone.name',
			},
			{
				Header: 'Active From',
				accessor: 'active_from',
				isDateField: true,
				filter: {
					type: 'date',
				},
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Active To',
				accessor: 'active_to',
				isDateField: true,
				filter: {
					type: 'date',
				},
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Customer Group',
				accessor: 'customer_group',
				filter: {
					type: 'select',
					options: [
						{
							value: 'general',
							label: 'Nesto',
						},
						{ value: 'other', label: 'Other' },
					],
				},
				custom: (v: any) => {
					if (v === 'general') {
						return 'Nesto';
					}
					return 'Other';
				},
				CSVFormat: (v: any) => {
					if (v === 'general') {
						return 'Nesto';
					}
					return 'Other';
				},
			},
			{
				Header: 'Status',
				accessor: 'status',
				custom: (v: any) => {
					if (v === 1) {
						return <Badge colorScheme='green'>Enabled</Badge>;
					}
					return <Badge colorScheme='red'>Disabled</Badge>;
				},
				filter: {
					type: 'select',
					options: [
						{
							value: 1,
							label: 'Enabled',
						},
						{ value: 0, label: 'Disabled' },
					],
				},
				CSVFormat: (v: any) => {
					if (v === 1) {
						return 'Enabled';
					}
					return 'Disabled';
				},
			},
			{
				Header: 'Sort order',
				accessor: 'sort_order',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: <FiTrash2 />,
				color: 'red',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
			{
				name: 'details',
				tooltip: 'Details',
				icon: <FiFileText />,
				color: 'blue',
				onTrigger: (item: any) => {
					window.open(
						`${window.location.origin}/timeslots/slot/${item.id}`,
						'_blank'
					);
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditTimeslotModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteTimeslotConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddTimeslotModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='Timeslot List'
				refetchFlag={refetchFlag}
			/>
		</>
	);
};
