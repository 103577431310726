import { useToast, Tooltip, IconButton } from '@chakra-ui/react';
import axios from 'axios';
import React, { useMemo, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { DataTable } from '../../components/DataTable';

interface SiteListProps {}

export const SiteList: React.FC<SiteListProps> = () => {
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/sites`, []);

	const toast = useToast();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'SAP Site ID',
				accessor: 'sap_website_id',
			},
			{
				Header: 'Site name',
				accessor: 'website_name',
			},
		],
		[]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	const syncData = () => {
		axios
			.post(`${url}/sync`, null)
			.then((res) => {
				refetchData();
				toast({
					description: 'Details sync successful',
					status: 'success',
				});
			})
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
					});
				}
			});
	};

	const customButtonFunction = () => {
		return (
			<Tooltip label='Synchronize data with Magento'>
				<IconButton
					px='4'
					onClick={() => syncData()}
					icon={<FiRefreshCw />}
					size='sm'
					colorScheme='teal'
					aria-label='Synchronize Data'
				/>
			</Tooltip>
		);
	};

	return (
		<DataTable
			columns={columns}
			url={url}
			pageHeader='Store List'
			refetchFlag={refetchFlag}
			customButtonFunction={customButtonFunction}
			refreshData={true}
			exportCSV={true}
			isSearchable={false}
		/>
	);
};
