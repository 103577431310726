import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface SubTemplatesIconProps extends IconProps {}

export const SubTemplatesIcon: React.FC<SubTemplatesIconProps> = ({
	...props
}) => {
	return (
		<Icon
			width='20px'
			height='20px'
			viewBox='0 0 20 20'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<g clipPath='url(#clip0_351_2021)'>
				<path
					d='M11 2.83333C11 2.28104 11.4477 1.83333 12 1.83333H12.9157C13.2145 1.83333 13.4977 1.96698 13.6877 2.19768L15.4386 4.32421C15.5861 4.50324 15.6667 4.72795 15.6667 4.95986V7.16666C15.6667 7.71895 15.219 8.16666 14.6667 8.16666H12C11.4477 8.16666 11 7.71895 11 7.16666V2.83333Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M11 13.6667C11 13.1144 11.4477 12.6667 12 12.6667H12.9157C13.2145 12.6667 13.4977 12.8003 13.6877 13.031L15.4386 15.1576C15.5861 15.3366 15.6667 15.5613 15.6667 15.7932V18C15.6667 18.5523 15.219 19 14.6667 19H12C11.4477 19 11 18.5523 11 18V13.6667Z'
					stroke='currentColor'
					strokeWidth='2'
				/>
				<path
					d='M3.33325 0.833328V4.58333M7.08325 15.4167H3.33325V4.58333M3.33325 4.58333H7.08325'
					stroke='currentColor'
					strokeWidth='2'
					strokeLinecap='round'
					strokeLinejoin='round'
				/>
			</g>
			<defs>
				<clipPath id='clip0_351_2021'>
					<rect width='20' height='20' fill='white' />
				</clipPath>
			</defs>
		</Icon>
	);
};
