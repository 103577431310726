import {
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	HStack,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Progress,
	Select,
	StackDivider,
	Text,
	VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FiCopy, FiMoreVertical, FiPlus, FiSearch } from 'react-icons/fi';
import { Link, useNavigate } from 'react-router-dom';
import { CardBox } from '../../components/CardBox';
import { Pagination } from '../../components/Pagination';
import { FitlersType, SubTemplateType } from '../../types/types';
import { CloneSubTemplateModal } from './partials/CloneSubTemplateModal';

interface ListSubTemplatesProps {}

export const ListSubTemplates: React.FC<ListSubTemplatesProps> = () => {
	const [subTemplateList, setSubTemplateList] = useState<SubTemplateType[]>([]);

	const [totalItems, setTotalItems] = useState(0);

	const [currentModal, setCurrentModal] = useState<'clone' | null>(null);
	const [selectedSubTemplate, setSelectedSubTemplate] = useState<
		SubTemplateType | undefined
	>();
	const [loading, setLoading] = useState(false);
	const [refetchFlag, setRefetchFlag] = useState(false);
	const navigate = useNavigate();

	const [keywordInput, setKeywordInput] = useState('');

	const [filters, setFilters] = useState<FitlersType>({
		pagination: {
			page: 1,
			limit: 5,
		},
		sort: {
			name: 'ASC',
		},
		filter: {
			keyword: '',
		},
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template`,
				{
					params: {
						...filters,
					},
				}
			);

			setSubTemplateList(result.data.data);
			setTotalItems(result.data.total);
			setLoading(false);
		};
		getData();
	}, [filters, refetchFlag]);

	const onClose = () => {
		setCurrentModal(null);
	};

	const onOpen = (
		s: 'clone',
		template: SubTemplateType | undefined = undefined
	) => {
		setSelectedSubTemplate(template);
		setCurrentModal(s);
	};

	const setPage = (pg: number) => {
		setFilters({
			...filters,
			pagination: { ...filters.pagination, page: pg },
		});
	};

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilters((f) => ({
				...f,
				filter: { ...f.filter, keyword: keywordInput },
				pagination: { ...f.pagination, page: 1 },
			}));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keywordInput]);

	const refetchFunction = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<Box>
			<CloneSubTemplateModal
				subTemplate={selectedSubTemplate}
				isOpen={currentModal === 'clone'}
				onClose={onClose}
				refetch={refetchFunction}
			/>
			<Flex alignItems='center' justifyContent='space-between' py='2'>
				<Heading color='gray.800' fontSize='2xl'>
					Sub-Templates
				</Heading>
				<Button
					colorScheme='green'
					leftIcon={<FiPlus />}
					as={Link}
					to='/sub-templates/create'
				>
					Create New Sub-template
				</Button>
			</Flex>
			<Divider />
			<Flex py='2' justifyContent='flex-end' alignItems='center' mb='1'>
				<Flex>
					<Flex alignItems='center' mr='2'>
						<Text color='gray.500' whiteSpace='nowrap' mr='2'>
							Sort by:
						</Text>
						<Select
							width='150px'
							value={Object.keys(filters.sort)[0]}
							onChange={(e) =>
								setFilters({ ...filters, sort: { [e.target.value]: 'ASC' } })
							}
						>
							<option value='name'>Name</option>
							<option value='createdAt'>Created At</option>
						</Select>
					</Flex>
					<InputGroup maxWidth='250px'>
						<InputLeftElement
							pointerEvents='none'
							children={<Icon as={FiSearch} color='gray.500' />}
						/>
						<Input
							placeholder='Search for Templates'
							value={keywordInput}
							onChange={(e) => setKeywordInput(e.target.value)}
						/>
					</InputGroup>
				</Flex>
			</Flex>
			<Box mb='4'>
				{loading && (
					<Progress
						position='absolute'
						top='0'
						left='0'
						width='100%'
						size='xs'
						isIndeterminate
					/>
				)}
				{subTemplateList.length > 0 ? (
					<>
						<VStack
							spacing={2}
							divider={<StackDivider borderColor='gray.100' />}
							align='stretch'
							mb='4'
						>
							{subTemplateList.map((subTemp) => {
								return (
									<CardBox
										key={subTemp._id}
										cursor='pointer'
										onClick={() =>
											navigate(`/sub-templates/edit/${subTemp._id}`)
										}
									>
										<Flex
											alignItems='center'
											justifyContent='space-between'
											py='4'
											px='6'
										>
											<HStack
												spacing={4}
												divider={<StackDivider borderColor='gray.100' />}
											>
												<Box width='250px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Name
													</Text>
													<Text
														color='gray.800'
														fontWeight='bold'
														fontSize='md'
													>
														{subTemp.name}
													</Text>
												</Box>
												<Box width='200px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Section Code
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{subTemp.sectionCode || '-'}
													</Text>
												</Box>
												<Box width='150px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Last Updated On
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{format(parseISO(subTemp.updatedAt!), 'dd/MM/yyyy')}
													</Text>
												</Box>
												<Box width='150px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Created On
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{format(parseISO(subTemp.createdAt!), 'dd/MM/yyyy')}
													</Text>
												</Box>
											</HStack>
											<Menu autoSelect={false}>
												<MenuButton
													as={IconButton}
													icon={<FiMoreVertical />}
													variant='ghost'
													onClick={(e) => e.stopPropagation()}
												/>
												<MenuList>
													<MenuItem
														icon={<FiCopy />}
														onClick={(e) => {
															onOpen('clone', subTemp);
															e.stopPropagation();
														}}
														color='gray.600'
														_hover={{ bg: 'white', color: 'primary.400' }}
													>
														Clone
													</MenuItem>
												</MenuList>
											</Menu>
										</Flex>
									</CardBox>
								);
							})}
						</VStack>
						<Pagination
							limit={filters.pagination.limit}
							page={filters.pagination.page}
							setPage={setPage}
							totalItems={totalItems}
							setLimit={(limit: number) =>
								setFilters({
									...filters,
									pagination: {
										...filters.pagination,
										limit: limit,
									},
								})
							}
						/>
					</>
				) : (
					<CardBox>
						<Center height='85px' fontSize='lg' fontWeight='semibold'>
							No data
						</Center>
					</CardBox>
				)}
			</Box>
		</Box>
	);
};
