import { useDisclosure } from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { DataTable } from '../../../components/DataTable';
import { AddCityModalForm } from './partials/AddCityModalForm';
import { DeleteCityConfirmModal } from './partials/DeleteCityConfirmModal';
import { EditCityModalForm } from './partials/EditCityModalForm';

interface CityListProps {}

export const CityList: React.FC<CityListProps> = () => {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/city`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'City',
				accessor: 'name',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: <FiTrash2 />,
				color: 'red',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openDeleteModal, openEditModal]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditCityModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteCityConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddCityModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='City List'
				refetchFlag={refetchFlag}
			/>
		</>
	);
};
