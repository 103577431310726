import { Center, Icon, Box } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface GridOneItemProps {}

export const GridOneItem: React.FC<GridOneItemProps> = () => {
	return (
		<Box height='100px' w='100%' bg='primary.100' p='2'>
			<Center bg='white' height='100%' flexDirection='column' p='2'>
				<Box flex='1'>
					<Icon as={FiImage} />
				</Box>
				<Box w='50px' bg='gray.500' height='8px' mb='1'></Box>
				<Box w='50px' bg='gray.500' height='8px'></Box>
			</Center>
		</Box>
	);
};
