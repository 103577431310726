import { Box, Flex, Text } from '@chakra-ui/layout';
import { HStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../redux/hooks';
import { setLayout } from '../../redux/reducers/layoutSlice';
import { clearContent } from '../../redux/reducers/subTemplateSlice';
import { ComponentDrawer } from '../editor/partials/ComponentDrawer';
import { SubTemplateList } from '../editor/partials/SubTemplateList';
import { SubTemplateInputDrawer } from './SubTemplateInputDrawer';

interface CreateSubTemplateProps {}

export const CreateSubTemplate: React.FC<CreateSubTemplateProps> = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		return () => {
			dispatch(clearContent());
		};
	}, [dispatch]);
	useEffect(() => {
		dispatch(setLayout({ layout: 'editor', isOpenSidenav: false }));
		return () => {
			dispatch(setLayout({ layout: 'dashboard', isOpenSidenav: true }));
		};
	}, [dispatch]);

	return (
		<>
			<Box p='2' pt='0' bg='gray.100' position='relative'>
				<Box
					bg='gray.100'
					h='2'
					w='100%'
					zIndex={10}
					position='sticky'
					top='0'
				></Box>
				<Flex
					mb='2'
					bg='white'
					position='sticky'
					top='8px'
					zIndex={10}
					py='2'
					px='4'
					alignItems='center'
					justifyContent='space-between'
				>
					<Text fontWeight='bold' fontSize='lg'>
						Create new Sub-Template
					</Text>
				</Flex>
				<HStack spacing={2} height='100%' alignItems='start' maxWidth='100%'>
					<ComponentDrawer />
					<Box flexGrow={1} height='100%' rounded='md' overflowX='auto'>
						<Box bg='white' p='2'>
							<Box
								height='100%'
								p='6'
								border='1px solid'
								borderColor='gray.100'
							>
								<SubTemplateList />
							</Box>
						</Box>
					</Box>
					<SubTemplateInputDrawer />
				</HStack>
			</Box>
		</>
	);
};
