import { Center, Box, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface TwoBannerItemV2Props {}

export const TwoBannerItemV2: React.FC<TwoBannerItemV2Props> = () => {
	return (
		<Center
			bg='primary.100'
			w='100%'
			height='100px'
			justifyContent='space-evenly'
		>
			<Box>
				<Box w='50px' bg='gray.500' height='8px' mb='1'></Box>
				<Box w='50px' bg='gray.500' height='8px' mb='1'></Box>
				<Box w='50px' bg='gray.500' height='8px'></Box>
			</Box>
			<Box>
				<Box w='50px' bg='gray.500' height='8px' mb='1'></Box>
				<Icon as={FiImage} />
			</Box>
		</Center>
	);
};
