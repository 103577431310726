import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AddNewPageModalProps {
	templateId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const AddNewPageModal: React.FC<AddNewPageModalProps> = ({
	templateId,
	isOpen,
	onClose,
}) => {
	const navigate = useNavigate();
	const toast = useToast();
	const formik = useFormik({
		initialValues: {
			name: '',
			pageType: 'OTHER',
			title: '',
			templateId,
		},
		enableReinitialize: true,
		onSubmit: async (values, { setStatus }) => {
			try {
				const pageResult = await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`,
					{
						...values,
						content: [],
					}
				);
				toast({
					position: 'top',
					status: 'success',
					description: 'Page added successfully',
				});
				navigate(`/editor/${templateId}/${pageResult.data.data._id}`);
				closeFunction();
			} catch (err: any) {
				setStatus(err.response.data.message || 'An error occurred');
			}
		},
	});
	const closeFunction = () => {
		formik.resetForm();
		formik.setStatus('');
		onClose();
	};
	return (
		<>
			<Modal isOpen={isOpen} onClose={closeFunction}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Add new page</ModalHeader>
					<ModalCloseButton />
					<form onSubmit={formik.handleSubmit}>
						<ModalBody>
							{formik.status && (
								<Alert status='error' mb='2'>
									<AlertIcon />
									<AlertDescription>{formik.status}</AlertDescription>
								</Alert>
							)}
							<FormControl
								id='name'
								isInvalid={formik.errors.name ? true : false}
								mb='4'
								isRequired
							>
								<FormLabel>Name</FormLabel>
								<Input type='text' {...formik.getFieldProps('name')} />
								<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
							</FormControl>
							<FormControl
								id='title'
								isInvalid={formik.errors.title ? true : false}
								mb='4'
								isRequired
							>
								<FormLabel>Title</FormLabel>
								<Input type='text' {...formik.getFieldProps('title')} />
								<FormErrorMessage>{formik.errors.title}</FormErrorMessage>
							</FormControl>
							<FormControl
								id='pageType'
								isInvalid={formik.errors.pageType ? true : false}
								mb='4'
								isRequired
							>
								<FormLabel>Type</FormLabel>
								<Select
									value={formik.values.pageType}
									onChange={(e) =>
										formik.setFieldValue('pageType', e.target.value)
									}
								>
									<option value='HOME'>Home</option>
									<option value='DEALS'>Deals</option>
									<option value='OTHER'>Other</option>
								</Select>
								<FormErrorMessage>{formik.errors.pageType}</FormErrorMessage>
							</FormControl>
						</ModalBody>

						<ModalFooter justifyContent='center'>
							<Button
								colorScheme='green'
								type='submit'
								mr={3}
								isLoading={formik.isSubmitting}
							>
								Submit
							</Button>
							<Button colorScheme='gray' onClick={closeFunction}>
								Cancel
							</Button>
						</ModalFooter>
					</form>
				</ModalContent>
			</Modal>
		</>
	);
};
