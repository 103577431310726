import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import { Icon, IconButton } from '@chakra-ui/react';
import React from 'react';
import { FiChevronLeft } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { customScrollCss } from '../../../constants/constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { removeInputDrawerComponent } from '../../../redux/reducers/editorDrawerSlice';
import { SectionListing } from './SectionListing';
import { WidgetListing } from './WidgetListing';

interface ComponentDrawerProps {}

export const ComponentDrawer: React.FC<ComponentDrawerProps> = () => {
	const { inputDrawerComponentType } = useAppSelector(
		(state) => state.editorDrawer
	);
	const dispatch = useAppDispatch();
	const location = useLocation();
	return (
		<>
			<Box
				minWidth='224px'
				width='224px'
				position='sticky'
				height='calc(100vh - 74px)'
				overflow='hidden'
				px='3'
				py='5'
				shadow='lg'
				bg='white'
				css={customScrollCss}
				top='64px'
				_hover={{ overflow: 'auto' }}
				rounded='md'
			>
				<Box
					width='200px'
					display={inputDrawerComponentType === 'SECTION' ? 'block' : 'none'}
				>
					<Flex
						borderBottom='1px solid'
						borderColor='primary.400'
						mb='2'
						p='2'
						alignItems='center'
						justifyContent='space-between'
					>
						<IconButton
							icon={<Icon as={FiChevronLeft} h={5} w={5} />}
							variant='ghost'
							colorScheme='gray'
							aria-label='back to sections'
							size='sm'
							onClick={() => dispatch(removeInputDrawerComponent())}
						/>
						<Text textAlign='center' fontSize='lg' color='primary.400'>
							Widgets
						</Text>
						<Box w='32px'></Box>
					</Flex>
					<WidgetListing />
				</Box>
				{location.pathname.includes('sub-templates/edit') ? (
					<Center
						height='300px'
						display={inputDrawerComponentType === 'SECTION' ? 'none' : 'flex'}
					>
						<Text textAlign='center' fontWeight='semibold'>
							Select the section to view widgets
						</Text>
					</Center>
				) : (
					<Box
						width='200px'
						display={inputDrawerComponentType === 'SECTION' ? 'none' : 'block'}
					>
						<Box
							borderBottom='1px solid'
							borderColor='primary.400'
							mb='2'
							p='2'
						>
							<Text textAlign='center' fontSize='lg' color='primary.400'>
								Sections
							</Text>
						</Box>
						<SectionListing />
					</Box>
				)}
			</Box>
		</>
	);
};
