import { Center, Flex, Icon, Box } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface DealsSliderItemProps {}

export const DealsSliderItem: React.FC<DealsSliderItemProps> = () => {
	return (
		<Center flexDirection='column' p='4' bg='primary.100' height='100px'>
			<Flex justifyContent='space-evenly' w='100%' h='100%' bg='white' mb='2'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Box bg='gray.500' height='12px' width='50px'></Box>
		</Center>
	);
};
