import { Center, Box, Icon, Flex } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface HalfWidthBannerProps {}

export const HalfWidthBanner: React.FC<HalfWidthBannerProps> = () => {
	return (
		<Flex height='100px'>
			<Center
				height='100%'
				bg='primary.100'
				w='100%'
				justifyContent='space-evenly'
				mr='1'
			>
				<Box w='25px' bg='gray.500' height='8px'></Box>
				<Icon as={FiImage} />
			</Center>
			<Center
				height='100%'
				bg='primary.100'
				w='100%'
				justifyContent='space-evenly'
			>
				<Box w='25px' bg='gray.500' height='8px'></Box>
				<Icon as={FiImage} />
			</Center>
		</Flex>
	);
};
