import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SectionType, WidgetType } from '../../types/types';

interface InitialStateType {
	selectedItemType: 'SECTION' | 'WIDGET' | null;
	selectedItem: SectionType | WidgetType | null;
}

interface SetSelectedItemDetailsPayload {
	selectedItemType: 'SECTION' | 'WIDGET';
	selectedItem: SectionType | WidgetType;
}

const initialState: InitialStateType = {
	selectedItemType: null,
	selectedItem: null,
};

const selectedItemSlice = createSlice({
	name: 'selectedItem',
	initialState,
	reducers: {
		setSelectedItemDetails: (
			state,
			action: PayloadAction<SetSelectedItemDetailsPayload>
		) => {
			const { selectedItem, selectedItemType } = action.payload;
			state = { selectedItem, selectedItemType };
			return state;
		},
		removeSelectedItem: (state) => {
			return initialState;
		},
	},
});

export const { setSelectedItemDetails, removeSelectedItem } =
	selectedItemSlice.actions;

export const selectedItemReducer = selectedItemSlice.reducer;
