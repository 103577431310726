import { Box } from '@chakra-ui/layout';
import { Flex, Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface CategoryStartProps {}

export const CategoryStart: React.FC<CategoryStartProps> = () => {
	return (
		<Box width='100%' height='100px'>
			<Flex bg='primary.100' alignItems='center' mb='1' h='70%'>
				<Center w='100%' h='100%'>
					<Icon as={FiImage} h='100%' />
				</Center>
			</Flex>
			<Flex alignItems='center' h='30%'>
				<Center bg='primary.100' w='100%' mr='2' h='100%'>
					<Icon as={FiImage} h='100%' />
				</Center>
				<Center bg='primary.100' w='100%' h='100%'>
					<Icon as={FiImage} h='100%' />
				</Center>
			</Flex>
		</Box>
	);
};
