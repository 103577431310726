import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { NestoAuthPageLogo } from '../svg/NestoAuthPageLogo';

interface AuthPageLayoutProps {}

export const AuthPageLayout: React.FC<AuthPageLayoutProps> = () => {
	return (
		<Flex>
			<Box
				width='100%'
				maxWidth='35%'
				height='100vh'
				position='sticky'
				top='0'
				left='0'
				bg='linear-gradient(153.69deg, #E48B89 0%, #BD4845 100%)'
			>
				<Flex direction='column' justifyContent='center' height='100%'>
					<Box px='10' mb='10'>
						<Heading color='white' mb='4'>
							Welcome to Nesto, Ecosystem Management
						</Heading>
						<Text color='white'>
							Manage Zones, Orders and Homepages from one convenient location.
						</Text>
					</Box>
					<Box pr='8'>
						<NestoAuthPageLogo />
					</Box>
				</Flex>
			</Box>
			<Box width='100%' flex='1'>
				<Outlet />
			</Box>
		</Flex>
	);
};
