import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { currentPageReducer } from './reducers/currentPageSlice';
import { editorDrawerReducer } from './reducers/editorDrawerSlice';
import { layoutReducer } from './reducers/layoutSlice';
import { selectedItemReducer } from './reducers/selectedItemSlice';
import { storeReducer } from './reducers/storeSlice';
import { subTemplateReducer } from './reducers/subTemplateSlice';

export const store = configureStore({
	reducer: {
		editorDrawer: editorDrawerReducer,
		layout: layoutReducer,
		selectedItem: selectedItemReducer,
		subTemplate: subTemplateReducer,
		currentPage: currentPageReducer,
		store: storeReducer,
	},
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
	ReturnType,
	RootState,
	unknown,
	Action<string>
>;
