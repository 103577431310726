import {
	FiBarChart,
	FiHome,
	FiMap,
	FiShoppingCart,
	FiUser,
} from 'react-icons/fi';
import { ModuleListType } from './types';

export const Modules: ModuleListType[] = [
	{
		name: 'Homepage Builder',
		description: 'Create new templates and manage the nesto homepage.',
		key: 'hbs',
		icon: FiHome,
		color: 'red',
		accessName: 'HBS_ACCESS',
	},
	{
		name: 'Zone Management',
		description: 'Manage and get details of timeslots and orders.',
		key: 'zms',
		icon: FiMap,
		color: 'blue',
		accessName: 'ZMS_ACCESS',
	},
	{
		name: 'User Management',
		description: 'Manage users across various Nesto microservices',
		key: 'ums',
		icon: FiUser,
		color: 'green',
		accessName: 'UMS_ACCESS',
	},
	{
		name: 'CRM',
		description: 'Manage customer orders for the various stores',
		key: 'crm',
		icon: FiShoppingCart,
		color: 'orange',
		isLinked: true,
		loginUrl: process.env.REACT_APP_CRM_LOGIN_URL,
		accessName: 'CRM_ACCESS',
	},
	{
		name: 'MIS',
		description: 'View and analyze data of orders',
		key: 'mis',
		icon: FiBarChart,
		color: 'pink',
		isLinked: true,
		loginUrl: process.env.REACT_APP_MIS_LOGIN_URL,
		accessName: 'MIS_ACCESS',
	},
];
