import { Center, Flex, Grid, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface ShopAndSaveProps {}

export const ShopAndSave: React.FC<ShopAndSaveProps> = () => {
	return (
		<Grid templateColumns='repeat(3,1fr)' gap={1} height='100px'>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
			<Flex bg='primary.100' alignItems='center'>
				<Center w='100%'>
					<Icon as={FiImage} />
				</Center>
			</Flex>
		</Grid>
	);
};
