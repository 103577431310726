import {
	Button,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

interface DeleteSubTemplateWarningModalProps {
	endFunction: () => void;
	isOpen: boolean;
	onClose: () => void;
}

export const DeleteSubTemplateWarningModal: React.FC<
	DeleteSubTemplateWarningModalProps
> = ({ endFunction, isOpen, onClose }) => {
	const [loading, setLoading] = useState(false);
	const cancelRef = useRef(null);

	const closeFunction = async () => {
		setLoading(true);
		await endFunction();
		setLoading(false);
		onClose();
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Sub-Template
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? This action will be updated in the template and
							cannot be undone
						</AlertDialogBody>

						<AlertDialogFooter justifyContent='center'>
							<Button
								colorScheme='red'
								onClick={closeFunction}
								mr={3}
								isLoading={loading}
							>
								Delete
							</Button>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
