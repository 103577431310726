import {
	Box,
	Button,
	Collapse,
	Divider,
	Flex,
	FormControl,
	FormHelperText,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Progress,
	Select,
	Square,
	StackDivider,
	Text,
	Tooltip,
	useToast,
	Wrap,
} from '@chakra-ui/react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
import React, {
	Fragment,
	ReactChild,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { unstable_batchedUpdates } from 'react-dom';
import {
	FiChevronDown,
	FiChevronLeft,
	FiChevronRight,
	FiChevronsLeft,
	FiChevronsRight,
	FiChevronUp,
	FiDownload,
	FiFilter,
	FiMoreVertical,
	FiPlusSquare,
	FiRefreshCw,
	FiSearch,
} from 'react-icons/fi';
import { useAppDispatch } from '../redux/hooks';
import { startPageLoading, stopPageLoading } from '../redux/layoutSlice';
import { ActionType, AnyObjectType, ColumnType } from '../types';

interface DataTableProps {
	url: string;
	columns: ColumnType[];
	expandableRows?: boolean;
	expandFunction?: (data: any) => ReactChild;
	actions?: ActionType[];
	pageHeader: string;
	isSearchable?: boolean;
	exportCSV?: boolean;
	refreshData?: boolean;
	addNewFunction?: () => any;
	customButtonFunction?: () => JSX.Element;
	refetchFlag?: boolean;
	filterIsOpen?: boolean;
}

export const DataTable: React.FC<DataTableProps> = ({
	url,
	columns,
	expandableRows,
	expandFunction,
	actions,
	pageHeader,
	isSearchable,
	exportCSV,
	refreshData,
	addNewFunction,
	customButtonFunction,
	refetchFlag,
	filterIsOpen,
}) => {
	const getInitialStatesFromColumns = () => {
		let initialFilterState: AnyObjectType = {
			keyword: '',
		};
		let initialAsyncSelectSearch: AnyObjectType = {};
		let initialAsyncSelectSelected: AnyObjectType = {};

		columns.forEach((col) => {
			if (col.filter) {
				initialFilterState[col.accessor] = col.filter.default || '';
				if (col.filter.type === 'async-select') {
					initialAsyncSelectSearch[col.accessor] = '';
					initialAsyncSelectSelected[col.accessor] = '';
				}
			}
		});
		return {
			initialFilterState,
			initialAsyncSelectSearch,
			initialAsyncSelectSelected,
		};
	};

	const {
		initialFilterState,
		initialAsyncSelectSearch,
		initialAsyncSelectSelected,
	} = getInitialStatesFromColumns();

	const [tableLoading, setTableLoading] = useState(false);
	const [sortState, setSortState] = useState({
		fieldName: 'id',
		order: 'ASC',
	});
	const [paginationState, setPaginationState] = useState({
		page: 1,
		limit: 10,
	});
	const [filterState, setFilterState] = useState(initialFilterState);
	const [asyncSelectSearch, setAsyncSelectSearch] = useState(
		initialAsyncSelectSearch
	);
	const [asyncSelectSelected, setAsyncSelectSelected] = useState(
		initialAsyncSelectSelected
	);
	const [tableData, setTableData] = useState<any[]>([]);
	const [CSVData, setCSVData] = useState<any[]>([]);
	const [expandIndex, setExpandIndex] = useState<number[]>([]);
	const [total, setTotal] = useState(1);
	const [keyword, setKeyword] = useState('');
	const [emptyMessage, setEmptyMessage] = useState('No Data');
	const [refetch, setRefetch] = useState(false);
	const [filterCollapse, setFilterCollapse] = useState(filterIsOpen);
	const toast = useToast();
	const dispatch = useAppDispatch();

	const updateExpand = (index: number) => {
		if (expandIndex.includes(index)) {
			let temp = expandIndex.filter((idx) => {
				return idx !== index;
			});
			return setExpandIndex(temp);
		}
		return setExpandIndex([...expandIndex, index]);
	};

	const expandAll = () => {
		let temp = Array.from(Array(paginationState.limit).keys());
		setExpandIndex(temp);
	};

	const collapseAll = () => {
		setExpandIndex([]);
	};

	useEffect(() => {
		dispatch(startPageLoading());
	}, [dispatch]);

	useEffect(() => {
		let hasRequiredData = true;

		columns.forEach((col) => {
			if (col.isRequired && !filterState[col.accessor]) {
				hasRequiredData = false;
			}
		});

		const getData = async () => {
			const makeCSVDownloadData = (data: any[]) => {
				let dataArray: any[] = [];
				data.forEach((val) => {
					let temp: any = {};
					columns.forEach((col) => {
						if (col.isPrintable !== false) {
							try {
								temp[col.Header] = col.CSVFormat
									? col.CSVFormat(_.get(val, col.accessor))
									: _.get(val, col.accessor);
							} catch (err) {
								temp[col.Header] = _.get(val, col.accessor);
							}
						}
					});
					dataArray.push(temp);
				});
				return dataArray;
			};

			setTableLoading(true);
			try {
				const result = await axios.get(url, {
					params: {
						sort: { [sortState.fieldName]: sortState.order },
						pagination: paginationState,
						filter: filterState,
					},
				});
				setTableData(result.data.data);
				setCSVData(makeCSVDownloadData(result.data.data));
				setTotal(result.data.total);
			} catch (err: any) {
				console.log(err);
				toast({
					description:
						err.response?.data?.message ||
						'An error occurred while fetching data',
					status: 'error',
				});
			}
			setTableLoading(false);
			dispatch(stopPageLoading());
		};

		if (hasRequiredData) {
			getData();
		} else {
			setTableData([]);
			setEmptyMessage('Please select all filters');
			dispatch(stopPageLoading());
		}
	}, [
		columns,
		url,
		paginationState,
		sortState,
		filterState,
		refetchFlag,
		refetch,
		toast,
		dispatch,
	]);

	const handleSort = (accessor: string) => {
		if (sortState.fieldName === accessor) {
			return setSortState({
				...sortState,
				order: sortState.order === 'ASC' ? 'DESC' : 'ASC',
			});
		}
		return setSortState({ fieldName: accessor, order: 'ASC' });
	};

	const getValue = (row: any, col: ColumnType) => {
		try {
			if (
				_.get(row, col.accessor) === null ||
				_.get(row, col.accessor) === undefined
			) {
				return '-';
			}
			if (col.isDateField) {
				return format(parseISO(_.get(row, col.accessor)), 'dd/MM/yyyy');
			}
			if (col.custom) {
				return col.custom(_.get(row, col.accessor), row);
			}
			return _.get(row, col.accessor);
		} catch (err) {
			return _.get(row, col.accessor);
		}
	};

	const changeLimit = (limit: number) => {
		setPaginationState((p) => ({ ...p, limit }));
	};

	const changePage = (page: number) => {
		setPaginationState((p) => ({ ...p, page }));
	};

	const resetAllFilters = () => {
		unstable_batchedUpdates(() => {
			setFilterState(initialFilterState);
			setAsyncSelectSearch(initialAsyncSelectSearch);
			setAsyncSelectSelected(initialAsyncSelectSelected);
			setKeyword('');
			setPaginationState((p) => ({ ...p, page: 1 }));
			setSortState({ fieldName: 'id', order: 'ASC' });
		});
	};

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}

		const delayDebounceUpdateSearch = setTimeout(
			() =>
				unstable_batchedUpdates(() => {
					setFilterState((state) => ({ ...state, keyword }));
					setPaginationState((p) => ({ ...p, page: 1 }));
				}),
			500
		);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keyword]);

	return (
		<Box rounded='md' position='relative'>
			<Flex
				py='6'
				px='2'
				justifyContent='space-between'
				alignItems={{ base: 'start', md: 'center' }}
				direction={{ base: 'column', md: 'row' }}
			>
				<Heading size='md' mb={{ base: '4', md: '0' }}>
					{pageHeader}
				</Heading>
				<HStack spacing={3} divider={<StackDivider />}>
					{isSearchable && (
						<InputGroup size='sm'>
							<Input
								type='text'
								placeholder='Search'
								onChange={(e) => setKeyword(e.target.value)}
								value={keyword}
							/>
							<InputRightElement children={<FiSearch />} />
						</InputGroup>
					)}
					{exportCSV ? (
						!_.isEmpty(CSVData) ? (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton
										as={CSVLink}
										icon={<FiDownload />}
										data={CSVData}
										size='sm'
										aria-label='download button'
										variant='ghost'
										colorScheme='gray'
									/>
								</Box>
							</Tooltip>
						) : (
							<Tooltip label='Download CSV'>
								<Box>
									<IconButton
										icon={<FiDownload />}
										size='sm'
										disabled
										aria-label='download button'
										variant='ghost'
										colorScheme='gray'
									/>
								</Box>
							</Tooltip>
						)
					) : null}
					{addNewFunction && (
						<Tooltip label='Add New'>
							<IconButton
								icon={<FiPlusSquare />}
								size='sm'
								onClick={addNewFunction}
								aria-label='add button'
								variant='ghost'
								colorScheme='gray'
							/>
						</Tooltip>
					)}
					{refreshData && (
						<Tooltip label='Refresh Data'>
							<IconButton
								icon={<FiRefreshCw />}
								size='sm'
								onClick={() => setRefetch(!refetch)}
								aria-label='refetch button'
								variant='ghost'
								colorScheme='gray'
							/>
						</Tooltip>
					)}
					{Object.keys(filterState).length > 1 && (
						<Tooltip label='Filters'>
							<IconButton
								icon={<FiFilter />}
								onClick={() => setFilterCollapse(!filterCollapse)}
								size='sm'
								colorScheme={filterCollapse ? 'primary' : 'gray'}
								aria-label='show hide filters'
								variant='ghost'
							/>
						</Tooltip>
					)}
					{customButtonFunction && customButtonFunction()}
				</HStack>
			</Flex>
			<Divider />
			<Box px='2' py='8'>
				<Collapse in={filterCollapse}>
					<Box p='2' mb='10' rounded='md'>
						<Wrap spacing={5} justify='end'>
							{columns.map((col, index) => {
								if (col.filter) {
									if (col.filter.type === 'date') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
											>
												<FormLabel fontSize='sm' mb='0'>
													{col.Header}
												</FormLabel>
												<Input
													type='date'
													value={filterState[col.accessor]}
													onChange={(e) =>
														setFilterState({
															...filterState,
															[col.accessor]: e.target.value,
														})
													}
													size='sm'
												/>
											</FormControl>
										);
									}
									if (col.filter.type === 'select') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
											>
												<FormLabel fontSize='sm' mb='0'>
													{col.Header}
												</FormLabel>
												<Select
													placeholder={
														col.filter.default ? undefined : 'Choose an option'
													}
													value={filterState[col.accessor]}
													onChange={(e) =>
														setFilterState({
															...filterState,
															[col.accessor]: e.target.value,
														})
													}
													size='sm'
												>
													{col.filter?.options?.map((option) => {
														return (
															<option value={option.value} key={option.value}>
																{option.label}
															</option>
														);
													})}
												</Select>
											</FormControl>
										);
									}
									if (col.filter.type === 'async-select') {
										return (
											<FormControl
												width='auto'
												key={`filter_${col.Header}`}
												isRequired={col.isRequired ? true : false}
												minW='200px'
												isDisabled={
													col.depends && filterState[col.depends] === ''
														? true
														: false
												}
											>
												<FormLabel fontSize='sm' mb='0'>
													Zone
												</FormLabel>
												<AsyncSelect
													cacheOptions
													defaultOptions
													getOptionLabel={(option: any) =>
														option[col.filter?.labelAccessor!]
													}
													getOptionValue={(option: any) =>
														option[col.filter?.valueAccessor!]
													}
													loadOptions={() =>
														col.filter?.loadOptions(
															asyncSelectSearch[col.accessor],
															filterState
														)
													}
													onInputChange={(v: any) =>
														setAsyncSelectSearch({
															...asyncSelectSearch,
															[col.accessor]: v,
														})
													}
													value={asyncSelectSelected[col.accessor]}
													onChange={(v: any) => {
														setFilterState({
															...filterState,
															[col.accessor]: v[col.filter?.valueAccessor!],
														});
														setAsyncSelectSelected({
															...asyncSelectSelected,
															[col.accessor]: v,
														});
													}}
													menuPosition='fixed'
												/>
												{col.depends && filterState[col.depends] === '' && (
													<FormHelperText>
														{col.depends} is required
													</FormHelperText>
												)}
											</FormControl>
										);
									}
								}
								return null;
							})}
						</Wrap>
					</Box>
					<Divider />
				</Collapse>
				<Box mb='8'>
					{tableLoading && (
						<Progress
							position='absolute'
							top='0'
							left='0'
							width='100%'
							size='xs'
							isIndeterminate
						/>
					)}
					<Flex direction='column' p='2' width='auto' overflowX='auto'>
						<HStack
							p='2'
							divider={<StackDivider />}
							boxShadow='rgb(0 0 0 / 9%) 2px 2px 8px'
							mb='2'
							width='fit-content'
							minWidth='100%'
						>
							{expandableRows && (
								<Box width='50px' px='2' flexShrink={0}>
									<Menu>
										<Tooltip label='Expand options'>
											<MenuButton
												as={IconButton}
												icon={<FiMoreVertical />}
												variant='ghost'
											/>
										</Tooltip>
										<MenuList>
											<MenuItem onClick={() => expandAll()}>
												+ Expand All
											</MenuItem>
											<MenuItem onClick={() => collapseAll()}>
												- Collapse All
											</MenuItem>
										</MenuList>
									</Menu>
								</Box>
							)}
							{columns.map((col) => {
								return (
									<Text
										key={col.accessor}
										fontWeight='bold'
										width={col.width || '120px'}
										px='2'
										_last={{ flexGrow: '1' }}
										flexShrink={0}
										onClick={() => handleSort(col.accessor)}
										cursor='pointer'
										as={Button}
										bg='white'
										_hover={{ bg: 'white' }}
										justifyContent='start'
										textAlign='left'
										whiteSpace='pre-wrap'
										wordBreak='break-word'
										height='auto'
									>
										{col.Header}
										{sortState.fieldName === col.accessor ? (
											sortState.order === 'ASC' ? (
												<FiChevronUp />
											) : (
												<FiChevronDown />
											)
										) : (
											<Box w='12px'></Box>
										)}
									</Text>
								);
							})}
							{actions && (
								<Text
									fontWeight='bold'
									textAlign='right'
									px='2'
									flexShrink={0}
									width='75px'
								>
									Actions
								</Text>
							)}
						</HStack>
						{tableData && tableData.length > 0 ? (
							tableData.map((row, rowIndex) => {
								return (
									<Fragment key={row.id}>
										<HStack
											p='2'
											divider={<StackDivider />}
											boxShadow='rgb(0 0 0 / 9%) 2px 2px 8px'
											mb='2'
											width='fit-content'
											minWidth='100%'
										>
											{expandableRows && (
												<Button
													onClick={() => updateExpand(rowIndex)}
													cursor='pointer'
													px='2'
													width='50px'
													size='sm'
													variant='ghost'
												>
													{expandIndex.includes(rowIndex) ? (
														<FiChevronDown />
													) : (
														<FiChevronRight />
													)}
												</Button>
											)}
											{columns.map((col, colIndex) => {
												if (col.display === false) {
													return null;
												}
												return (
													<Text
														key={`${row.id}_${colIndex}`}
														textAlign='left'
														px='2'
														_last={{ flexGrow: '1' }}
														width={col.width || '120px'}
														wordBreak='break-word'
													>
														{getValue(row, col)}
													</Text>
												);
											})}
											{actions && (
												<Box
													display='flex'
													justifyContent='end'
													px='2'
													width='75px'
												>
													<Menu autoSelect={false}>
														<MenuButton
															as={IconButton}
															icon={<FiMoreVertical />}
															aria-label='more options'
															size='sm'
															variant='ghost'
														/>
														<MenuList>
															{actions.map((action) => {
																return (
																	<MenuItem
																		key={action.name}
																		icon={action.icon}
																		color='gray.600'
																		_hover={{
																			bg: 'white',
																			color: 'primary.400',
																		}}
																		onClick={() => action.onTrigger(row)}
																	>
																		{action.tooltip}
																	</MenuItem>
																);
															})}
														</MenuList>
													</Menu>
												</Box>
											)}
										</HStack>
										{expandableRows && (
											<Box
												p='0'
												border={
													expandIndex.includes(rowIndex) ? 'auto' : 'none'
												}
											>
												<Collapse in={expandIndex.includes(rowIndex)}>
													{expandFunction!(row)}
												</Collapse>
											</Box>
										)}
									</Fragment>
								);
							})
						) : (
							<Text w='full' textAlign='center' flexGrow={1}>
								{emptyMessage}
							</Text>
						)}
					</Flex>
				</Box>
				<Flex justifyContent='space-between' alignItems='center' mt='8'>
					{Object.keys(filterState).length > 1 ? (
						<Button
							leftIcon={<FiFilter />}
							onClick={resetAllFilters}
							colorScheme='orange'
						>
							Reset Filters
						</Button>
					) : (
						<Box></Box>
					)}

					<HStack spacing={3} alignItems='center' justifyContent='flex-end'>
						<Select
							width='auto'
							size='sm'
							value={paginationState.limit}
							onChange={(e) => {
								changeLimit(parseInt(e.target.value));
								changePage(1);
							}}
						>
							<option value='10'>10 Rows</option>
							<option value='20'>20 Rows</option>
							<option value='50'>50 Rows</option>
						</Select>
						<IconButton
							icon={<FiChevronsLeft />}
							onClick={() => changePage(1)}
							disabled={paginationState.page === 1}
							size='sm'
							aria-label='first page'
						/>
						<IconButton
							icon={<FiChevronLeft />}
							onClick={() => changePage(paginationState.page - 1)}
							disabled={paginationState.page === 1}
							size='sm'
							aria-label='previous page'
						/>
						<Square size='30px' color='white' bg='blue.500' rounded='md'>
							{paginationState.page}
						</Square>
						<IconButton
							icon={<FiChevronRight />}
							onClick={() => changePage(paginationState.page + 1)}
							disabled={
								paginationState.page >= Math.ceil(total / paginationState.limit)
							}
							size='sm'
							aria-label='next page'
						/>
						<IconButton
							icon={<FiChevronsRight />}
							onClick={() =>
								changePage(Math.ceil(total / paginationState.limit))
							}
							disabled={
								paginationState.page ===
								Math.ceil(total / paginationState.limit)
							}
							size='sm'
							aria-label='last page'
						/>
					</HStack>
				</Flex>
			</Box>
		</Box>
	);
};

DataTable.defaultProps = {
	exportCSV: true,
	isSearchable: true,
	refreshData: true,
	filterIsOpen: false,
};
