import { ModuleTypes } from '../types';

export const getCurrentModule = () => {
	return localStorage.getItem('module');
};

export const setCurrentModule = (s: ModuleTypes) => {
	return localStorage.setItem('module', s);
};

export const resetModule = () => {
	return localStorage.removeItem('module');
};
