import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import { theme } from './theme';

export const HBS = () => {
	return (
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<DndProvider backend={HTML5Backend}>
					<App />
				</DndProvider>
			</ChakraProvider>
		</Provider>
	);
};
