import {
	Alert,
	AlertDescription,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	ModalBody,
	ModalFooter,
	Text,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';

interface AddTemplateStepOneProps {
	closeFunction: () => void;
	submitFunction: (s: string) => void;
}

export const AddTemplateStepOne: React.FC<AddTemplateStepOneProps> = ({
	closeFunction,
	submitFunction,
}) => {
	const toast = useToast();
	const formik = useFormik({
		initialValues: { templateName: '' },
		onSubmit: async (values, { setStatus }) => {
			try {
				const templateResult = await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template`,
					{ name: values.templateName }
				);

				toast({
					description: 'Template created',
					status: 'success',
				});
				submitFunction(templateResult.data.data._id);
			} catch (err: any) {
				setStatus(err?.response?.data?.message || 'An error occurred');
			}
		},
	});
	const onClose = () => {
		formik.resetForm();
		closeFunction();
	};
	return (
		<form onSubmit={formik.handleSubmit}>
			<ModalBody>
				<Text color='gray.500' fontWeight='medium' mb='2'>
					Template Details
				</Text>
				{formik.status && (
					<Alert status='error' mb='2'>
						<AlertIcon />
						<AlertDescription>{formik.status}</AlertDescription>
					</Alert>
				)}
				<FormControl
					id='templateName'
					isInvalid={formik.errors.templateName ? true : false}
					mb='2'
					isRequired
				>
					<FormLabel>Name</FormLabel>
					<Input type='text' {...formik.getFieldProps('templateName')} />
					<FormErrorMessage>{formik.errors.templateName}</FormErrorMessage>
				</FormControl>
			</ModalBody>

			<ModalFooter justifyContent='center'>
				<Button
					colorScheme='green'
					mr={3}
					type='submit'
					isLoading={formik.isSubmitting}
				>
					Submit
				</Button>
				<Button colorScheme='gray' onClick={onClose}>
					Cancel
				</Button>
			</ModalFooter>
		</form>
	);
};
