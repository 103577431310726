import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';

interface DeleteWidgetWarningModalProps {
	endFunction: () => void;
	isOpen: boolean;
	onClose: () => void;
}

export const DeleteWidgetWarningModal: React.FC<
	DeleteWidgetWarningModalProps
> = ({ endFunction, isOpen, onClose }) => {
	const [loading, setLoading] = useState(false);
	const cancelRef = useRef(null);

	const closeFunction = async () => {
		setLoading(true);
		await endFunction();
		setLoading(false);
		onClose();
	};

	return (
		<>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
			>
				<AlertDialogOverlay>
					<AlertDialogContent>
						<AlertDialogHeader fontSize='lg' fontWeight='bold'>
							Delete Widget
						</AlertDialogHeader>

						<AlertDialogBody>
							Are you sure? This action cannot be undone. To save this action
							you will need to save the sub-template
						</AlertDialogBody>

						<AlertDialogFooter>
							<Button
								colorScheme='red'
								onClick={closeFunction}
								mr={3}
								isLoading={loading}
							>
								Delete
							</Button>
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
