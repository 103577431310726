import {
	useDisclosure,
	Center,
	Box,
	Heading,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
} from '@chakra-ui/react';
import React, { useMemo, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { DataTable } from '../../../components/DataTable';
import { LatLong } from '../../../types';
import { ifStringToArray } from '../../../utils/ifStringToArray';
import { AddAreaModalForm } from './partials/AddAreaModalForm';
import { DeleteAreaConfirmModal } from './partials/DeleteAreaConfirmModal';
import { EditAreaModalForm } from './partials/EditAreaModalForm';

interface AreaListProps {}

export const AreaList: React.FC<AreaListProps> = () => {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);

	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/area`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Area',
				accessor: 'name',
				width: '250px',
			},
			{
				Header: 'City',
				accessor: 'city.name',
				width: '250px',
			},
			{
				Header: 'Zone',
				accessor: 'zone.name',
				width: '250px',
			},
		],
		[]
	);

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: <FiTrash2 />,
				color: 'red',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal]
	);

	const expandFunction = (row: any) => {
		return (
			<Center px='10' py='6' bg='cyan.200'>
				<Box>
					<Heading size='sm' mb='2'>
						Coordinates
					</Heading>
					<Table
						style={{
							tableLayout: 'fixed',
							width: '100%',
							maxWidth: '600px',
						}}
						bg='white'
						rounded='md'
					>
						<Thead>
							<Tr>
								<Th
									width='250px'
									textAlign='center'
									borderRight='1px solid'
									borderRightColor='gray.100'
								>
									Latitude
								</Th>
								<Th width='250px' textAlign='center'>
									Longitude
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{row.geocoordinates &&
								row.geocoordinates.map((val: LatLong, index: number) => {
									return (
										<Tr key={'coord' + row.id + index.toString()}>
											<Td
												borderRight='1px solid'
												borderRightColor='gray.100'
												textAlign='center'
											>
												{val.lat}
											</Td>
											<Td textAlign='center'>{val.long}</Td>
										</Tr>
									);
								})}
						</Tbody>
					</Table>
				</Box>
			</Center>
		);
	};

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			<EditAreaModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteAreaConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddAreaModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<DataTable
				columns={columns}
				actions={actions}
				url={url}
				exportCSV={true}
				addNewFunction={openAddModal}
				pageHeader='Area List'
				expandableRows={true}
				expandFunction={expandFunction}
				refetchFlag={refetchFlag}
			/>
		</>
	);
};
