import { AdBanner } from './AdBanner';
import { CategoryStart } from './CategoryStart';
import { DealsView } from './DealsView';
import { DealsViewV2 } from './DealsViewV2';
import { DynamicSectionList } from './DynamicSectionList';
import { DynamicSectionSlider } from './DynamicSectionSlider';
import { FullWidthCarousel } from './FullWidthCarousel';
import { GridAllCategory } from './GridAll';
import { NoMarginBanner } from './NoMarginBanner';
import { OfferBanner } from './OfferBanner';
import { ShopAndSave } from './ShopAndSave';
import { TwoBannerGrid } from './TwoBannerGrid';

interface SectionComponentsList {
	[key: string]: JSX.Element;
}

export const sectionComponents: SectionComponentsList = {
	CATEGORY_START: <CategoryStart />,
	FULL_IMAGE_CAROUSEL: <FullWidthCarousel />,
	SHOP_AND_SAVE: <ShopAndSave />,
	GRID_ALL_CATEGORY: <GridAllCategory />,
	GRID_WITH_BUTTON: <GridAllCategory />,
	GRID_ITEM_WITH_TIME: <GridAllCategory />,
	GRID: <GridAllCategory />,
	CAROUSEL: <FullWidthCarousel />,
	AD_BANNER: <AdBanner />,
	THREE_BANNER_GRID: <CategoryStart />,
	OFFER_BANNER: <OfferBanner />,
	NO_MARGIN_BANNER: <NoMarginBanner />,
	GRID_VIEW: <ShopAndSave />,
	TWO_BANNER_GRID: <TwoBannerGrid />,
	SLIDER_CATEGORY: <TwoBannerGrid />,
	DYNAMIC_SECTION_SLIDER: <DynamicSectionSlider />,
	DYNAMIC_SECTION_LIST: <DynamicSectionList />,
	DEALS_VIEW_V2: <DealsViewV2 />,
	DEALS_VIEW: <DealsView />,
};
