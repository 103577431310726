import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubTemplateType, SubTemplateWidgetType } from '../../types/types';

interface CombinedSectionPayload {
	subTemplate: SubTemplateType;
	identifier?: string;
	id?: string;
}

interface UpdateToNewSubTemplatePayload {
	subTemplate: SubTemplateType;
	identifier?: string;
	id?: string;
	position: number;
}

interface CombinedWidgetPayload {
	widget: SubTemplateWidgetType;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

interface MoveSubTemplatePayload {
	id?: string;
	identifier?: string;
	oldPosition: number;
	newPosition: number;
}

interface MoveWidgetPayload {
	identifier: string;
	oldPosition: number;
	newPosition: number;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

interface RemoveSubTemplateFromContentPayload {
	id?: string;
	identifier?: string;
	position: number;
}

interface RemoveWidgetFromContentPayload {
	identifier: string;
	position: number;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

interface InitialState {
	content: SubTemplateType[];
	isSaved: string[];
	isPublished: boolean;
	isOrderChanged: boolean;
	isChangedImage: string[];
}

const initialState: InitialState = {
	content: [],
	isSaved: [],
	isPublished: false,
	isOrderChanged: false,
	isChangedImage: [],
};

const subTemplateSlice = createSlice({
	name: 'subTemplate',
	initialState,
	reducers: {
		addPageToContent: (
			state,
			action: PayloadAction<{ page: SubTemplateType[] }>
		) => {
			const { page } = action.payload;
			let temp = page.map(({ createdAt, updatedAt, __v, ...rest }) => {
				return { ...rest };
			});

			state.content = temp;
		},
		addEmptySectionToContent: (
			state,
			action: PayloadAction<CombinedSectionPayload>
		) => {
			const { subTemplate } = action.payload;
			let tempId = subTemplate._id ? subTemplate._id : subTemplate.identifier;

			state.content.push(subTemplate);

			if (!state.isSaved.includes(tempId!)) {
				state.isSaved.push(tempId!);
			}
		},
		addEditSubTemplateSectionToContent: (
			state,
			action: PayloadAction<CombinedSectionPayload>
		) => {
			const { subTemplate } = action.payload;

			state.content = [subTemplate];
		},
		updateSectionDetails: (
			state,
			action: PayloadAction<CombinedSectionPayload>
		) => {
			const { subTemplate } = action.payload;

			let temp = subTemplate._id ? subTemplate._id : subTemplate.identifier;

			state.content = state.content.map((sub) => {
				if (sub[subTemplate._id ? '_id' : 'identifier'] === temp) {
					return { ...subTemplate, widget: sub.widget };
				}
				return sub;
			});

			if (!state.isSaved.includes(temp!)) {
				state.isSaved.push(temp!);
			}
		},
		moveSubTemplate: (state, action: PayloadAction<MoveSubTemplatePayload>) => {
			const { id, identifier, oldPosition, newPosition } = action.payload;
			let temp = id ? id : identifier;
			state.content = state.content.map((sub) => {
				if (sub[id ? '_id' : 'identifier'] === temp) {
					return { ...sub, position: newPosition };
				}
				if (sub.position !== undefined) {
					if (oldPosition > newPosition) {
						if (sub.position < oldPosition && sub.position >= newPosition) {
							return { ...sub, position: sub.position + 1 };
						}
					}
					if (oldPosition < newPosition) {
						if (sub.position > oldPosition && sub.position <= newPosition) {
							return { ...sub, position: sub.position - 1 };
						}
					}
				}
				return sub;
			});
			state.isOrderChanged = true;
		},
		removeSubTemplateFromContent: (
			state,
			action: PayloadAction<RemoveSubTemplateFromContentPayload>
		) => {
			const { id, identifier, position } = action.payload;
			let temp = id ? id : identifier;

			state.content = state.content
				.filter((sub) => sub[id ? '_id' : 'identifier'] !== temp)
				.map((sub) => {
					if (sub.position !== undefined && sub.position > position) {
						return { ...sub, position: sub.position - 1 };
					}
					return sub;
				});

			if (state.isSaved.includes(temp!)) {
				state.isSaved = state.isSaved.filter((v) => v !== temp);
				state.isPublished = false;
			}
		},
		updateToNewSubTemplate: (
			state,
			action: PayloadAction<UpdateToNewSubTemplatePayload>
		) => {
			const { subTemplate, identifier, id, position } = action.payload;

			let temp = id ? id : identifier;

			state.content = state.content.map((sub) => {
				if (sub[id ? '_id' : 'identifier'] === temp) {
					return { ...subTemplate, position };
				}
				return sub;
			});

			subTemplate.widget.forEach((wid) => {
				if (state.isChangedImage.includes(wid.details?.identifier)) {
					state.isChangedImage = state.isChangedImage.filter(
						(v) => v !== wid.details?.identifier
					);
				}
			});
		},
		addWidgetToContent: (
			state,
			action: PayloadAction<CombinedWidgetPayload>
		) => {
			const { widget, subTemplateIdentifier } = action.payload;

			let tempSubId = subTemplateIdentifier.id
				? subTemplateIdentifier.id
				: subTemplateIdentifier.identifier;
			state.content = state.content.map((sub) => {
				if (
					sub[subTemplateIdentifier.id ? '_id' : 'identifier'] === tempSubId
				) {
					sub.widget.push(widget);
				}
				return sub;
			});
			if (!state.isChangedImage.includes(widget.details?.identifier)) {
				state.isChangedImage.push(widget.details?.identifier);
			}
			if (!state.isSaved.includes(tempSubId!)) {
				state.isSaved.push(tempSubId!);
			}
		},
		updateWidgetDetailsToContent: (
			state,
			action: PayloadAction<CombinedWidgetPayload>
		) => {
			const { widget, subTemplateIdentifier } = action.payload;

			let tempSubId = subTemplateIdentifier.id
				? subTemplateIdentifier.id
				: subTemplateIdentifier.identifier;

			state.content = state.content.map((sub) => {
				if (
					sub[subTemplateIdentifier.id ? '_id' : 'identifier'] === tempSubId
				) {
					sub.widget = sub.widget.map((wid) => {
						if (wid.details?.identifier === widget.details?.identifier) {
							if (wid.details?.imageUrl !== widget.details?.imageUrl) {
								if (
									!state.isChangedImage.includes(widget.details?.identifier)
								) {
									state.isChangedImage.push(widget.details?.identifier);
								}
							}
							if (wid.details?.imageUrlWeb !== widget.details?.imageUrlWeb) {
								if (
									!state.isChangedImage.includes(widget.details?.identifier)
								) {
									state.isChangedImage.push(widget.details?.identifier);
								}
							}
							return widget;
						}
						return wid;
					});
				}
				return sub;
			});
			if (!state.isSaved.includes(tempSubId!)) {
				state.isSaved.push(tempSubId!);
			}
		},
		removeWidgetFromContent: (
			state,
			action: PayloadAction<RemoveWidgetFromContentPayload>
		) => {
			const { identifier, position, subTemplateIdentifier } = action.payload;

			let tempSubId = subTemplateIdentifier.id
				? subTemplateIdentifier.id
				: subTemplateIdentifier.identifier;
			state.content = state.content.map((sub) => {
				if (
					sub[subTemplateIdentifier.id ? '_id' : 'identifier'] === tempSubId
				) {
					sub.widget = sub.widget
						.filter((wid) => wid.details!.identifier !== identifier)
						.map((wid) => {
							if (
								wid.details?.position !== undefined &&
								wid.details?.position > position
							) {
								return { ...wid, position: wid.details?.position - 1 };
							}
							return wid;
						});
				}
				return sub;
			});
			if (state.isChangedImage.includes(identifier)) {
				state.isChangedImage = state.isChangedImage.filter(
					(v) => v !== identifier
				);
			}
			if (!state.isSaved.includes(tempSubId!)) {
				state.isSaved.push(tempSubId!);
			}
		},
		moveWidgetInContent: (state, action: PayloadAction<MoveWidgetPayload>) => {
			const { identifier, oldPosition, newPosition, subTemplateIdentifier } =
				action.payload;
			let tempSubId = subTemplateIdentifier.id
				? subTemplateIdentifier.id
				: subTemplateIdentifier.identifier;
			state.content = state.content.map((sub) => {
				if (
					sub[subTemplateIdentifier.id ? '_id' : 'identifier'] === tempSubId
				) {
					sub.widget = sub.widget.map((wid) => {
						if (wid.details?.identifier === identifier) {
							return {
								...wid,
								details: { ...wid.details, position: newPosition },
							};
						}
						if (wid.details?.position !== undefined) {
							if (oldPosition > newPosition) {
								if (
									wid.details?.position < oldPosition &&
									wid.details?.position >= newPosition
								) {
									return {
										...wid,
										details: {
											...wid.details,
											position: wid.details?.position + 1,
										},
									};
								}
							}
							if (oldPosition < newPosition) {
								if (
									wid.details?.position > oldPosition &&
									wid.details?.position <= newPosition
								) {
									return {
										...wid,
										details: {
											...wid.details,
											position: wid.details?.position - 1,
										},
									};
								}
							}
						}
						return wid;
					});
				}
				return sub;
			});

			if (!state.isSaved.includes(tempSubId!)) {
				state.isSaved.push(tempSubId!);
			}
		},
		setIsPublished: (
			state,
			action: PayloadAction<{ isPublished: boolean }>
		) => {
			state.isPublished = action.payload.isPublished;
		},

		removeFromIsSaved: (
			state,
			action: PayloadAction<{ identifier: string }>
		) => {
			const { identifier } = action.payload;
			state.isSaved = state.isSaved.filter((v) => v !== identifier);
			state.isPublished = false;
		},

		resetOrderChanged: (state) => {
			state.isOrderChanged = false;
		},

		clearContent: (state) => {
			return { ...initialState, content: [], isChangedImage: [], isSaved: [] };
		},
	},
});

export const {
	addEmptySectionToContent,
	addWidgetToContent,
	moveSubTemplate,
	removeSubTemplateFromContent,
	updateSectionDetails,
	updateWidgetDetailsToContent,
	removeFromIsSaved,
	removeWidgetFromContent,
	moveWidgetInContent,
	addPageToContent,
	resetOrderChanged,
	updateToNewSubTemplate,
	clearContent,
	addEditSubTemplateSectionToContent,
	setIsPublished,
} = subTemplateSlice.actions;

export const subTemplateReducer = subTemplateSlice.reducer;
