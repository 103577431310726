import { Flex, Center, Icon, Text } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface DynamicSectionSliderProps {}

export const DynamicSectionSlider: React.FC<DynamicSectionSliderProps> = () => {
	return (
		<Flex alignItems='center' height='100px'>
			<Text>{`<`}</Text>
			<Center bg='primary.100' w='100%' height='100%' mr='1'>
				<Icon as={FiImage} />
			</Center>
			<Center bg='primary.100' w='100%' height='100%' mr='1'>
				<Icon as={FiImage} />
			</Center>
			<Center bg='primary.100' w='100%' height='100%'>
				<Icon as={FiImage} />
			</Center>
			<Text>{`>`}</Text>
		</Flex>
	);
};
