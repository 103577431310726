import { Center, Icon, Square } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface SquareGridItemProps {}

export const SquareGridItem: React.FC<SquareGridItemProps> = () => {
	return (
		<Center w='100%' height='100px' p='4'>
			<Square bg='primary.100' size='80px'>
				<Icon as={FiImage} />
			</Square>
		</Center>
	);
};
