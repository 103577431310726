import { Center, Icon, Box } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface CombinationDealItemProps {}

export const CombinationDealItem: React.FC<CombinationDealItemProps> = () => {
	return (
		<Center flexDirection='column' p='4' bg='primary.100' height='100px'>
			<Center flex='1' bg='white' w='100%' mb='2'>
				<Icon as={FiImage} />
			</Center>
			<Box bg='gray.500' height='12px' width='50px'></Box>
		</Center>
	);
};
