import { Icon, IconProps } from '@chakra-ui/react';
import React from 'react';

interface DragIconProps extends IconProps {}

export const DragIcon: React.FC<DragIconProps> = ({ ...props }) => {
	return (
		<Icon
			width='8px'
			height='14px'
			viewBox='0 0 8 14'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
			{...props}
		>
			<path
				d='M6.13343 2.2C5.99198 2.2 5.85633 2.14381 5.75631 2.04379C5.65629 1.94377 5.6001 1.80811 5.6001 1.66666C5.6001 1.52522 5.65629 1.38956 5.75631 1.28954C5.85633 1.18952 5.99198 1.13333 6.13343 1.13333C6.27488 1.13333 6.41054 1.18952 6.51056 1.28954C6.61058 1.38956 6.66677 1.52522 6.66677 1.66666C6.66677 1.80811 6.61058 1.94377 6.51056 2.04379C6.41054 2.14381 6.27488 2.2 6.13343 2.2Z'
				stroke='currentColor'
			/>
			<path
				d='M6.13343 7.53334C5.99198 7.53334 5.85633 7.47715 5.75631 7.37713C5.65629 7.27711 5.6001 7.14146 5.6001 7.00001C5.6001 6.85856 5.65629 6.7229 5.75631 6.62288C5.85633 6.52287 5.99198 6.46667 6.13343 6.46667C6.27488 6.46667 6.41054 6.52287 6.51056 6.62288C6.61058 6.7229 6.66677 6.85856 6.66677 7.00001C6.66677 7.14146 6.61058 7.27711 6.51056 7.37713C6.41054 7.47715 6.27488 7.53334 6.13343 7.53334Z'
				stroke='currentColor'
			/>
			<path
				d='M6.13343 12.8667C5.99198 12.8667 5.85633 12.8105 5.75631 12.7104C5.65629 12.6104 5.6001 12.4748 5.6001 12.3333C5.6001 12.1919 5.65629 12.0562 5.75631 11.9562C5.85633 11.8562 5.99198 11.8 6.13343 11.8C6.27488 11.8 6.41054 11.8562 6.51056 11.9562C6.61058 12.0562 6.66677 12.1919 6.66677 12.3333C6.66677 12.4748 6.61058 12.6104 6.51056 12.7104C6.41054 12.8105 6.27488 12.8667 6.13343 12.8667Z'
				stroke='currentColor'
			/>
			<path
				d='M1.86683 2.2C1.72538 2.2 1.58973 2.14381 1.48971 2.04379C1.38969 1.94377 1.3335 1.80811 1.3335 1.66666C1.3335 1.52522 1.38969 1.38956 1.48971 1.28954C1.58973 1.18952 1.72538 1.13333 1.86683 1.13333C2.00828 1.13333 2.14393 1.18952 2.24395 1.28954C2.34397 1.38956 2.40016 1.52522 2.40016 1.66666C2.40016 1.80811 2.34397 1.94377 2.24395 2.04379C2.14393 2.14381 2.00828 2.2 1.86683 2.2Z'
				stroke='currentColor'
			/>
			<path
				d='M1.86683 7.53334C1.72538 7.53334 1.58973 7.47715 1.48971 7.37713C1.38969 7.27711 1.3335 7.14146 1.3335 7.00001C1.3335 6.85856 1.38969 6.7229 1.48971 6.62288C1.58973 6.52287 1.72538 6.46667 1.86683 6.46667C2.00828 6.46667 2.14393 6.52287 2.24395 6.62288C2.34397 6.7229 2.40016 6.85856 2.40016 7.00001C2.40016 7.14146 2.34397 7.27711 2.24395 7.37713C2.14393 7.47715 2.00828 7.53334 1.86683 7.53334Z'
				stroke='currentColor'
			/>
			<path
				d='M1.86683 12.8667C1.72538 12.8667 1.58973 12.8105 1.48971 12.7104C1.38969 12.6104 1.3335 12.4748 1.3335 12.3333C1.3335 12.1919 1.38969 12.0562 1.48971 11.9562C1.58973 11.8562 1.72538 11.8 1.86683 11.8C2.00828 11.8 2.14393 11.8562 2.24395 11.9562C2.34397 12.0562 2.40016 12.1919 2.40016 12.3333C2.40016 12.4748 2.34397 12.6104 2.24395 12.7104C2.14393 12.8105 2.00828 12.8667 1.86683 12.8667Z'
				stroke='currentColor'
			/>
		</Icon>
	);
};
