import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './redux/store';
import { theme } from './lib/theme';

export const ZMS = () => {
	return (
		<Provider store={store}>
			<ChakraProvider theme={theme}>
				<App />
			</ChakraProvider>
		</Provider>
	);
};
