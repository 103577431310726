import {
	Alert,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

interface ChangePasswordModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: any;
}

export const ChangePasswordModalForm: React.FC<
	ChangePasswordModalFormProps
> = ({ url, isOpen, onClose, selectedItem }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const initialValues = {
		newPassword: '',
		confirmPassword: '',
	};

	const validationSchema = Yup.object().shape({
		newPassword: Yup.string().required('Password is required'),
		confirmPassword: Yup.string().oneOf(
			[Yup.ref('newPassword'), null],
			'Passwords must match'
		),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			return axios
				.put(`${url}/reset-password/${selectedItem?.id}`, values)
				.then((res) => {
					toast({
						description: `Password updated for user account ${selectedItem?.email}`,
						status: 'success',
					});
					resetAndCloseAddModal();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Change Password</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						<Text>Changing Password For User {selectedItem?.email}</Text>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.newPassword} mb='4'>
							<FormLabel>New Password</FormLabel>
							<InputGroup>
								<Input
									{...formik.getFieldProps('newPassword')}
									type={showPassword ? 'text' : 'password'}
									pr='75px'
								/>
								<InputRightElement w='75px'>
									<Button
										onClick={() => setShowPassword((p) => !p)}
										h='30px'
										size='sm'
									>
										{showPassword ? 'Hide' : 'Show'}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>{formik.errors.newPassword}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.confirmPassword} mb='4'>
							<FormLabel>Confirm Password</FormLabel>
							<InputGroup>
								<Input
									{...formik.getFieldProps('confirmPassword')}
									type={showConfirmPassword ? 'text' : 'password'}
									pr='75px'
								/>
								<InputRightElement w='75px'>
									<Button
										onClick={() => setShowConfirmPassword((p) => !p)}
										h='30px'
										size='sm'
									>
										{showConfirmPassword ? 'Hide' : 'Show'}
									</Button>
								</InputRightElement>
							</InputGroup>
							<FormErrorMessage>
								{formik.errors.confirmPassword}
							</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal} type='button'>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
