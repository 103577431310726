import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { getUmsPerms } from '../utils/getUmsPerms';
import { DashboardLayout } from './layouts/DashboardLayout';
import { AddRolesForm } from './pages/roles/partials/AddRoleForm';
import { EditRolesForm } from './pages/roles/partials/EditRoleForm';
import { RolesDataTable } from './pages/roles/partials/RolesDataTable';
import { Users } from './pages/users/Users';

interface PageRoutesProps {}

export const PageRoutes: React.FC<PageRoutesProps> = () => {
	const { hasRolePerm, hasUserPerm } = getUmsPerms();
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<DashboardLayout />}>
					<Route
						index
						element={
							<Navigate
								to={
									hasUserPerm ? '/users' : hasRolePerm ? '/roles' : '/nothing'
								}
							/>
						}
					/>
					{hasUserPerm && <Route path='/users' element={<Users />} />}
					{hasRolePerm && (
						<>
							<Route path='/roles' element={<RolesDataTable />} />
							<Route path='/roles/add' element={<AddRolesForm />} />
							<Route path='/roles/edit/:roleId' element={<EditRolesForm />} />
						</>
					)}
					<Route path='/nothing' element={<div>not found</div>} />
				</Route>
				<Route path='*' element={<Navigate to='/' />} />
			</Routes>
		</BrowserRouter>
	);
};
