import { Box, Flex, Heading, HStack } from '@chakra-ui/layout';
import {
	Center,
	Divider,
	Icon,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Progress,
	Select,
	StackDivider,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FiMoreVertical, FiSearch, FiUpload } from 'react-icons/fi';
import { CardBox } from '../../components/CardBox';
import { Pagination } from '../../components/Pagination';
import { FitlersType, StoreType } from '../../types/types';
import { ChangeTemplateModal } from './partials/ChangeTemplateModal';

interface ListStoresProps {}

interface StoreDetailsType {
	storeId: string;
	storeName: string;
	currentTemplate: string | undefined;
}

export const ListStores: React.FC<ListStoresProps> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [storeList, setStoreList] = useState<StoreType[]>([]);
	const [storeDetails, setStoreDetails] = useState<StoreDetailsType>({
		storeId: '',
		storeName: '',
		currentTemplate: '',
	});
	const [refetchFlag, setRefetchFlag] = useState(false);

	const [totalItems, setTotalItems] = useState(0);

	const [keywordInput, setKeywordInput] = useState('');
	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);

	const [filters, setFilters] = useState<FitlersType>({
		pagination: {
			page: 1,
			limit: 5,
		},
		sort: {
			siteName: 'ASC',
		},
		filter: {
			keyword: '',
		},
	});

	useEffect(() => {
		const getData = async () => {
			setLoading(true);
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/store`,
				{
					params: {
						...filters,
					},
				}
			);

			setStoreList(result.data.data);
			setTotalItems(result.data.total);
			setLoading(false);
			setInitialLoading(false);
		};
		getData();
	}, [filters, refetchFlag]);

	const openModalAndSetStoreId = (details: StoreDetailsType) => {
		setStoreDetails(details);
		onOpen();
	};

	const setPage = (pg: number) => {
		setFilters({
			...filters,
			pagination: { ...filters.pagination, page: pg },
		});
	};

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilters((f) => ({
				...f,
				filter: { ...f.filter, keyword: keywordInput },
				pagination: { ...f.pagination, page: 1 },
			}));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keywordInput]);

	const refetchFunction = () => {
		setRefetchFlag(!refetchFlag);
	};

	return (
		<>
			{!initialLoading && (
				<ChangeTemplateModal
					isOpen={isOpen}
					onClose={onClose}
					storeId={storeDetails.storeId}
					storeName={storeDetails.storeName}
					currentTemplate={storeDetails.currentTemplate}
					refetchFunction={refetchFunction}
				/>
			)}
			<Flex alignItems='center' justifyContent='space-between' py='2'>
				<Heading color='gray.800' fontSize='2xl'>
					Stores
				</Heading>
			</Flex>
			<Divider />
			<Flex py='2' justifyContent='flex-end' alignItems='center' mb='1'>
				<Flex>
					<Flex alignItems='center' mr='2'>
						<Text color='gray.500' whiteSpace='nowrap' mr='2'>
							Sort by:
						</Text>
						<Select
							width='150px'
							value={Object.keys(filters.sort)[0]}
							onChange={(e) =>
								setFilters({ ...filters, sort: { [e.target.value]: 'ASC' } })
							}
						>
							<option value='siteName'>Site Name</option>
							<option value='createdAt'>Created At</option>
						</Select>
					</Flex>
					<InputGroup maxWidth='250px'>
						<InputLeftElement
							pointerEvents='none'
							children={<Icon as={FiSearch} color='gray.500' />}
						/>
						<Input
							placeholder='Search for Templates'
							value={keywordInput}
							onChange={(e) => setKeywordInput(e.target.value)}
						/>
					</InputGroup>
				</Flex>
			</Flex>
			<Box mb='4'>
				{loading && (
					<Progress
						position='absolute'
						top='0'
						left='0'
						width='100%'
						size='xs'
						isIndeterminate
					/>
				)}
				{storeList && storeList.length > 0 ? (
					<>
						<VStack
							spacing={2}
							divider={<StackDivider borderColor='gray.100' />}
							align='stretch'
							mb='4'
						>
							{storeList.map((store) => {
								return (
									<CardBox key={store._id}>
										<Flex
											alignItems='center'
											justifyContent='space-between'
											py='4'
											px='6'
										>
											<HStack
												spacing={4}
												divider={<StackDivider borderColor='gray.100' />}
											>
												<Box width='250px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Site Name
													</Text>
													<Text
														color='gray.800'
														fontWeight='bold'
														fontSize='md'
													>
														{store.websiteName}
													</Text>
												</Box>
												<Box width='80px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														SAP ID
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{store.sapWebsiteId}
													</Text>
												</Box>
												<Box width='150px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Site Code
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{store.websiteCode}
													</Text>
												</Box>
												<Box width='250px'>
													<Text mb='2' fontSize='sm' color='gray.500'>
														Active Template
													</Text>
													<Text
														color='gray.600'
														fontWeight='bold'
														fontSize='md'
													>
														{store.template ? store.template.name : '-'}
													</Text>
												</Box>
											</HStack>
											<Menu autoSelect={false}>
												<MenuButton
													as={IconButton}
													icon={<FiMoreVertical />}
													variant='ghost'
												/>
												<MenuList>
													<MenuItem
														icon={<FiUpload />}
														onClick={() =>
															openModalAndSetStoreId({
																storeId: store._id,
																storeName: store.websiteName,
																currentTemplate: store.template?._id,
															})
														}
														color='gray.600'
														_hover={{ bg: 'white', color: 'primary.400' }}
													>
														Change store template
													</MenuItem>
												</MenuList>
											</Menu>
										</Flex>
									</CardBox>
								);
							})}
						</VStack>
						<Pagination
							limit={filters.pagination.limit}
							page={filters.pagination.page}
							setPage={setPage}
							totalItems={totalItems}
							setLimit={(limit: number) =>
								setFilters({
									...filters,
									pagination: {
										...filters.pagination,
										limit: limit,
									},
								})
							}
						/>
					</>
				) : (
					<CardBox>
						<Center height='85px' fontSize='lg' fontWeight='semibold'>
							No data
						</Center>
					</CardBox>
				)}
			</Box>
		</>
	);
};
