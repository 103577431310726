import { Circle, Grid } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface GridAllCategoryProps {}

export const GridAllCategory: React.FC<GridAllCategoryProps> = () => {
	return (
		<Grid
			templateColumns='repeat(4,1fr)'
			bg='primary.100'
			gap={2}
			height='100px'
			p='2'
		>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
			<Circle bg='white'>
				<FiImage />
			</Circle>
		</Grid>
	);
};
