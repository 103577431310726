import { LinkBox, LinkOverlay } from '@chakra-ui/layout';
import React from 'react';
import { Link, useResolvedPath, useMatch } from 'react-router-dom';
import { Flex, Box } from '@chakra-ui/react';

interface SideNavLinkProps {
	linkTo: string;
}

export const SideNavLink: React.FC<SideNavLinkProps> = ({
	linkTo,
	children,
}) => {
	const resolved = useResolvedPath(linkTo);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<LinkBox
			color={match ? 'primary.400' : 'gray.500'}
			fontSize='sm'
			width='100%'
		>
			<Flex alignItems='center'>
				<Box
					width='8px'
					bg={match ? 'primary.300' : 'transparent'}
					height='54px'
					mr='4'
					borderRightRadius='8px'
				></Box>
				<LinkOverlay
					py='4'
					as={Link}
					to={linkTo}
					_hover={{ textDecoration: 'none' }}
				>
					{children}
				</LinkOverlay>
			</Flex>
		</LinkBox>
	);
};
