import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { useDrag } from 'react-dnd';
import { SectionDragType } from '../constants/constants';
import { SectionType } from '../types/types';
import { DefaultComponent } from './DefaultComponent';
import { sectionComponents } from './sections/SectionComponents';

interface SectionDragItemProps {
	section: SectionType;
}

export const SectionDragItem: React.FC<SectionDragItemProps> = ({
	section,
}) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: SectionDragType,
		item: { section },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));
	return (
		<Box
			width='100%'
			ref={drag}
			opacity={isDragging ? 0.5 : 1}
			border='1px solid'
			borderColor='gray.100'
			p='2'
		>
			{sectionComponents[section.code] ? (
				sectionComponents[section.code]
			) : (
				<DefaultComponent />
			)}
			<Text textAlign='center'>{section.name}</Text>
		</Box>
	);
};
