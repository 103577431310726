import { Center, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { WidgetDragType } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setSelectedItemDetails } from '../redux/reducers/selectedItemSlice';
import {
	SubTemplateWidgetType,
	WidgetType,
	SubTemplateType,
} from '../types/types';
import { PushWidgetModal } from './PushWidgetModal';
import { WidgetCard } from './WidgetCard';
import _ from 'lodash';

interface WidgetGridProps {
	widgets: SubTemplateWidgetType[];
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

export const WidgetGrid: React.FC<WidgetGridProps> = ({
	widgets,
	subTemplateIdentifier,
}) => {
	const dispatch = useAppDispatch();
	const { inputDrawerComponent } = useAppSelector(
		(state) => state.editorDrawer
	);
	const [isActive, setIsActive] = useState(false);
	const [{ canDrop }, drop] = useDrop(
		() => ({
			accept: WidgetDragType,
			drop: (item: { widget: WidgetType }) => {
				if (!isActive) {
					return;
				}
				dispatch(
					setSelectedItemDetails({
						selectedItemType: 'WIDGET',
						selectedItem: item.widget,
					})
				);
				onOpen();
			},
			collect: (monitor) => ({
				canDrop: monitor.canDrop(),
			}),
		}),
		[isActive]
	);

	const { isOpen, onOpen, onClose } = useDisclosure();

	useEffect(() => {
		if (
			inputDrawerComponent?._id &&
			inputDrawerComponent?._id === subTemplateIdentifier.id
		) {
			return setIsActive(true);
		}
		if (
			(inputDrawerComponent as SubTemplateType)?.identifier &&
			(inputDrawerComponent as SubTemplateType)?.identifier ===
				subTemplateIdentifier.identifier
		) {
			return setIsActive(true);
		}
		return setIsActive(false);
	}, [inputDrawerComponent, subTemplateIdentifier]);
	return (
		<>
			<PushWidgetModal
				isOpen={isOpen}
				onClose={onClose}
				subTemplateIdentifier={subTemplateIdentifier}
			/>
			<VStack
				spacing={6}
				width='100%'
				minHeight='200px'
				ref={drop}
				opacity={canDrop && isActive ? '0.5' : '1'}
			>
				{_.orderBy(widgets, ['details.position'], ['asc']).map((wid, index) => {
					return (
						<WidgetCard
							widget={wid}
							key={`${wid._id || wid.details?.identifier || index}`}
							subTemplateIdentifier={subTemplateIdentifier}
						/>
					);
				})}
				{widgets.length === 0 && (
					<Center bg='white' width='100%' height='200px' fontWeight='medium'>
						There are no Widgets in this Sub-Template
					</Center>
				)}
			</VStack>
		</>
	);
};
