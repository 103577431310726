import { Box, Flex, Text } from '@chakra-ui/layout';
import { HStack, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setLayout } from '../../redux/reducers/layoutSlice';
import { setStores } from '../../redux/reducers/storeSlice';
import {
	addEditSubTemplateSectionToContent,
	clearContent,
} from '../../redux/reducers/subTemplateSlice';
import { ComponentDrawer } from '../editor/partials/ComponentDrawer';
import { SubTemplateList } from '../editor/partials/SubTemplateList';
import { SubTemplateInputDrawer } from './SubTemplateInputDrawer';

interface EditSubTemplateProps {}

export const EditSubTemplate: React.FC<EditSubTemplateProps> = () => {
	const dispatch = useAppDispatch();
	const toast = useToast();
	const { subTemplateId } = useParams();
	const { content } = useAppSelector((state) => state.subTemplate);
	const { stores } = useAppSelector((state) => state.store);

	useEffect(() => {
		return () => {
			dispatch(clearContent());
		};
	}, [dispatch]);

	useEffect(() => {
		dispatch(setLayout({ layout: 'editor', isOpenSidenav: false }));
		return () => {
			dispatch(setLayout({ layout: 'dashboard', isOpenSidenav: true }));
		};
	}, [dispatch]);

	useEffect(() => {
		const getData = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/${subTemplateId}`
				);
				dispatch(
					addEditSubTemplateSectionToContent({
						subTemplate: result.data.data,
					})
				);
			} catch (err: any) {
				toast({
					status: 'error',
					position: 'top',
					description: err?.response?.data?.message || 'An error occurred',
				});
			}
		};
		getData();
	}, [dispatch, subTemplateId, toast]);

	useEffect(() => {
		const getData = async () => {
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/store`,
				{
					params: {
						page: 1,
						limit: 30,
					},
				}
			);
			dispatch(setStores({ stores: result.data.data }));
		};
		if (stores.length === 0) {
			getData();
		}
	}, [dispatch, stores]);

	return (
		<>
			<Box p='2' pt='0' bg='gray.100' position='relative'>
				<Box
					bg='gray.100'
					h='2'
					w='100%'
					zIndex={10}
					position='sticky'
					top='0'
				></Box>
				<Flex
					mb='2'
					bg='white'
					position='sticky'
					top='8px'
					zIndex={10}
					py='2'
					px='4'
					alignItems='center'
					justifyContent='space-between'
				>
					<Text fontWeight='bold' fontSize='lg'>
						Editing Sub-Template {content.length > 0 && content[0].name}
					</Text>
				</Flex>
				<HStack spacing={2} height='100%' alignItems='start' maxWidth='100%'>
					<ComponentDrawer />
					<Box flexGrow={1} height='100%' rounded='md' overflowX='auto'>
						<Box bg='white' p='2'>
							<Box
								height='100%'
								p='6'
								border='1px solid'
								borderColor='gray.100'
							>
								<SubTemplateList />
							</Box>
						</Box>
					</Box>
					<SubTemplateInputDrawer />
				</HStack>
			</Box>
		</>
	);
};
