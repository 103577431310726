import { createSlice } from '@reduxjs/toolkit';

interface InitiatlState {
	isSidenavOpen: boolean;
	isPageLoading: boolean;
}

const initialState: InitiatlState = {
	isSidenavOpen: true,
	isPageLoading: false,
};

const layoutSlice = createSlice({
	name: 'layoutSlice',
	initialState,
	reducers: {
		openSidenav: (state) => {
			state.isSidenavOpen = true;
		},
		closeSidenav: (state) => {
			state.isSidenavOpen = false;
		},
		startPageLoading: (state) => {
			state.isPageLoading = true;
		},
		stopPageLoading: (state) => {
			state.isPageLoading = false;
		},
	},
});

export const { openSidenav, closeSidenav, startPageLoading, stopPageLoading } =
	layoutSlice.actions;
export const layoutReducer = layoutSlice.reducer;
