import { Center, Box, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface OfferBannerItemProps {}

export const OfferBannerItem: React.FC<OfferBannerItemProps> = () => {
	return (
		<Center
			bg='primary.100'
			w='100%'
			height='100px'
			rounded='md'
			justifyContent='space-evenly'
		>
			<Box w='50px' bg='gray.500' height='8px'></Box>
			<Icon as={FiImage} />
		</Center>
	);
};
