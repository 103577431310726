import {
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	InputGroup,
	InputRightElement,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { FiUpload, FiX } from 'react-icons/fi';

interface FileUploadProps {
	name: string;
	formik: any;
}

export const FileUpload: React.FC<FileUploadProps> = ({ name, formik }) => {
	const ref = useRef<HTMLInputElement>(null);
	let value = true;
	if (typeof formik.values.imageUrl === 'object' || formik.values.imageUrl) {
		value = false;
	}
	return (
		<FormControl
			id={name}
			isInvalid={formik.errors[name] ? true : false}
			mb='4'
			isRequired={name === 'imageUrlWeb' ? false : value}
		>
			<FormLabel textTransform='capitalize'>{name}</FormLabel>
			<input
				type='file'
				name={name}
				ref={ref}
				style={{ display: 'none' }}
				onChange={(e) => {
					if (e.target.files && e.target.files[0]) {
						const reader = new FileReader();
						reader.readAsDataURL(e.target.files[0]);
						reader.onloadend = () => {
							if (e.target.files && e.target.files[0]) {
								formik.setFieldValue(name, {
									fileName: e.target.files[0].name,
									mimeType: e.target.files[0].type,
									preview: reader.result,
								});
							}
						};
					}
				}}
				accept='image/png, image/jpeg, image/jpg'
			/>
			<Flex>
				<InputGroup mr='1'>
					<Input
						placeholder='Upload'
						onClick={() => ref.current?.click()}
						cursor='pointer'
						value={
							typeof formik.values[name] === 'object'
								? formik.values[name]
									? formik.values[name].fileName
									: ''
								: formik.values[name]
						}
						css={{ caretColor: 'transparent' }}
						onChange={() => 0}
						autoComplete='off'
					/>
					<InputRightElement pointerEvents='none' children={<FiUpload />} />
				</InputGroup>
				{name === 'imageUrlWeb' && (
					<IconButton
						aria-label='remove file'
						icon={<FiX />}
						onClick={() => formik.setFieldValue(name, '')}
					/>
				)}
			</Flex>

			<FormErrorMessage>{formik.errors[name]}</FormErrorMessage>
		</FormControl>
	);
};
