import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	ModalFooter,
	Button,
	Select,
	useToast,
	Alert,
	AlertDescription,
	AlertIcon,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { addPage } from '../../../redux/reducers/currentPageSlice';
import { PageType } from '../../../types/types';

interface EditPageModalProps {
	isOpen: boolean;
	onClose: () => void;
	updateFunction: (p: PageType) => void;
}

export const EditPageModal: React.FC<EditPageModalProps> = ({
	isOpen,
	onClose,
	updateFunction,
}) => {
	const toast = useToast();
	const { currentPage } = useAppSelector((state) => state.currentPage);
	const { templateId } = useParams();
	const dispatch = useAppDispatch();
	const formik = useFormik({
		initialValues: {
			_id: currentPage?._id || '',
			name: currentPage?.name || '',
			pageType: currentPage?.pageType || '',
			title: currentPage?.title || '',
			content: currentPage?.content || '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setStatus }) => {
			try {
				const result = await axios.put(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`,
					{ ...values, templateId }
				);
				toast({
					position: 'top',
					status: 'success',
					description: 'Page details updated',
				});
				closeFunction();
				updateFunction(result.data.data);
				dispatch(addPage({ page: result.data.data }));
				formik.resetForm();
			} catch (err: any) {
				setStatus(err.response.data.message || 'An error occurred');
			}
		},
	});

	const closeFunction = () => {
		formik.resetForm();
		formik.setStatus('');
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={closeFunction}>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit page</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='2'>
								<AlertIcon />
								<AlertDescription>{formik.status}</AlertDescription>
							</Alert>
						)}
						<FormControl
							id='name'
							isInvalid={formik.errors.name ? true : false}
							mb='4'
							isRequired
						>
							<FormLabel>Name</FormLabel>
							<Input type='text' {...formik.getFieldProps('name')} />
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl
							id='title'
							isInvalid={formik.errors.title ? true : false}
							mb='4'
							isRequired
						>
							<FormLabel>Title</FormLabel>
							<Input type='text' {...formik.getFieldProps('title')} />
							<FormErrorMessage>{formik.errors.title}</FormErrorMessage>
						</FormControl>
						<FormControl
							id='pageType'
							isInvalid={formik.errors.pageType ? true : false}
							mb='4'
							isRequired
						>
							<FormLabel>Type</FormLabel>
							<Select
								value={formik.values.pageType}
								onChange={(e) =>
									formik.setFieldValue('pageType', e.target.value)
								}
							>
								<option value='HOME'>Home</option>
								<option value='DEALS'>Deals</option>
								<option value='OTHER'>Other</option>
							</Select>
							<FormErrorMessage>{formik.errors.pageType}</FormErrorMessage>
						</FormControl>
					</ModalBody>

					<ModalFooter justifyContent='center'>
						<Button
							colorScheme='green'
							type='submit'
							mr={3}
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
						<Button colorScheme='gray' onClick={closeFunction}>
							Cancel
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
