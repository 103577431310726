import {
	ModalBody,
	Alert,
	AlertIcon,
	AlertDescription,
	FormControl,
	FormLabel,
	Input,
	FormErrorMessage,
	ModalFooter,
	Button,
	Text,
	useToast,
	Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface AddTemplateStepTwoProps {
	templateId: string;
	closeFunction: () => void;
}

export const AddTemplateStepTwo: React.FC<AddTemplateStepTwoProps> = ({
	templateId,
	closeFunction,
}) => {
	const navigate = useNavigate();
	const toast = useToast();

	const formik = useFormik({
		initialValues: {
			templateId,
			name: '',
			pageType: 'OTHER',
			title: '',
		},
		enableReinitialize: true,
		onSubmit: async (values, { setStatus }) => {
			try {
				const pageResult = await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`,
					{
						...values,
						content: [],
					}
				);

				toast({
					description: 'Page created',
					status: 'success',
				});

				navigate(`/editor/${templateId}/${pageResult.data.data._id}`);
			} catch (err: any) {
				setStatus(err?.response?.data?.message || 'An error occurred');
			}
		},
	});

	const onClose = () => {
		formik.resetForm();
		closeFunction();
	};

	return (
		<form onSubmit={formik.handleSubmit}>
			<ModalBody>
				<Text color='gray.500' fontWeight='medium' mb='2'>
					Page Details
				</Text>
				{formik.status && (
					<Alert status='error' mb='2'>
						<AlertIcon />
						<AlertDescription>{formik.status}</AlertDescription>
					</Alert>
				)}
				<FormControl
					id='name'
					isInvalid={formik.errors.name ? true : false}
					mb='2'
					isRequired
				>
					<FormLabel>Name</FormLabel>
					<Input type='text' {...formik.getFieldProps('name')} />
					<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
				</FormControl>
				<FormControl
					id='pageType'
					isInvalid={formik.errors.pageType ? true : false}
					mb='4'
					isRequired
				>
					<FormLabel>Type</FormLabel>
					<Select
						value={formik.values.pageType}
						onChange={(e: any) =>
							formik.setFieldValue('pageType', e.target.value)
						}
					>
						<option value='HOME'>Home</option>
						<option value='DEALS'>Deals</option>
						<option value='OTHER'>Other</option>
					</Select>
					<FormErrorMessage>{formik.errors.pageType}</FormErrorMessage>
				</FormControl>
				<FormControl
					id='title'
					isInvalid={formik.errors.title ? true : false}
					mb='2'
					isRequired
				>
					<FormLabel>Title</FormLabel>
					<Input type='text' {...formik.getFieldProps('title')} />
					<FormErrorMessage>{formik.errors.title}</FormErrorMessage>
				</FormControl>
			</ModalBody>

			<ModalFooter justifyContent='center'>
				<Button
					colorScheme='green'
					mr={3}
					type='submit'
					isLoading={formik.isSubmitting}
				>
					Submit
				</Button>
				<Button colorScheme='gray' onClick={onClose}>
					Cancel
				</Button>
			</ModalFooter>
		</form>
	);
};
