import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { useDrag } from 'react-dnd';
import { WidgetDragType } from '../constants/constants';
import { useAppSelector } from '../redux/hooks';
import { SubTemplateType, WidgetType } from '../types/types';
import { DefaultComponent } from './DefaultComponent';
import { WidgetComponents } from './widgets/WidgetComponents';

interface WidgetDragItemProps {
	widget: WidgetType;
}

export const WidgetDragItem: React.FC<WidgetDragItemProps> = ({ widget }) => {
	const { inputDrawerComponent } = useAppSelector(
		(state) => state.editorDrawer
	);
	const [{ isDragging }, drag] = useDrag(() => ({
		type: WidgetDragType,
		item: { widget },
		collect: (monitor) => ({
			isDragging: monitor.isDragging(),
		}),
	}));

	const bringToScreen = () => {
		const doc = document.getElementById(
			inputDrawerComponent?._id ||
				(inputDrawerComponent as SubTemplateType).identifier ||
				''
		);
		if (!doc) {
			return;
		}
		var rect = doc.getBoundingClientRect();
		var viewHeight = Math.max(
			document.documentElement.clientHeight,
			window.innerHeight
		);
		if (!!(rect.bottom < 100 || rect.top - viewHeight >= 100)) {
			doc.scrollIntoView();
		}
	};

	return (
		<Box
			width='100%'
			ref={drag}
			opacity={isDragging ? 0.5 : 1}
			onDragStart={bringToScreen}
		>
			{WidgetComponents[widget.code] ? (
				WidgetComponents[widget.code]
			) : (
				<DefaultComponent />
			)}
			<Text textAlign='center'>{widget.name || widget.code}</Text>
		</Box>
	);
};
