import { Box, Text, VStack, Center } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { SmallLoader } from '../../../components/SmallLoader';
import { WidgetDragItem } from '../../../components/WidgetDragItem';
import { useAppSelector } from '../../../redux/hooks';
import { SubTemplateType, WidgetType } from '../../../types/types';

interface WidgetListingProps {}

export const WidgetListing: React.FC<WidgetListingProps> = () => {
	const [loading, setLoading] = useState(false);
	const [widgets, setWidgets] = useState<WidgetType[]>([]);
	const { inputDrawerComponent, inputDrawerComponentType } = useAppSelector(
		(state) => state.editorDrawer
	);

	useEffect(() => {
		const getData = async () => {
			if (!inputDrawerComponent || inputDrawerComponentType !== 'SECTION') {
				return;
			}
			setLoading(true);
			const sec = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/section/details/${
					(inputDrawerComponent as SubTemplateType).sectionId
				}`
			);
			setLoading(false);
			setWidgets(sec.data.data.widgets);
		};
		getData();
	}, [inputDrawerComponent, inputDrawerComponentType]);

	return (
		<>
			{loading ? (
				<Box mt='10'>
					<SmallLoader />
				</Box>
			) : widgets && widgets.length > 0 ? (
				<VStack spacing='6'>
					{widgets.map((widget) => {
						return <WidgetDragItem widget={widget} key={widget._id} />;
					})}
				</VStack>
			) : (
				<Center height='300px'>
					<Text textAlign='center' fontWeight='medium'>
						This section uses no widgets
					</Text>
				</Center>
			)}
		</>
	);
};
