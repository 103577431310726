import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import { DataTable } from '../../../components/DataTable';
import { ColumnType } from '../../../types';

interface OrderListProps {}

export const OrderList: React.FC<OrderListProps> = () => {
	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/order`, []);

	const columns: ColumnType[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Created At',
				accessor: 'createdAt',
				isDateField: true,
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Increment ID',
				accessor: 'increment_id',
			},
			{
				Header: 'Order ID',
				accessor: 'morder_id',
			},
			{
				Header: 'Customer Name',
				accessor: 'customer_name',
			},
			{
				Header: 'Customer Mobile',
				accessor: 'customer_mobile',
			},
			{
				Header: 'Order Value',
				accessor: 'order_value',
			},
			{
				Header: 'Payment Type',
				accessor: 'payment_type',
			},
			{
				Header: 'Zone ID',
				accessor: 'zone.id',
			},
			{
				Header: 'Delivery Time',
				accessor: 'delivery_date',
				isDateField: true,
				filter: {
					type: 'date',
				},
				CSVFormat: (val) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
		],
		[]
	);

	return (
		<DataTable
			columns={columns}
			url={url}
			pageHeader='Order List'
			exportCSV={true}
		/>
	);
};
