import {
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

interface ConfirmPublishModalProps {
	isOpen: boolean;
	onClose: () => void;
	submitFunction: () => void;
}

export const ConfirmPublishModal: React.FC<ConfirmPublishModalProps> = ({
	isOpen,
	onClose,
	submitFunction,
}) => {
	const cancelRef = useRef(null);

	const closeFunction = () => {
		submitFunction();
		onClose();
	};

	return (
		<AlertDialog
			isOpen={isOpen}
			leastDestructiveRef={cancelRef}
			onClose={onClose}
		>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Publish Template
					</AlertDialogHeader>

					<AlertDialogBody>
						Publishing a template will reflect its changes to associated stores.
						Are you sure you want to publish?
					</AlertDialogBody>

					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onClose}>
							Cancel
						</Button>
						<Button colorScheme='primary' onClick={closeFunction} ml={3}>
							Confirm
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	);
};
