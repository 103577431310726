import { Box, Button, Collapse, Icon, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../redux/hooks';

interface SideNavDropdownProps {
	linkTo: string;
	label: string;
}

export const SideNavDropdown: React.FC<SideNavDropdownProps> = ({
	linkTo,
	label,
	children,
}) => {
	const [isOpen, setIsOpen] = useState(true);
	const location = useLocation();
	const isMatch = location.pathname.split('/')[1] === linkTo;
	const { isSidenavOpen } = useAppSelector((state) => state.layout);

	return (
		<Box w='full'>
			<Button
				onClick={() => setIsOpen((open) => !open)}
				bg={isMatch || isOpen ? 'primary.100' : 'white'}
				_hover={{ bg: 'primary.100' }}
				_focus={{ outline: 'none' }}
				_active={{ bg: 'primary.100' }}
				isFullWidth
				rounded='none'
				justifyContent='space-between'
			>
				{isSidenavOpen && <Text ml='3'>{label}</Text>}
				<Icon as={isOpen ? FiChevronDown : FiChevronRight} />
			</Button>
			<Collapse in={isOpen}>
				<Box mt='2'>{children}</Box>
			</Collapse>
		</Box>
	);
};
