export const chakraSelect = {
	minHeight: '40px',
	borderRadius: '0.375rem',
	borderColor: '#E2E8F0',
	':hover': {
		borderColor: '#CBD5E0',
	},
	':focus-within': {
		borderColor: '#3182CE',
		boxShadow: '0 0 0 1px #3182CE',
	},
};
