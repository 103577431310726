import { Box, useDisclosure } from '@chakra-ui/react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { removeWidgetFromContent } from '../redux/reducers/subTemplateSlice';
import { SubTemplateType, SubTemplateWidgetType } from '../types/types';
import { DeleteWidgetWarningModal } from './DeleteWidgetWarningModal';
import { WidgetReorderElement } from './WidgetReorderElement';

interface WidgetReorderElementContainerProps {}

export const WidgetReorderElementContainer: React.FC<
	WidgetReorderElementContainerProps
> = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { inputDrawerComponent } = useAppSelector(
		(state) => state.editorDrawer
	);
	const { content } = useAppSelector((state) => state.subTemplate);
	const dispatch = useAppDispatch();
	const location = useLocation();
	const [currentWidget, setCurrentWidget] = useState<
		SubTemplateWidgetType | undefined
	>();
	let subTemplateIdentifier = inputDrawerComponent?._id
		? { id: inputDrawerComponent?._id }
		: {
				identifier: (inputDrawerComponent as SubTemplateType)?.identifier,
		  };

	const findWithEqualId = () => {
		let temp: SubTemplateWidgetType[] = [];

		content.forEach((sub) => {
			if (inputDrawerComponent?._id && inputDrawerComponent._id === sub._id) {
				temp = sub.widget;
			}
			if (
				(inputDrawerComponent as SubTemplateType)?.identifier &&
				(inputDrawerComponent as SubTemplateType)?.identifier === sub.identifier
			) {
				temp = sub.widget;
			}
		});

		return temp;
	};

	const triggerDelete = (w: SubTemplateWidgetType) => {
		setCurrentWidget(w);
		onOpen();
	};

	return (
		<>
			<DeleteWidgetWarningModal
				endFunction={() => {
					dispatch(
						removeWidgetFromContent({
							identifier: currentWidget?.details?.identifier!,
							position: currentWidget?.details?.position!,
							subTemplateIdentifier,
						})
					);
				}}
				isOpen={isOpen}
				onClose={onClose}
			/>
			<Box>
				{location.pathname.includes('sub-templates')
					? content.length > 0 &&
					  content[0].widget.map((widget) => {
							return (
								<WidgetReorderElement
									widget={widget}
									key={widget._id || widget.details?.identifier}
									subTemplateIdentifier={subTemplateIdentifier}
									deleteFunction={triggerDelete}
								/>
							);
					  })
					: _.orderBy(findWithEqualId(), ['details.position'], ['asc']).map(
							(widget) => {
								return (
									<WidgetReorderElement
										widget={widget}
										key={widget._id || widget.details?.identifier}
										subTemplateIdentifier={subTemplateIdentifier}
										deleteFunction={triggerDelete}
									/>
								);
							}
					  )}
			</Box>
		</>
	);
};
