import {
	useToast,
	AlertDialog,
	AlertDialogOverlay,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogCloseButton,
	AlertDialogBody,
	AlertDialogFooter,
	Button,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useRef, useState } from 'react';

interface DeleteCityConfirmModalProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: any;
	refetchData: () => void;
}

export const DeleteCityConfirmModal: React.FC<DeleteCityConfirmModalProps> = ({
	url,
	isOpen,
	onClose,
	selectedItem,
	refetchData,
}) => {
	const toast = useToast();

	const [loading, setLoading] = useState(false);

	const deleteRequest = async () => {
		setLoading(true);
		try {
			await axios.delete(`${url}/${selectedItem.id}`);
			toast({
				description: 'Item deleted successfully',
				status: 'success',
			});
			setLoading(false);
			refetchData();
			onClose();
		} catch (err: any) {
			if (err.response.status !== 401 && err.response.status !== 420) {
				toast({
					description: err.response.data.message || 'An error occurred',
					status: 'error',
				});
				setLoading(false);
				onClose();
			}
		}
	};

	const cancelRef = useRef(null);

	return (
		<AlertDialog
			motionPreset='slideInBottom'
			onClose={onClose}
			isOpen={isOpen}
			isCentered
			leastDestructiveRef={cancelRef}
		>
			<AlertDialogOverlay />

			<AlertDialogContent>
				<AlertDialogHeader>Delete City</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					Are you sure you want to delete the City "{selectedItem?.name}"?
				</AlertDialogBody>
				<AlertDialogFooter alignItems='center'>
					<Button
						colorScheme='green'
						mr={3}
						onClick={deleteRequest}
						isLoading={loading}
					>
						Delete
					</Button>
					<Button
						onClick={onClose}
						ref={cancelRef}
						variant='outline'
						colorScheme='red'
					>
						Cancel
					</Button>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	);
};
