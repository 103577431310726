import { useDisclosure } from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import React, { useMemo, useState } from 'react';
import { FiEdit, FiLock } from 'react-icons/fi';
import { getUmsPerms } from '../../../utils/getUmsPerms';
import { DataTable } from '../../components/DataTable';
import { AddUserModalForm } from './partials/AddUserModalForm';
import { ChangePasswordModalForm } from './partials/ChangePasswordModalForm';
import { EditUserModalForm } from './partials/EditUserModalForm';

interface UsersProps {}

export const Users: React.FC<UsersProps> = () => {
	const [refetchFlag, setRefetchFlag] = useState(false);
	const [selectedItem, setSelectedItem] = useState();
	const { hasPasswordPerm } = getUmsPerms();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: changePasswordModal,
		onOpen: openChangePasswordModal,
		onClose: closeChangePasswordModal,
	} = useDisclosure();

	const url = useMemo(() => `${process.env.REACT_APP_UMS_BASE_URL}/admin`, []);

	const columns = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Name',
				accessor: 'name',
				width: '150px',
			},
			{
				Header: 'Email',
				accessor: 'email',
				width: '200px',
			},
			{
				Header: 'Mobile',
				accessor: 'mobile',
				width: '120px',
			},
			{
				Header: 'Created At',
				accessor: 'createdAt',
				width: '120px',
				isDateField: true,
				CSVFormat: (val: string) => {
					return format(parseISO(val), 'dd-MM-yyyy');
				},
			},
			{
				Header: 'Updated At',
				accessor: 'updatedAt',
				width: '120px',
				isDateField: true,
				isPrintable: false,
			},
		],
		[]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openEditModal();
				},
			},
			{
				name: 'change_password',
				tooltip: 'Change Password',
				icon: <FiLock />,
				color: 'orange',
				onTrigger: (item: any) => {
					setSelectedItem(item);
					openChangePasswordModal();
				},
				show: !!hasPasswordPerm,
			},
		],
		[openEditModal, openChangePasswordModal, hasPasswordPerm]
	);

	return (
		<>
			<AddUserModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			<EditUserModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<ChangePasswordModalForm
				isOpen={changePasswordModal}
				onClose={closeChangePasswordModal}
				url={url}
				selectedItem={selectedItem}
			/>
			<DataTable
				url={url}
				columns={columns}
				pageHeader='List of Admin Users'
				actions={actions}
				addNewFunction={openAddModal}
				refetchFlag={refetchFlag}
			/>
		</>
	);
};
