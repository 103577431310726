import { useDisclosure, useToast } from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import { DataTable } from '../../../components/DataTable';
import { ColumnType } from '../../../types';
import { AddZoneModalForm } from './partials/AddZoneModalForm';
import { DeleteZoneConfirmModal } from './partials/DeleteZoneConfirmModal';
import { EditZoneModalForm } from './partials/EditZoneModalForm';

interface ZoneListProps {}

interface SiteFilter {
	label: string;
	value: string | number;
}

export const ZoneList: React.FC<ZoneListProps> = () => {
	const [selectedItem, setSelectedItem] = useState();
	const [refetchFlag, setRefetchFlag] = useState(false);
	const [siteFilters, setSiteFilters] = useState<SiteFilter[]>([]);

	const url = useMemo(() => `${process.env.REACT_APP_ZMS_BASE_URL}/zone`, []);

	const {
		isOpen: editModal,
		onOpen: openEditModal,
		onClose: closeEditModal,
	} = useDisclosure();

	const {
		isOpen: deleteModal,
		onOpen: openDeleteModal,
		onClose: closeDeleteModal,
	} = useDisclosure();

	const {
		isOpen: addModal,
		onOpen: openAddModal,
		onClose: closeAddModal,
	} = useDisclosure();

	const columns: ColumnType[] = useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
				width: '50px',
			},
			{
				Header: 'Zone',
				accessor: 'name',
			},
			{
				Header: 'Minimum Order Value',
				accessor: 'zone_order.min_order_value',
			},
			{
				Header: 'Shipping Charges',
				accessor: 'zone_order.shipping_charges',
			},
			{
				Header: 'Express Delivery Charges',
				accessor: 'zone_order.express_delivery_charge',
			},
			{
				Header: 'Free Shipping Order Value',
				accessor: 'zone_order.free_ship_value',
			},
			{
				Header: 'Store',
				accessor: 'site_id',
				custom: (v: any) => {
					let temp: any = _.find(siteFilters, (x: any) => {
						if (x.value === v) {
							return true;
						}
					});
					return temp.label;
				},
				CSVFormat: (v: any) => {
					let temp: any = _.find(siteFilters, (x: any) => {
						if (x.value === v) {
							return true;
						}
					});
					return temp.label;
				},
				filter: {
					type: 'select',
					options: siteFilters,
				},
			},
		],
		[siteFilters]
	);

	const toast = useToast();

	const actions = useMemo(
		() => [
			{
				name: 'edit',
				tooltip: 'Edit',
				icon: <FiEdit />,
				color: 'green',
				onTrigger: (item: any) => {
					let temp: string = siteFilters.filter(
						(v) => v.value === item.site_id
					)[0].label;
					setSelectedItem({ ...item, website_name: temp });
					openEditModal();
				},
			},
			{
				name: 'delete',
				tooltip: 'Delete',
				icon: <FiTrash2 />,
				color: 'red',
				onTrigger: (item: any) => {
					let temp: string = siteFilters.filter(
						(v) => v.value === item.site_id
					)[0].label;
					setSelectedItem({ ...item, website_name: temp });
					openDeleteModal();
				},
			},
		],
		[openEditModal, openDeleteModal, siteFilters]
	);

	const refetchData = () => {
		setRefetchFlag(!refetchFlag);
	};

	useEffect(() => {
		axios
			.get(`${process.env.REACT_APP_ZMS_BASE_URL}/sites`)
			.then((res) => {
				let temp: any = [];
				res.data.data.forEach((val: any) => {
					temp.push({
						label: val.website_name,
						value: val.id,
					});
				});
				setSiteFilters(temp);
			})
			.catch((err) => {
				if (err.response.status !== 401 && err.response.status !== 420) {
					toast({
						description: err.response.data.message || 'An error occurred',
						status: 'error',
					});
				}
			});
	}, [toast]);

	return (
		<>
			<EditZoneModalForm
				isOpen={editModal}
				onClose={closeEditModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<DeleteZoneConfirmModal
				isOpen={deleteModal}
				onClose={closeDeleteModal}
				url={url}
				selectedItem={selectedItem}
				refetchData={refetchData}
			/>
			<AddZoneModalForm
				isOpen={addModal}
				onClose={closeAddModal}
				url={url}
				refetchData={refetchData}
			/>
			{siteFilters && (
				<DataTable
					columns={columns}
					actions={actions}
					url={url}
					exportCSV={true}
					addNewFunction={openAddModal}
					pageHeader='Zone List'
					refetchFlag={refetchFlag}
				/>
			)}
		</>
	);
};
