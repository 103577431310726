import {
	useToast,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Alert,
	AlertIcon,
	Grid,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Input,
	ModalFooter,
	Button,
	Select,
	Flex,
	Box,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

interface EditSlotModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: any;
	refetchData: () => void;
	t_id: string;
}

export const EditSlotModalForm: React.FC<EditSlotModalFormProps> = ({
	url,
	isOpen,
	onClose,
	selectedItem,
	refetchData,
	t_id,
}) => {
	const initialValues = {
		t_id,
		slot_type: selectedItem ? selectedItem.slot_type : '',
		start_hours: selectedItem ? selectedItem.start_time.split(':')[0] : '',
		start_minutes: selectedItem ? selectedItem.start_time.split(':')[1] : '00',
		cutoff_hours: selectedItem ? selectedItem.cutoff_time.split(':')[0] : '',
		cutoff_minutes: selectedItem
			? selectedItem.cutoff_time.split(':')[1]
			: '00',
		delivery_from_time: selectedItem ? selectedItem.delivery_from_time : '',
		delivery_to_time: selectedItem ? selectedItem.delivery_to_time : '',
		hand_off_time: selectedItem ? selectedItem.hand_off_time : '',
		dispatch_time: selectedItem ? selectedItem.dispatch_time : '',
		pick_pack_allocation: selectedItem ? selectedItem.pick_pack_allocation : '',
		max_orders: selectedItem ? selectedItem.max_orders : '',
	};

	const validationSchema = Yup.object().shape({
		slot_type: Yup.string().required('Slot type is required'),
		start_hours: Yup.string().required('Start Time is required'),
		start_minutes: Yup.string().required('Start Time is required'),
		cutoff_hours: Yup.string().required('Start Time is required'),
		cutoff_minutes: Yup.string().required('Start Time is required'),
		delivery_from_time: Yup.string().required('Delivery From is required'),
		delivery_to_time: Yup.string().required('Delivery To is required'),
		hand_off_time: Yup.string().required('Hand Off Time is required'),
		dispatch_time: Yup.string().required('Dispatch Time is required'),
		pick_pack_allocation: Yup.number().required(
			'Pick Pack Allocation is required'
		),
		max_orders: Yup.number().required('Max Orders is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnChange: false,
		onSubmit: async (values, { setStatus }) => {
			let {
				start_hours,
				start_minutes,
				cutoff_hours,
				cutoff_minutes,
				...payload
			} = values;
			let finalPayload = {
				...payload,
				start_time: start_hours + ':' + start_minutes,
				cutoff_time: cutoff_hours + ':' + cutoff_minutes,
			};
			try {
				await axios.put(`${url}/${selectedItem.id}`, finalPayload);
				toast({
					description: 'Item updated successfully',
					status: 'success',
				});
				resetAndCloseAddModal();
				refetchData();
			} catch (err: any) {
				if (err.response.status !== 401 && err.response.status !== 420) {
					setStatus(err.response.data.message || 'An error occurred');
				}
			}
		},
	});

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		onClose();
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='2xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit Slot</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<Grid templateColumns='repeat(2, 1fr)' gap={4}>
							<FormControl isInvalid={!!formik.errors.slot_type} mb='4'>
								<FormLabel>Slot Type</FormLabel>
								<Select
									placeholder='Select an item'
									{...formik.getFieldProps('slot_type')}
								>
									<option value='scheduled'>Scheduled</option>
									<option value='express'>Express</option>
								</Select>
								<FormErrorMessage>{formik.errors.slot_type}</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!formik.errors.start_hours || !!formik.errors.start_minutes
								}
								mb='4'
							>
								<FormLabel>Start Before (HH:MM)</FormLabel>
								<Flex alignItems='center'>
									<Select
										{...formik.getFieldProps('start_hours')}
										placeholder='Select hours'
									>
										{[...Array(48)].map((v, i) => {
											return (
												<option
													value={i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
													key={`start_hours_${i}`}
												>
													{i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
												</option>
											);
										})}
									</Select>
									<Box as='span' px='4'>
										:
									</Box>
									<Select {...formik.getFieldProps('start_minutes')}>
										{['00', '15', '30', '45'].map((v, i) => {
											return (
												<option value={v} key={`cutoff_minutes_${i}`}>
													{v}
												</option>
											);
										})}
									</Select>
								</Flex>
								<FormErrorMessage>
									{formik.errors.start_hours}
									{formik.errors.start_minutes}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={
									!!formik.errors.cutoff_hours || !!formik.errors.cutoff_minutes
								}
								mb='4'
							>
								<FormLabel>Cut Off Before (HH:MM)</FormLabel>
								<Flex alignItems='center'>
									<Select
										{...formik.getFieldProps('cutoff_hours')}
										placeholder='Select hours'
									>
										{[...Array(48)].map((a, i) => {
											return (
												<option
													value={i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
													key={`cutoff_hours_${i}`}
												>
													{i + 1 < 10 ? `0${i + 1}` : `${i + 1}`}
												</option>
											);
										})}
									</Select>
									<Box as='span' px='4'>
										:
									</Box>
									<Select {...formik.getFieldProps('cutoff_minutes')}>
										{['00', '15', '30', '45'].map((v, i) => {
											return (
												<option value={v} key={`cutoff_minutes_${i}`}>
													{v}
												</option>
											);
										})}
									</Select>
								</Flex>
								<FormErrorMessage>
									{formik.errors.cutoff_hours}
									{formik.errors.cutoff_minutes}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={!!formik.errors.delivery_from_time}
								mb='4'
							>
								<FormLabel>Delivery From</FormLabel>
								<Input
									{...formik.getFieldProps('delivery_from_time')}
									type='time'
								/>
								<FormErrorMessage>
									{formik.errors.delivery_from_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.delivery_to_time} mb='4'>
								<FormLabel>Delivery To</FormLabel>
								<Input
									{...formik.getFieldProps('delivery_to_time')}
									type='time'
								/>
								<FormErrorMessage>
									{formik.errors.delivery_to_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.hand_off_time} mb='4'>
								<FormLabel>Hand Off Time</FormLabel>
								<Input {...formik.getFieldProps('hand_off_time')} type='time' />
								<FormErrorMessage>
									{formik.errors.hand_off_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.dispatch_time} mb='4'>
								<FormLabel>Dispatch Time</FormLabel>
								<Input {...formik.getFieldProps('dispatch_time')} type='time' />
								<FormErrorMessage>
									{formik.errors.dispatch_time}
								</FormErrorMessage>
							</FormControl>
							<FormControl
								isInvalid={!!formik.errors.pick_pack_allocation}
								mb='4'
							>
								<FormLabel>Picker Packer Allocation Interval</FormLabel>
								<Input
									{...formik.getFieldProps('pick_pack_allocation')}
									type='number`'
								/>
								<FormErrorMessage>
									{formik.errors.pick_pack_allocation}
								</FormErrorMessage>
							</FormControl>
							<FormControl isInvalid={!!formik.errors.max_orders} mb='4'>
								<FormLabel>Max Orders</FormLabel>
								<Input {...formik.getFieldProps('max_orders')} type='number' />
								<FormErrorMessage>{formik.errors.max_orders}</FormErrorMessage>
							</FormControl>
						</Grid>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal}>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
				{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
			</ModalContent>
		</Modal>
	);
};
