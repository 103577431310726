import { Button } from '@chakra-ui/button';
import {
	Box,
	Center,
	Divider,
	Flex,
	Icon,
	Input,
	InputGroup,
	InputLeftElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Radio,
	RadioGroup,
	Text,
	useToast,
	VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Pagination } from '../../../components/Pagination';
import { SmallLoader } from '../../../components/SmallLoader';
import { customScrollCss } from '../../../constants/constants';
import { FitlersType, TemplateListType } from '../../../types/types';

interface ChangeTemplateModalProps {
	storeId: string;
	storeName: string;
	isOpen: boolean;
	onClose: () => void;
	currentTemplate?: string;
	refetchFunction: () => void;
}

export const ChangeTemplateModal: React.FC<ChangeTemplateModalProps> = ({
	storeId,
	isOpen,
	onClose,
	storeName,
	currentTemplate,
	refetchFunction,
}) => {
	const [templateList, setTemplateList] = useState<TemplateListType[]>([]);
	const [selectedTemplate, setSelectedTemplate] = useState('');
	const [keywordInput, setKeywordInput] = useState('');
	const [fetching, setFetching] = useState(false);
	const [loading, setLoading] = useState(false);
	const toast = useToast();

	const [totalItems, setTotalItems] = useState(0);

	const [filters, setFilters] = useState<FitlersType>({
		pagination: {
			page: 1,
			limit: 5,
		},
		sort: {
			siteName: 'ASC',
		},
		filter: {
			keyword: '',
		},
	});

	useEffect(() => {
		const getData = async () => {
			setFetching(true);
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template/publish`,
					{
						params: {
							...filters,
						},
					}
				);

				setTemplateList(result.data.data);
				setTotalItems(result.data.total);
			} catch (err: any) {
				toast({
					position: 'top',
					status: 'error',
					description: err?.response?.data?.message || 'An error occurred',
				});
			}
			setFetching(false);
		};
		getData();
	}, [toast, filters]);

	const setPage = (pg: number) => {
		setFilters({
			...filters,
			pagination: { ...filters.pagination, page: pg },
		});
	};

	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
		const delayDebounceUpdateSearch = setTimeout(() => {
			setFilters((f) => ({
				...f,
				filter: { ...f.filter, keyword: keywordInput },
				pagination: { ...f.pagination, page: 1 },
			}));
		}, 500);

		return () => {
			clearTimeout(delayDebounceUpdateSearch);
		};
	}, [keywordInput]);

	const addSelectedSubTemplate = async () => {
		setLoading(true);
		try {
			await axios.put(`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/store`, {
				_id: storeId,
				templateId: selectedTemplate,
			});
			toast({
				position: 'top',
				status: 'success',
				description: `Template updated for store ${storeName}`,
			});
			refetchFunction();
		} catch (err: any) {
			toast({
				position: 'top',
				status: 'error',
				description: err?.response?.data?.message || 'An Error Occurred',
			});
		}
		setLoading(false);
		closeFunction();
	};

	const closeFunction = () => {
		setSelectedTemplate('');
		onClose();
	};

	useEffect(() => {
		if (currentTemplate) {
			setSelectedTemplate(currentTemplate);
		}
	}, [currentTemplate, storeId]);

	return (
		<>
			<Modal
				isOpen={isOpen}
				onClose={closeFunction}
				scrollBehavior='inside'
				size='3xl'
			>
				<ModalOverlay />
				<ModalContent css={customScrollCss}>
					<ModalHeader pr='14'>
						Changing template for store "{storeName}"
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<InputGroup mb='2'>
							<InputLeftElement
								pointerEvents='none'
								children={<Icon as={FiSearch} color='gray.500' />}
							/>
							<Input
								placeholder='Search for a template'
								value={keywordInput}
								onChange={(e) => setKeywordInput(e.target.value)}
							/>
						</InputGroup>
						<Divider mb='2' />
						<Box mb='4'>
							{fetching ? (
								<Center height='300px'>
									<SmallLoader />
								</Center>
							) : templateList.length > 0 ? (
								<>
									<RadioGroup
										value={selectedTemplate}
										onChange={setSelectedTemplate}
									>
										<VStack spacing={2} align='stretch' mb='4'>
											{templateList.map((temp) => {
												return (
													<Flex
														key={temp._id}
														border='1px solid'
														borderColor={
															selectedTemplate === temp._id
																? 'green.200'
																: 'gray.200'
														}
														bg={
															selectedTemplate === temp._id
																? 'green.50'
																: 'white'
														}
														py='2'
														px='3'
														rounded='md'
														alignItems='center'
														justifyContent='space-between'
														cursor='pointer'
														boxShadow='0px 1px 6px rgba(0, 0, 0, 0.09)'
														onClick={() => setSelectedTemplate(temp._id)}
													>
														<Text color='gray.600' fontSize='lg'>
															{temp.name}
														</Text>
														<Radio value={temp._id} colorScheme='green' />
													</Flex>
												);
											})}
										</VStack>
									</RadioGroup>
									<Pagination
										limit={filters.pagination.limit}
										page={filters.pagination.page}
										setPage={setPage}
										totalItems={totalItems}
										setLimit={(limit: number) =>
											setFilters({
												...filters,
												pagination: {
													...filters.pagination,
													limit: limit,
												},
											})
										}
										alterLimit={false}
									/>
								</>
							) : (
								<Center height='300px'>
									<Text fontWeight='bold' fontSize='xl'>
										No Data
									</Text>
								</Center>
							)}
						</Box>
					</ModalBody>
					<ModalFooter justifyContent='center'>
						<Button
							colorScheme='green'
							onClick={addSelectedSubTemplate}
							mr={3}
							isLoading={loading}
						>
							Select
						</Button>
						<Button colorScheme='gray' onClick={closeFunction}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};
