import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
	colors: {
		primary: {
			50: '#FAE8E8',
			100: '#F4D1D0',
			200: '#E9A2A1',
			300: '#DF7471',
			400: '#D44542',
			500: '#C91713',
			600: '#97110E',
			700: '#650C0A',
			800: '#320605',
			900: '#140202',
		},
	},
});
