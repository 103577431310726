import {
	HStack,
	Flex,
	Select,
	IconButton,
	Square,
	Text,
} from '@chakra-ui/react';
import React from 'react';
import {
	FiChevronLeft,
	FiChevronRight,
	FiChevronsLeft,
	FiChevronsRight,
} from 'react-icons/fi';

interface PaginationProps {
	limit: number;
	page: number;
	totalItems: number;
	setPage: (n: number) => void;
	setLimit: (n: number) => void;
	alterLimit?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
	limit,
	page,
	setPage,
	setLimit,
	totalItems,
	alterLimit = true,
}) => {
	return (
		<HStack justifyContent='flex-end'>
			{alterLimit && (
				<Flex alignItems='center'>
					<Text mr='2'>Rows:</Text>
					<Select
						value={limit}
						onChange={(e) => setLimit(parseInt(e.target.value))}
					>
						<option value='5'>5</option>
						<option value='10'>10</option>
						<option value='20'>20</option>
					</Select>
				</Flex>
			)}
			<IconButton
				icon={<FiChevronsLeft />}
				onClick={() => setPage(1)}
				disabled={page === 1}
				size='sm'
				aria-label='page'
				variant='ghost'
			/>
			<IconButton
				icon={<FiChevronLeft />}
				onClick={() => setPage(page - 1)}
				disabled={page === 1}
				size='sm'
				aria-label='page'
				variant='ghost'
			/>
			<Square size='30px' color='blue.600' bg='gray.100'>
				{page}
			</Square>
			<IconButton
				icon={<FiChevronRight />}
				onClick={() => setPage(page + 1)}
				disabled={page >= Math.ceil(totalItems / limit)}
				size='sm'
				aria-label='page'
				variant='ghost'
			/>
			<IconButton
				icon={<FiChevronsRight />}
				onClick={() => setPage(Math.ceil(totalItems / limit))}
				disabled={page === Math.ceil(totalItems / limit)}
				size='sm'
				aria-label='page'
				variant='ghost'
			/>
		</HStack>
	);
};
