import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SubTemplateType, SubTemplateWidgetType } from '../../types/types';

interface InitialState {
	inputDrawerComponent: SubTemplateType | SubTemplateWidgetType | undefined;
	inputDrawerComponentType: 'SECTION' | 'WIDGET' | '';
	sectionIdentifier?: {
		id?: string;
		identifier?: string;
	};
}

const initialState: InitialState = {
	inputDrawerComponent: undefined,
	inputDrawerComponentType: '',
	sectionIdentifier: {},
};

const editorDrawerSlice = createSlice({
	name: 'editorDrawer',
	initialState,
	reducers: {
		setInputDrawerComponent: (
			state,
			action: PayloadAction<{
				component: SubTemplateType | SubTemplateWidgetType;
				componentType: 'SECTION' | 'WIDGET' | '';
				sectionIdentifier?: {
					id?: string;
					identifier?: string;
				};
			}>
		) => {
			const { component, componentType, sectionIdentifier } = action.payload;
			state.inputDrawerComponent = component;
			state.inputDrawerComponentType = componentType;
			state.sectionIdentifier = sectionIdentifier;
		},
		removeInputDrawerComponent: (state) => {
			state.inputDrawerComponent = undefined;
			state.inputDrawerComponentType = '';
			state.sectionIdentifier = {};
		},
		updateDrawerWidgets: (
			state,
			action: PayloadAction<{ widget: SubTemplateWidgetType[] }>
		) => {
			const { widget } = action.payload;
			(state.inputDrawerComponent as SubTemplateType).widget = widget;
		},
	},
});

export const { removeInputDrawerComponent, setInputDrawerComponent } =
	editorDrawerSlice.actions;

export const editorDrawerReducer = editorDrawerSlice.reducer;
