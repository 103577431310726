import { Flex, Icon, Center } from '@chakra-ui/react';
import React from 'react';
import { FiChevronDown, FiChevronUp, FiImage } from 'react-icons/fi';

interface DynamicSectionListProps {}

export const DynamicSectionList: React.FC<DynamicSectionListProps> = () => {
	return (
		<Flex alignItems='center' direction='column' height='100px'>
			<Icon as={FiChevronUp} />
			<Center bg='primary.100' w='100%' height='100%' mb='1'>
				<Icon as={FiImage} />
			</Center>
			<Center bg='primary.100' w='100%' height='100%' mb='1'>
				<Icon as={FiImage} />
			</Center>
			<Center bg='primary.100' w='100%' height='100%'>
				<Icon as={FiImage} />
			</Center>
			<Icon as={FiChevronDown} />
		</Flex>
	);
};
