import { HStack, Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface TwoBannerGridProps {}

export const TwoBannerGrid: React.FC<TwoBannerGridProps> = () => {
	return (
		<HStack spacing={1}>
			<Center w='100%' height='100px' rounded='md' bg='primary.100'>
				<Icon as={FiImage} />
			</Center>
			<Center w='100%' height='100px' rounded='md' bg='primary.100'>
				<Icon as={FiImage} />
			</Center>
		</HStack>
	);
};
