import { Box, BoxProps } from '@chakra-ui/layout';
import React from 'react';

interface CardBoxProps extends BoxProps {}

export const CardBox: React.FC<CardBoxProps> = ({ children, ...props }) => {
	return (
		<Box boxShadow='2px 2px 8px rgba(0, 0, 0, 0.09)' rounded='md' {...props}>
			{children}
		</Box>
	);
};
