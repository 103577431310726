import {
	removeAccessToken,
	removeRefreshToken,
	removeUser,
} from './authFunctions';
import { resetModule } from './moduleFunctions';

export const logout = () => {
	resetModule();
	removeAccessToken();
	removeRefreshToken();
	removeUser();
	window.location.reload();
};
