import { Box, Center } from '@chakra-ui/react';
import React from 'react';

interface DefaultComponentProps {}

export const DefaultComponent: React.FC<DefaultComponentProps> = () => {
	return (
		<Box border='1px solid' borderColor='gray.100' width='100%'>
			<Center height='100px'>?</Center>
		</Box>
	);
};
