import { Box, Center, Divider, Flex, HStack, Text } from '@chakra-ui/layout';
import { IconButton, Spinner, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiMaximize, FiMinimize, FiSave, FiTrash2 } from 'react-icons/fi';
import { useLocation, useParams } from 'react-router-dom';
import { SectionReorderType } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import {
	addPage,
	moveSubTemplateInPage,
	removeContentFromPage,
} from '../redux/reducers/currentPageSlice';
import { setInputDrawerComponent } from '../redux/reducers/editorDrawerSlice';
import {
	moveSubTemplate,
	removeFromIsSaved,
	removeSubTemplateFromContent,
	updateToNewSubTemplate,
} from '../redux/reducers/subTemplateSlice';
import {
	SubTemplateType,
	SubTemplateWidgetType,
	TemplateListType,
} from '../types/types';
import { makeBinaryFromBase64 } from '../utils/getBinaryFromBase64';
import { makeSubTemplateForRequest } from '../utils/makeItemForRequest';
import { DeleteSubTemplateWarningModal } from './DeleteSubTemplateWarningModal';
import { SaveSubTemplateWarningModal } from './SaveSubTemplateWarningModal';
import { FileDetailsIcon } from './svgs/FileDetailsIcon';
import { WidgetGrid } from './WidgetGrid';

interface SubTemplateCardProps {
	subTemplate: SubTemplateType;
}

export const SubTemplateCard: React.FC<SubTemplateCardProps> = ({
	subTemplate,
}) => {
	const [showDetails, setShowDetails] = useState(true);
	const { isSaved, isChangedImage } = useAppSelector(
		(state) => state.subTemplate
	);
	const { currentPage } = useAppSelector((state) => state.currentPage);
	const { inputDrawerComponent } = useAppSelector(
		(state) => state.editorDrawer
	);
	const toast = useToast();
	const location = useLocation();
	const [affectedTemplates, setAffectedTemplates] = useState<
		TemplateListType[]
	>([]);
	const dispatch = useAppDispatch();
	const [, drag] = useDrag(
		() => ({
			type: SectionReorderType,
			item: subTemplate,
			canDrag: () => {
				if (isSaved.length !== 0) {
					return false;
				}
				return true;
			},
			collect: (monitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[subTemplate, isSaved]
	);
	const [isSaving, setIsSaving] = useState(false);
	const { templateId } = useParams();

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: SectionReorderType,
			drop: (item: SubTemplateType) => {
				if (item._id && item._id !== subTemplate._id) {
					dispatch(
						moveSubTemplateInPage({
							id: item._id,
							oldPosition: item.position!,
							newPosition: subTemplate.position!,
						})
					);
					return dispatch(
						moveSubTemplate({
							id: item._id,
							oldPosition: item.position!,
							newPosition: subTemplate.position!,
						})
					);
				}
				return;
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[subTemplate]
	);

	const [currentModal, setCurrentModal] = useState<'save' | 'delete' | null>(
		null
	);

	const onOpen = (s: 'save' | 'delete') => {
		setCurrentModal(s);
	};

	const onClose = () => {
		setCurrentModal(null);
	};

	const colorSchemes = {
		active: {
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(7,78,232)' stroke-width='4' stroke-dasharray='19%2c 17' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
			backgroundColor: '#EEF4FF',
		},
		saved: {
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(45,145,64)' stroke-width='4' stroke-dasharray='19%2c 17' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
			backgroundColor: '#EAF4EC',
		},
		error: {
			backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgb(201,23,19)' stroke-width='4' stroke-dasharray='19%2c 17' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
			backgroundColor: '#FAE8E7',
		},
	};

	const [currentScheme, setCurrentScheme] = useState<
		'active' | 'saved' | 'error'
	>('saved');

	const confirmSaveFunction = async () => {
		try {
			if (!subTemplate._id) {
				return saveFunction();
			}
			const result = await axios.get(
				`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/template/sub-template/${subTemplate._id}`
			);
			setAffectedTemplates(result.data.data.templates);

			if (result.data.data?.templates?.length > 0) {
				onOpen('save');
			} else {
				saveFunction();
			}
		} catch (err: any) {
			toast({
				position: 'top',
				status: 'error',
				description: err?.response?.data?.message || 'An error occurred',
			});
		}
	};

	const saveFunction = async () => {
		setIsSaving(true);
		try {
			let temp = makeSubTemplateForRequest(subTemplate);

			const getImageUrl = (wid: SubTemplateWidgetType, s: string) => {
				if (wid.details && typeof wid.details[s] === 'object') {
					return '';
				}
				return wid.details![s];
			};

			temp.widget = temp.widget.map((wid) => {
				return {
					...wid,
					details: {
						...wid.details,
						imageUrl: getImageUrl(wid, 'imageUrl'),
						imageUrlWeb: getImageUrl(wid, 'imageUrlWeb'),
					},
				};
			});

			if (subTemplate._id) {
				let updatedSubTemplate: any;

				let subTemplatePostResponse = await axios.put(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template`,
					temp
				);
				let fileList = subTemplate.widget
					.filter(
						(wid) =>
							isChangedImage.includes(wid.details?.identifier) &&
							wid.details?.imageUrl &&
							typeof wid.details?.imageUrl === 'object'
					)
					.map((wid) => {
						return {
							fileName: wid.details!.imageUrl.fileName,
							mimeType: wid.details!.imageUrl.mimeType,
							identifier: wid.details!.identifier,
							subTemplateId: subTemplatePostResponse.data.data._id,
						};
					});

				fileList.push(
					...subTemplate.widget
						.filter(
							(wid) =>
								isChangedImage.includes(wid.details?.identifier) &&
								wid.details?.imageUrlWeb &&
								typeof wid.details?.imageUrlWeb === 'object'
						)
						.map((wid) => {
							return {
								fileName: wid.details!.imageUrlWeb.fileName,
								mimeType: wid.details!.imageUrlWeb.mimeType,
								type: 'web',
								identifier: wid.details!.identifier,
								subTemplateId: subTemplatePostResponse.data.data._id,
							};
						})
				);

				if (fileList.length > 0) {
					const uploadUrl: any = await axios.post(
						`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/upload-image`,
						{ files: fileList }
					);

					let files: any = await Promise.all(
						subTemplate.widget
							.filter(
								(wid) =>
									isChangedImage.includes(wid.details?.identifier) &&
									typeof wid.details?.imageUrl === 'object'
							)
							.map(async (wid) => {
								return {
									[wid.details?.identifier!]: await makeBinaryFromBase64(
										wid.details?.imageUrl.preview
									),
								};
							})
					);

					let moreFiles: any = await Promise.all(
						subTemplate.widget
							.filter(
								(wid) =>
									isChangedImage.includes(wid.details?.identifier) &&
									typeof wid.details?.imageUrlWeb === 'object'
							)
							.map(async (wid) => {
								return {
									[wid.details?.identifier!]: await makeBinaryFromBase64(
										wid.details?.imageUrlWeb.preview
									),
								};
							})
					);

					files.push(...moreFiles);

					for (let i = 0; i < uploadUrl.data.data.length; i++) {
						await axios.put(
							uploadUrl.data.data[i].uploadURL,
							files[i][uploadUrl.data.data[i].identifier]
						);
					}
					let keys: any = {};
					let keysWeb: any = {};
					uploadUrl.data.data.forEach((fl: any) => {
						if (fl.type) {
							keysWeb[fl.identifier] = fl.key;
						} else {
							keys[fl.identifier] = fl.key;
						}
					});

					let newSubTemplate = makeSubTemplateForRequest(
						subTemplatePostResponse.data.data
					);

					newSubTemplate.widget = newSubTemplate.widget.map((wid) => {
						return {
							...wid,
							details: {
								...wid.details,
								imageUrl:
									keys[wid.details?.identifier] || wid.details?.imageUrl,
								imageUrlWeb:
									keysWeb[wid.details?.identifier] || wid.details?.imageUrlWeb,
							},
						};
					});

					let putSubTemplate = await axios.put(
						`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template`,
						newSubTemplate
					);

					updatedSubTemplate = await axios.get(
						`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/${putSubTemplate.data.data._id}`
					);

					dispatch(
						updateToNewSubTemplate({
							subTemplate: updatedSubTemplate.data.data,
							id: subTemplate._id,
							identifier: subTemplate.identifier,
							position: subTemplate.position!,
						})
					);
				} else {
					updatedSubTemplate = subTemplatePostResponse;
				}
				if (!location.pathname.includes('sub-templates')) {
					let existingSubTemplates = currentPage?.content.map((v) => {
						return v.subTemplateId;
					});

					if (
						!existingSubTemplates?.includes(updatedSubTemplate.data.data._id)
					) {
						let updatedPage = await axios.put(
							`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`,
							{
								templateId,
								...currentPage,
								content: [
									...(currentPage?.content || []),
									{
										subTemplateId: updatedSubTemplate.data.data._id,
										position: subTemplate.position!,
									},
								],
							}
						);

						dispatch(addPage({ page: updatedPage.data.data }));
					}
				}
				dispatch(removeFromIsSaved({ identifier: subTemplate._id! }));
				toast({
					position: 'top',
					status: 'success',
					description: 'All requests handled successfully',
				});
			} else {
				let subTemplatePostResponse = await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template`,
					temp
				);
				let fileList = subTemplate.widget
					.filter(
						(wid) =>
							wid.details?.imageUrl && typeof wid.details?.imageUrl === 'object'
					)
					.map((wid) => {
						return {
							fileName: wid.details!.imageUrl.fileName,
							mimeType: wid.details!.imageUrl.mimeType,
							identifier: wid.details!.identifier,
							subTemplateId: subTemplatePostResponse.data.data._id,
						};
					});

				fileList.push(
					...subTemplate.widget
						.filter(
							(wid) =>
								wid.details?.imageUrlWeb &&
								typeof wid.details?.imageUrlWeb === 'object'
						)
						.map((wid) => {
							return {
								fileName: wid.details!.imageUrlWeb.fileName,
								mimeType: wid.details!.imageUrlWeb.mimeType,
								type: 'web',
								identifier: wid.details!.identifier,
								subTemplateId: subTemplatePostResponse.data.data._id,
							};
						})
				);

				const uploadUrl: any = await axios.post(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/upload-image`,
					{ files: fileList }
				);

				let files: any = await Promise.all(
					subTemplate.widget
						.filter(
							(wid) =>
								isChangedImage.includes(wid.details?.identifier) &&
								typeof wid.details?.imageUrl === 'object'
						)
						.map(async (wid) => {
							return {
								[wid.details?.identifier!]: await makeBinaryFromBase64(
									wid.details?.imageUrl.preview
								),
							};
						})
				);

				let moreFiles: any = await Promise.all(
					subTemplate.widget
						.filter(
							(wid) =>
								isChangedImage.includes(wid.details?.identifier) &&
								typeof wid.details?.imageUrlWeb === 'object'
						)
						.map(async (wid) => {
							return {
								[wid.details?.identifier!]: await makeBinaryFromBase64(
									wid.details?.imageUrlWeb.preview
								),
							};
						})
				);

				files.push(...moreFiles);

				for (let i = 0; i < uploadUrl.data.data.length; i++) {
					await axios.put(
						uploadUrl.data.data[i].uploadURL,
						files[i][uploadUrl.data.data[i].identifier]
					);
				}
				let keys: any = {};
				let keysWeb: any = {};
				uploadUrl.data.data.forEach((fl: any) => {
					if (fl.type) {
						keysWeb[fl.identifier] = fl.key;
					} else {
						keys[fl.identifier] = fl.key;
					}
				});

				let newSubTemplate = makeSubTemplateForRequest(
					subTemplatePostResponse.data.data
				);

				newSubTemplate.widget = newSubTemplate.widget.map((wid) => {
					return {
						...wid,
						details: {
							...wid.details,
							imageUrl: keys[wid.details?.identifier] || wid.details?.imageUrl,
							imageUrlWeb:
								keysWeb[wid.details?.identifier] || wid.details?.imageUrlWeb,
						},
					};
				});

				let putSubTemplate = await axios.put(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template`,
					newSubTemplate
				);

				let updatedSubTemplate = await axios.get(
					`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/sub-template/${putSubTemplate.data.data._id}`
				);

				dispatch(
					updateToNewSubTemplate({
						subTemplate: updatedSubTemplate.data.data,
						identifier: subTemplate.identifier!,
						position: subTemplate.position!,
					})
				);
				if (!location.pathname.includes('sub-templates')) {
					let updatedPage = await axios.put(
						`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`,
						{
							templateId,
							...currentPage,
							content: [
								...(currentPage?.content || []),
								{
									subTemplateId: updatedSubTemplate.data.data._id,
									position: subTemplate.position!,
								},
							],
						}
					);

					dispatch(addPage({ page: updatedPage.data.data }));
				}
				dispatch(removeFromIsSaved({ identifier: subTemplate.identifier! }));
				toast({
					position: 'top',
					status: 'success',
					description: 'All requests handled successfully',
				});
			}
		} catch (err: any) {
			toast({
				position: 'top',
				status: 'error',
				description: err?.response?.data?.message || 'An error occurred',
			});
		}
		return setIsSaving(false);
	};

	const deleteFunction = async () => {
		let pageSubtemplateIdList = currentPage?.content.map((con) => {
			return con.subTemplateId;
		});
		try {
			if (pageSubtemplateIdList?.includes(subTemplate._id!)) {
				let temp: any = Object.assign({}, currentPage);
				temp.content = temp.content
					.filter((w: any) => w.subTemplateId !== subTemplate._id!)
					.map((w: any) => {
						if (w.position > subTemplate.position!) {
							return { ...w, position: w.position - 1 };
						}
						return w;
					});
				await axios.put(`${process.env.REACT_APP_HBS_BASE_URL}/api/v1/page`, {
					templateId,
					...temp,
				});
				dispatch(
					removeContentFromPage({
						subTemplateId: subTemplate._id!,
						position: subTemplate.position!,
					})
				);
			}

			dispatch(
				removeSubTemplateFromContent({
					id: subTemplate._id,
					identifier: subTemplate.identifier,
					position: subTemplate.position!,
				})
			);
			toast({
				position: 'top',
				status: 'success',
				description: 'Sub-template removed successfully',
			});
		} catch (err: any) {
			toast({
				position: 'top',
				status: 'error',
				description: err?.response?.data?.message || 'An error occurred',
			});
		}
		return;
	};

	useEffect(() => {
		if (
			inputDrawerComponent?._id &&
			inputDrawerComponent?._id === subTemplate._id
		) {
			return setCurrentScheme('active');
		}
		if (
			(inputDrawerComponent as SubTemplateType)?.identifier &&
			(inputDrawerComponent as SubTemplateType)?.identifier ===
				subTemplate.identifier
		) {
			return setCurrentScheme('active');
		}
		if (isSaved.includes(subTemplate?._id || subTemplate?.identifier || '')) {
			return setCurrentScheme('error');
		} else {
			return setCurrentScheme('saved');
		}
	}, [isSaved, inputDrawerComponent, subTemplate]);

	return (
		<>
			<SaveSubTemplateWarningModal
				templates={affectedTemplates}
				isOpen={currentModal === 'save'}
				onClose={onClose}
				endFunction={saveFunction}
			/>
			<DeleteSubTemplateWarningModal
				endFunction={deleteFunction}
				isOpen={currentModal === 'delete'}
				onClose={onClose}
			/>
			<Box
				w='full'
				borderTop='5px solid'
				borderTopColor={isOver ? 'gray.600' : 'transparent'}
			>
				<Box
					width='100%'
					backgroundImage={colorSchemes[currentScheme].backgroundImage}
					p='1'
					id={subTemplate._id || subTemplate.identifier}
					css={{ scrollMarginTop: '52px' }}
					position='relative'
					ref={(node) => drag(drop(node))}
				>
					{isSaving && (
						<Center
							position='absolute'
							height='100%'
							width='100%'
							top='0'
							left='0'
							bg='white'
							opacity='0.6'
							zIndex={100}
							flexDirection='column'
						>
							<Spinner
								thickness='4px'
								speed='0.65s'
								emptyColor='gray.200'
								color='primary.500'
								size='xl'
							/>
							<Text fontWeight='bold' fontSize='xl' mt='2' color='primary.600'>
								Saving
							</Text>
						</Center>
					)}
					<Box bg={colorSchemes[currentScheme].backgroundColor}>
						<Flex px='2' alignItems='center' justifyContent='space-between'>
							<Text fontWeight='bold'>
								#{subTemplate.position} {subTemplate.name}
							</Text>
							<HStack spacing={2}>
								<IconButton
									icon={<FiSave />}
									colorScheme='gray'
									variant='ghost'
									aria-label='icon-button'
									draggable={true}
									onDragStart={(e) => e.preventDefault()}
									color='gray.600'
									onClick={confirmSaveFunction}
									disabled={
										!isSaved.includes(
											subTemplate._id || subTemplate.identifier || ''
										)
									}
								/>
								<IconButton
									icon={showDetails ? <FiMinimize /> : <FiMaximize />}
									colorScheme='gray'
									variant='ghost'
									aria-label='icon-button'
									draggable={true}
									onDragStart={(e) => e.preventDefault()}
									color='gray.600'
									onClick={() => setShowDetails(!showDetails)}
								/>
								<IconButton
									icon={<FileDetailsIcon />}
									colorScheme='gray'
									variant='ghost'
									aria-label='icon-button'
									draggable={true}
									onDragStart={(e) => e.preventDefault()}
									color='gray.600'
									onClick={() =>
										dispatch(
											setInputDrawerComponent({
												component: subTemplate,
												componentType: 'SECTION',
											})
										)
									}
								/>
								{!location.pathname.includes('sub-templates') && (
									<IconButton
										icon={<FiTrash2 />}
										colorScheme='gray'
										variant='ghost'
										aria-label='icon-button'
										draggable={true}
										onDragStart={(e) => e.preventDefault()}
										color='gray.600'
										onClick={() => onOpen('delete')}
									/>
								)}
							</HStack>
						</Flex>
						{showDetails && (
							<>
								<Divider />
								<Box p='2'>
									{subTemplate.widget && (
										<WidgetGrid
											widgets={subTemplate.widget}
											subTemplateIdentifier={
												subTemplate._id
													? { id: subTemplate._id }
													: { identifier: subTemplate.identifier }
											}
										/>
									)}
								</Box>
							</>
						)}
					</Box>
				</Box>
			</Box>
		</>
	);
};
