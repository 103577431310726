import { Center, Icon } from '@chakra-ui/react';
import React from 'react';
import { FiImage } from 'react-icons/fi';

interface AdBannerItemProps {}

export const AdBannerItem: React.FC<AdBannerItemProps> = () => {
	return (
		<Center bg='primary.100' w='100%' height='100px' rounded='md'>
			<Icon as={FiImage} />
		</Center>
	);
};
