import {
	Alert,
	AlertIcon,
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	IconButton,
	Input,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tooltip,
	Tr,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { Fragment, useEffect, useState } from 'react';
import { FiCornerDownRight, FiX } from 'react-icons/fi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { FullPageLoader } from '../../../../components/FullPageLoader';

interface Permission {
	permission_access_code: string;
	is_read: boolean;
	is_create: boolean;
	is_delete: boolean;
	is_update: boolean;
}

interface InitialValue {
	id: string;
	name: string;
	description: string;
	permissions: Permission[];
}

export const EditRolesForm: React.FC = () => {
	const initialValues: InitialValue = {
		id: '',
		name: '',
		description: '',
		permissions: [],
	};

	const [allPermissions, setAllPermissions] = useState<any[]>([]);
	const [loading, setLoading] = useState(true);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		description: Yup.string().required('Description is required'),
		permissions: Yup.array().min(1, 'At least 1 user permission is required'),
	});

	const toast = useToast();
	const navigate = useNavigate();
	const { roleId } = useParams();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			let perms = values.permissions.filter(
				(p: any) => p.is_create || p.is_update || p.is_read || p.is_delete
			);
			if (perms.length === 0) {
				setStatus('At least 1 permission is required for role');
				setSubmitting(false);
				return;
			}
			let deletePerms = values.permissions
				.filter(
					(p: any) => !p.is_create && !p.is_update && !p.is_read && !p.is_delete
				)
				.map((p: any) => p.permission_access_code);

			return axios
				.put(`${process.env.REACT_APP_UMS_BASE_URL}/role`, {
					...values,
					permissions: perms,
					deletePermission: deletePerms,
				})
				.then((res) => {
					toast({
						description: 'Role details updated',
						status: 'success',
					});
					navigate('/roles');
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setSubmitting(false);
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	useEffect(() => {
		const flattenWithParentCode = (perms: any, parentCode = '') => {
			let temp: any[] = [];
			perms.forEach((p: any) => {
				temp.push({
					code: p.code,
					id: p.id,
					name: p.name,
					parentCode,
				});
				if (p.subPermission) {
					temp = temp.concat(flattenWithParentCode(p.subPermission, p.code));
				}
			});
			return temp;
		};
		const loadPermissions = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_UMS_BASE_URL}/permission`
				);
				let flattenedPermissions = flattenWithParentCode(result.data.data);
				setAllPermissions(flattenedPermissions);
			} catch (error) {
				console.log(error);
			}
		};
		loadPermissions();
	}, []);

	useEffect(() => {
		const makeInitialPermissions = (formikPerms: any) => {
			let permissionCodeArr = formikPerms.map(
				(p: any) => p.permission_access_code
			);
			let initialPerms = allPermissions.map((p: any) => {
				if (permissionCodeArr.includes(p.code)) {
					const {
						permission_access_code,
						is_create,
						is_update,
						is_read,
						is_delete,
					} = formikPerms.filter(
						(fp: any) => fp.permission_access_code === p.code
					)[0];
					return {
						permission_access_code,
						is_create,
						is_delete,
						is_read,
						is_update,
					};
				}
				return {
					permission_access_code: p.code,
					is_create: false,
					is_delete: false,
					is_read: false,
					is_update: false,
				};
			});
			return initialPerms;
		};
		const loadRole = async () => {
			try {
				const result = await axios.get(
					`${process.env.REACT_APP_UMS_BASE_URL}/role/permission/${roleId}`
				);
				if (!result.data.data.role) {
					toast({
						description: 'Invalid role details',
						status: 'error',
					});
					navigate('/roles');
					return;
				}
				const { id, name, description } = result.data.data.role;
				formik.setFieldValue('id', id);
				formik.setFieldValue('name', name);
				formik.setFieldValue('description', description);
				formik.setFieldValue(
					'permissions',
					makeInitialPermissions(result.data.data.permissions)
				);
			} catch (error) {
				console.log(error);
			}
			setLoading(false);
		};
		if (allPermissions.length > 0) {
			loadRole();
		}
	}, [roleId, allPermissions.length]);

	const isAlreadyChecked = (code: string, permissionName = 'all') => {
		let current = formik.values.permissions.filter(
			(p: any) => p.permission_access_code === code
		)[0];
		if (
			current?.is_read &&
			(permissionName === 'all' || permissionName === 'read')
		) {
			return true;
		}
		if (
			current?.is_create &&
			(permissionName === 'all' || permissionName === 'create')
		) {
			return true;
		}
		if (
			current?.is_update &&
			(permissionName === 'all' || permissionName === 'update')
		) {
			return true;
		}
		if (
			current?.is_delete &&
			(permissionName === 'all' || permissionName === 'delete')
		) {
			return true;
		}
		return false;
	};

	const getChildren = (code: string) => {
		let children = allPermissions.filter((p: any) => p.parentCode === code);
		let temp: any[] = children.map((c: any) => c.code);
		if (children.length === 0) {
			return [];
		}
		children.forEach((c) => {
			temp = temp.concat(getChildren(c.code));
		});
		return temp;
	};

	const getParents = (code: string) => {
		let current = allPermissions.filter((p: any) => p.code === code);
		let temp: any[] = [current[0]?.parentCode];
		if (!current[0].parentCode) {
			return [];
		}
		if (current[0].parentCode) {
			temp = temp.concat(getParents(current[0]?.parentCode));
		}
		return temp;
	};

	const toRemoveParents = (code: string, permissionName = 'all') => {
		let current = allPermissions.filter((p: any) => p.code === code)[0];
		let temp: any[] = [current?.parentCode];
		if (!current?.parentCode) {
			return [];
		}
		let myRow = allPermissions.filter(
			(p: any) => p.parentCode === current?.parentCode
		);
		let checkedCount = 0;
		myRow.forEach((r: any) => {
			if (isAlreadyChecked(r?.code, permissionName)) {
				checkedCount += 1;
			}
		});
		if (checkedCount > 1) {
			return [];
		}
		if (checkedCount < 2) {
			temp = temp.concat(toRemoveParents(current?.parentCode, permissionName));
		}
		return temp;
	};

	const checkFunction = (code: string, permissionName = 'all') => {
		let checked = isAlreadyChecked(code, permissionName);
		let toCheckParents = getParents(code);
		let toCheckChildren = getChildren(code);
		let toCheckItems: any[] = [];
		if (!checked) {
			toCheckItems = [...toCheckChildren, ...toCheckParents];
			formik.setFieldValue(
				'permissions',
				formik.values.permissions.map((p: any) => {
					if (
						p.permission_access_code === code ||
						toCheckItems.includes(p.permission_access_code)
					) {
						return {
							permission_access_code: p.permission_access_code,
							is_read:
								permissionName === 'all' || permissionName === 'read'
									? true
									: p.is_read,
							// is_create:
							// 	permissionName === 'all' || permissionName === 'create'
							// 		? true
							// 		: p.is_create,
							// is_delete:
							// 	permissionName === 'all' || permissionName === 'delete'
							// 		? true
							// 		: p.is_delete,
							// is_update:
							// 	permissionName === 'all' || permissionName === 'update'
							// 		? true
							// 		: p.is_update,
							is_create: false,
							is_delete: false,
							is_update: false,
						};
					}
					return p;
				})
			);
		} else {
			toCheckItems = [
				...toCheckChildren,
				...toRemoveParents(code, permissionName),
			];
			formik.setFieldValue(
				'permissions',
				formik.values.permissions.map((p: any) => {
					if (
						p.permission_access_code === code ||
						toCheckItems.includes(p.permission_access_code)
					) {
						return {
							permission_access_code: p.permission_access_code,
							is_read:
								permissionName === 'all' || permissionName === 'read'
									? false
									: p.is_read,
							// is_create:
							// 	permissionName === 'all' || permissionName === 'create'
							// 		? false
							// 		: p.is_create,
							// is_delete:
							// 	permissionName === 'all' || permissionName === 'delete'
							// 		? false
							// 		: p.is_delete,
							// is_update:
							// 	permissionName === 'all' || permissionName === 'update'
							// 		? false
							// 		: p.is_update,
							is_create: false,
							is_delete: false,
							is_update: false,
						};
					}
					return p;
				})
			);
		}
	};

	const makeCheck = (parentCode = '', rIndex = 0) => {
		return allPermissions
			.filter((p: any) => p.parentCode === parentCode)
			.map((perms: any, index) => {
				return (
					<Fragment key={perms.code}>
						<Tr bg={rIndex === 0 ? 'gray.50' : 'white'}>
							<Td
								textAlign='center'
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Checkbox
									type='checkbox'
									isChecked={isAlreadyChecked(perms?.code, 'all')}
									onChange={() => checkFunction(perms?.code)}
									mr='5px'
								/>
							</Td>
							<Td
								pl={`${rIndex * 20}px`}
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Flex>
									{rIndex > 0 && <FiCornerDownRight />}
									<Text ml='3px'>{perms?.name}</Text>
								</Flex>
							</Td>
							<Td
								textAlign='center'
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Checkbox
									type='checkbox'
									isChecked={isAlreadyChecked(perms?.code, 'read')}
									onChange={() => checkFunction(perms?.code, 'read')}
									mr='5px'
								/>
							</Td>
							<Td
								textAlign='center'
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Checkbox
									type='checkbox'
									isChecked={isAlreadyChecked(perms?.code, 'create')}
									onChange={() => checkFunction(perms?.code, 'create')}
									mr='5px'
									isDisabled
								/>
							</Td>
							<Td
								textAlign='center'
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Checkbox
									type='checkbox'
									isChecked={isAlreadyChecked(perms?.code, 'update')}
									onChange={() => checkFunction(perms?.code, 'update')}
									mr='5px'
									isDisabled
								/>
							</Td>
							<Td
								textAlign='center'
								borderTop={
									rIndex === 0 && index !== 0 ? '20px solid white' : '0'
								}
								borderColor='white'
							>
								<Checkbox
									type='checkbox'
									isChecked={isAlreadyChecked(perms?.code, 'delete')}
									onChange={() => checkFunction(perms?.code, 'delete')}
									mr='5px'
									isDisabled
								/>
							</Td>
						</Tr>
						{allPermissions.filter((p: any) => p.parentCode === perms.code)
							.length > 0 && <>{makeCheck(perms.code, rIndex + 1)}</>}
					</Fragment>
				);
			});
	};

	return (
		<Box rounded='md' position='relative' py='6' px='2'>
			{(loading || formik.isSubmitting) && <FullPageLoader />}
			<Flex justifyContent='space-between' alignItems='center'>
				<Heading size='md' mb={6}>
					Create a new Role
				</Heading>
				<Tooltip label='Go Back'>
					<IconButton
						as={Link}
						to='/roles'
						icon={<FiX />}
						size='sm'
						aria-label='Back'
						variant='ghost'
						colorScheme='gray'
					/>
				</Tooltip>
			</Flex>
			<Box mb={6}>
				<form onSubmit={formik.handleSubmit}>
					{formik.status && (
						<Alert status='error' mb='4' fontSize='sm'>
							<AlertIcon />
							{formik.status}
						</Alert>
					)}
					<FormControl isInvalid={!!formik.errors.name} mb='4'>
						<FormLabel>Name</FormLabel>
						<Input {...formik.getFieldProps('name')} />
						<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!formik.errors.description} mb='4'>
						<FormLabel>Description</FormLabel>
						<Input {...formik.getFieldProps('description')} />
						<FormErrorMessage>{formik.errors.description}</FormErrorMessage>
					</FormControl>
					<FormControl isInvalid={!!formik.errors.permissions} mb='4'>
						<FormLabel>Permissions</FormLabel>
						<FormErrorMessage>{formik.errors.permissions}</FormErrorMessage>
						<Table>
							<Thead position='sticky' top='0' bg='white' zIndex='2'>
								<Tr>
									<Th textAlign='center'></Th>
									<Th textAlign='left'>Name</Th>
									<Th textAlign='center'>Read</Th>
									<Th textAlign='center'>Create</Th>
									<Th textAlign='center'>Update</Th>
									<Th textAlign='center'>Delete</Th>
								</Tr>
							</Thead>
							<Tbody>
								{allPermissions && allPermissions.length > 0 && makeCheck()}
							</Tbody>
						</Table>
					</FormControl>
					<Box textAlign='right'>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</Box>
					{/* <pre>{JSON.stringify(formik.values, null, 2)}</pre> */}
				</form>
			</Box>
		</Box>
	);
};
