let accessToken = '';

export const getAccessToken = () => {
	return accessToken;
};

export const setAccessToken = (token: string) => {
	accessToken = token;
};

export const removeAccessToken = () => {
	accessToken = '';
};

export const getRefreshToken = () => {
	return localStorage.getItem('refreshToken');
};

export const setRefreshToken = (token: string) => {
	return localStorage.setItem('refreshToken', token);
};

export const removeRefreshToken = () => {
	return localStorage.removeItem('refreshToken');
};

export const setUser = (p: any) => {
	return localStorage.setItem('user_details', JSON.stringify(p));
};

export const getUser = () => {
	let user = localStorage.getItem('user_details');
	return user ? JSON.parse(user) : null;
};

export const removeUser = () => {
	localStorage.removeItem('user_details');
};
