import { Box, Center, Icon, Image } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { FiMonitor, FiSmartphone } from 'react-icons/fi';
import { WidgetSwapType } from '../constants/constants';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import { setInputDrawerComponent } from '../redux/reducers/editorDrawerSlice';
import { moveWidgetInContent } from '../redux/reducers/subTemplateSlice';
import { SubTemplateWidgetType } from '../types/types';

interface WidgetCardProps {
	widget: SubTemplateWidgetType;
	subTemplateIdentifier: {
		id?: string;
		identifier?: string;
	};
}

export const WidgetCard: React.FC<WidgetCardProps> = ({
	widget,
	subTemplateIdentifier,
}) => {
	const dispatch = useAppDispatch();
	const { inputDrawerComponent } = useAppSelector(
		(state) => state.editorDrawer
	);
	const [isActive, setIsActive] = useState(false);

	const [, drag] = useDrag(
		() => ({
			type: WidgetSwapType,
			item: {
				identifier: widget.details?.identifier,
				oldPosition: widget.details?.position,
			},
		}),
		[widget]
	);

	const [{ isOver }, drop] = useDrop(
		() => ({
			accept: WidgetSwapType,
			drop: (item: { identifier: string; oldPosition: number }) => {
				const { identifier, oldPosition } = item;
				if (oldPosition === widget.details?.position) {
					return;
				}
				dispatch(
					moveWidgetInContent({
						identifier,
						oldPosition: oldPosition,
						newPosition: widget.details?.position!,
						subTemplateIdentifier,
					})
				);
			},
			collect: (monitor) => ({
				isOver: monitor.isOver(),
			}),
		}),
		[widget]
	);

	const ref = useRef<HTMLDivElement>(null);

	drag(drop(ref));

	useEffect(() => {
		if (inputDrawerComponent?._id && inputDrawerComponent?._id === widget._id) {
			return setIsActive(true);
		}
		if (
			inputDrawerComponent?.details?.identifier &&
			inputDrawerComponent?.details?.identifier === widget.details?.identifier
		) {
			return setIsActive(true);
		}
		return setIsActive(false);
	}, [inputDrawerComponent, widget]);

	return (
		<Box
			position='relative'
			width='100%'
			ref={ref}
			opacity={isOver ? 0.2 : 1}
			zIndex={4}
			bg='white'
			id={widget._id || widget.details?.identifier}
			css={{ scrollMarginTop: '54px' }}
			border='2px dashed'
			borderColor={isActive ? '#074EE8' : '#A3A3A3'}
			p='2'
		>
			<Center
				cursor='pointer'
				onClick={() =>
					dispatch(
						setInputDrawerComponent({
							component: widget,
							componentType: 'WIDGET',
							sectionIdentifier: subTemplateIdentifier,
						})
					)
				}
				minHeight='200px'
			>
				<Box
					width={
						widget.details?.imageUrlWeb?.preview || widget.details?.imageUrlWeb
							? '50%'
							: 'auto'
					}
					h='200px'
					position='relative'
				>
					<Icon
						position='absolute'
						top='0'
						left='0'
						color='gray.500'
						h={5}
						w={5}
						as={FiSmartphone}
					/>
					<Center p='5' h='100%'>
						<Image
							src={
								widget.details?.imageUrl?.preview ||
								widget.details?.cdnUrl + widget.details?.imageUrl
							}
							height='auto'
							maxHeight='200px'
							width='auto'
							objectFit='contain'
						/>
					</Center>
					{/* <Text textAlign='center'>Mobile</Text> */}
				</Box>
				{(widget.details?.imageUrlWeb?.preview ||
					widget.details?.imageUrlWeb) && (
					<Box
						width={
							widget.details?.imageUrlWeb?.preview ||
							widget.details?.imageUrlWeb
								? '50%'
								: 'auto'
						}
						h='200px'
						position='relative'
					>
						<Icon
							position='absolute'
							top='0'
							left='0'
							color='gray.500'
							h={5}
							w={5}
							as={FiMonitor}
						/>
						<Center p='5' h='100%'>
							<Image
								src={
									widget.details?.imageUrlWeb?.preview ||
									widget.details?.cdnUrl + widget.details?.imageUrlWeb
								}
								height='auto'
								maxHeight='200px'
								width='auto'
								objectFit='contain'
							/>
						</Center>
						{/* <Text textAlign='center'>Web</Text> */}
					</Box>
				)}
			</Center>
		</Box>
	);
};
