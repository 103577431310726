import {
	Alert,
	AlertIcon,
	Button,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import * as Yup from 'yup';
import { chakraSelect } from '../../../../utils/styleConstants';

interface EditUserModalFormProps {
	url: string;
	isOpen: boolean;
	onClose: () => void;
	selectedItem: any;
	refetchData: () => void;
}

export const EditUserModalForm: React.FC<EditUserModalFormProps> = ({
	url,
	isOpen,
	onClose,
	selectedItem,
	refetchData,
}) => {
	const [roleKeyword, setRoleKeyword] = useState('');
	const [selectedRole, setSelectedRole] = useState<any[] | null>(null);
	const [initialRoles, setInitialRoles] = useState<any[]>([]);
	const [rolePage, setRolePage] = useState(1);
	const [searchRolePage, setSearchRolePage] = useState(1);
	const [roleLoading, setRoleLoading] = useState(false);

	const initialValues = {
		id: selectedItem?.id || '',
		name: selectedItem?.name || '',
		mobilePrefix: selectedItem?.mobile?.slice(0, 2) === '97' ? '971' : '91',
		mobile:
			selectedItem?.mobile?.slice(0, 2) === '97'
				? selectedItem?.mobile?.slice(-9)
				: selectedItem?.mobile?.slice(-10) || '',
		email: selectedItem?.email || '',
		role: selectedItem?.user_roles || [],
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Name is required'),
		mobilePrefix: Yup.string().oneOf(
			['971', '91'],
			'Country Code is for mobile required'
		),
		mobile: Yup.number()
			.when('mobilePrefix', {
				is: '971',
				then: Yup.number().test(
					'len',
					'Must be exactly 9 characters',
					(val) => val?.toString().length === 9
				),
			})
			.when('mobilePrefix', {
				is: '91',
				then: Yup.number().test(
					'len',
					'Must be exactly 10 characters',
					(val) => val?.toString().length === 10
				),
			})
			.required('Mobile is required'),
		email: Yup.string().required('Email is required'),
		role: Yup.array().min(1, 'At least 1 user role is required'),
	});

	const toast = useToast();

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		validateOnBlur: false,
		validateOnChange: false,
		onSubmit: (values, { setStatus }) => {
			const deleteRole = initialRoles.filter(
				(v: string) => !values.role.includes(v)
			);

			const { email, mobile, mobilePrefix, ...submitValues } = { ...values };

			return axios
				.put(`${url}`, {
					...submitValues,
					deleteRole,
					mobile: mobilePrefix + mobile,
				})
				.then((res) => {
					toast({
						description: 'User updated',
						status: 'success',
					});
					resetAndCloseAddModal();
					refetchData();
				})
				.catch((err) => {
					if (err.response.status !== 401 && err.response.status !== 420) {
						setStatus(err.response.data.message || 'An error occurred');
					}
				});
		},
	});

	const loadRoleOptions = () => {
		setRoleLoading(true);
		let limit = 10;
		let params: any = {
			pagination: { page: roleKeyword ? searchRolePage : rolePage, limit },
		};
		let currentPage = roleKeyword ? searchRolePage : rolePage;
		if (roleKeyword) {
			params.filter = { keyword: roleKeyword };
		}
		return axios
			.get(`${process.env.REACT_APP_UMS_BASE_URL}/role/admin`, {
				params: {
					pagination: { page: currentPage, limit: limit },
					filter: { keyword: roleKeyword },
				},
			})
			.then((res) => {
				let hasMore = res.data.total - currentPage * limit > 0;
				if (hasMore) {
					roleKeyword
						? setSearchRolePage((p) => p + 1)
						: setRolePage((p) => p + 1);
				}
				setRoleLoading(false);
				return {
					options: res.data.data,
					hasMore,
				};
			})
			.catch((err) => {
				setRoleLoading(false);
				return { options: [] };
			});
	};

	const resetAndCloseAddModal = () => {
		formik.resetForm();
		setRolePage(1);
		setSearchRolePage(1);
		onClose();
	};

	useEffect(() => {
		setSelectedRole(selectedItem?.user_roles.map((r: any) => r.role));
		let temp = selectedItem?.user_roles.map((r: any) => r.roleId);
		formik.setFieldValue('role', temp);
		setInitialRoles(temp);
	}, [selectedItem, isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={resetAndCloseAddModal}
			closeOnOverlayClick={false}
			isCentered
			size='xl'
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Edit User</ModalHeader>
				<ModalCloseButton />
				<form onSubmit={formik.handleSubmit}>
					<ModalBody>
						{formik.status && (
							<Alert status='error' mb='4' fontSize='sm'>
								<AlertIcon />
								{formik.status}
							</Alert>
						)}
						<FormControl isInvalid={!!formik.errors.name} mb='4'>
							<FormLabel>Name</FormLabel>
							<Input
								{...formik.getFieldProps('name')}
								background='gray.200'
								readOnly
							/>
							<FormErrorMessage>{formik.errors.name}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.mobile} mb='4'>
							<FormLabel htmlFor='mobile'>Mobile</FormLabel>
							<InputGroup>
								<InputLeftAddon p='0' pr='1px' border='0' margin='0'>
									<Select
										{...formik.getFieldProps('mobilePrefix')}
										borderRightRadius='0'
										id='mobilePrefix'
									>
										<option value='971'>+971</option>
										<option value='91'>+91</option>
									</Select>
								</InputLeftAddon>
								<Input id='mobile' {...formik.getFieldProps('mobile')} />
							</InputGroup>
							<FormErrorMessage>{formik.errors.mobile}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.email} mb='4'>
							<FormLabel>Email</FormLabel>
							<Input
								{...formik.getFieldProps('email')}
								background='gray.200'
								readOnly
							/>
							<FormErrorMessage>{formik.errors.email}</FormErrorMessage>
						</FormControl>
						<FormControl isInvalid={!!formik.errors.role} mb='4'>
							<FormLabel>Role</FormLabel>
							<AsyncPaginate
								debounceTimeout={300}
								isLoading={roleLoading}
								defaultOptions
								getOptionLabel={(option: any) => option.name}
								getOptionValue={(option: any) => option.id}
								loadOptions={loadRoleOptions}
								onInputChange={(v: any) => {
									setRoleKeyword(v);
									setSearchRolePage(1);
								}}
								value={selectedRole}
								onChange={(v: any) => {
									setSelectedRole(v);
									formik.setFieldValue(
										'role',
										v.map((val: any) => val.id)
									);
								}}
								isMulti
								menuPosition='fixed'
								closeMenuOnSelect={false}
								name='role'
								styles={{
									control: (existingStyles) => {
										return {
											...existingStyles,
											chakraSelect,
										};
									},
								}}
							/>
							<FormErrorMessage>{formik.errors.role}</FormErrorMessage>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button mr={3} onClick={resetAndCloseAddModal} type='button'>
							Cancel
						</Button>
						<Button
							colorScheme='blue'
							type='submit'
							isLoading={formik.isSubmitting}
						>
							Submit
						</Button>
					</ModalFooter>
				</form>
			</ModalContent>
		</Modal>
	);
};
